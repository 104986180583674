import http from "../http-common";

const getAll = () => {
  return http.get("/client/clientdata/clientinfo");
};

const create = (data) => {
    return http.post("/client/clientdata/addclient", data);
}

const getClientBy = (id) => {
  return http.get(`/client/clientdata/clientinfo?id=${id}`);
}

const isExist = (ek) => {
  return http.get(`/client/clientdata/rolekey?ek=${ek}`);
}

const chnageSts = (id) => {
  return http.get(`/client/clientdata/status?id=${id}`);
}

const remove = (id) => {
  return http.delete(`/client/clientdata/dclient?id=${id}`);
};


const ClientService = {
  getAll,
  create,
  getClientBy,
  isExist,
  chnageSts,
  remove
};

export default ClientService;