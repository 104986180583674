import React from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from "recharts";

const TodaysScheduleTimimg = () => {
  const data = [
    {
      name: "TR001",
      'batch1(9-11)': 5,
      'batch2(11-13)': 8,
      'batch3(14-16)': 3,
      'batch4(16-18)': 6,
      'batch5(18-20)': 4
    },
    {
      name: "TR002",
      'batch1(9-11)': 10,
      'batch2(11-13)': 5,
      'batch3(14-16)': 10,
      'batch4(16-18)': 12,
      'batch5(18-20)': 6
    },
    {
      name: "TR003",
      'batch1(9-11)': 8,
      'batch2(11-13)': 6,
      'batch3(14-16)': 9,
      'batch4(16-18)': 7,
      'batch5(18-20)': 5
    },
    {
      name: "TR004",
      'batch1(9-11)': 2,
      'batch2(11-13)': 8,
      'batch3(14-16)': 15,
      'batch4(16-18)': 4,
      'batch5(18-20)': 10
    },
    {
      name: "TR005",
      'batch1(9-11)': 4,
      'batch2(11-13)': 5,
      'batch3(14-16)': 15,
      'batch4(16-18)': 9,
      'batch5(18-20)': 12
    },
    {
      name: "TR006",
      'batch1(9-11)': 6,
      'batch2(11-13)': 7,
      'batch3(14-16)': 15,
      'batch4(16-18)': 12,
      'batch5(18-20)': 10
    },
    {
      name: "TR007",
      'batch1(9-11)': 9,
      'batch2(11-13)': 4,
      'batch3(14-16)': 5,
      'batch4(16-18)': 20,
      'batch5(18-20)': 5
    },
    {
      name: "TR008",
      'batch1(9-11)': 3,
      'batch2(11-13)': 6,
      'batch3(14-16)': 18,
      'batch4(16-18)': 16,
      'batch5(18-20)': 12
    }
  ];
  return (
    <>
      <div className="header-flex">
        <div className="heading">
          <h2>Today's Scheduled Timing</h2>
        </div>
        <div className="selectDate">
          <input
            type="date"
            placeholder="Select Date"
            name="todaysScheduledTraining"
            id="todaysScheduledTraining"
          />
        </div>
      </div>
      <div className="dataContent">
        <h2>Average Scheduled Timing</h2>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            maxBarSize={30}
            data={data}
            margin={{
              top: 5,
              right: 20,
              left: 0,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="batch1(9-11)" fill="rgba(var(--SecondaryColorHex), 0.10)" />
            <Bar dataKey="batch2(11-13)" fill="rgba(var(--SecondaryColorHex), 0.25)" />
            <Bar dataKey="batch3(14-16)" fill="rgba(var(--SecondaryColorHex), 0.50)" />
            <Bar dataKey="batch4(16-18)" fill="rgba(var(--SecondaryColorHex), 0.75)" />
            <Bar dataKey="batch5(18-20)" fill="rgba(var(--SecondaryColorHex), 1)" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default TodaysScheduleTimimg;
