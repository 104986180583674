import React, { useState, useEffect } from "react";
import { Container, Row, Form, Col, Button, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import "./assets.css";

import AssetService from "../../../services/asset.service";

import AssetTypeService from "../../../services/assettype.service";

import StaffService from "../../../services/staff.service";

const EditAsset = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [asset, setAsset] = useState({
    id: id,
    name: "",
    photo: "",
    registration_no: "",
    manager: "",
    capacity: "",
    note: "",
    maintenance_period: "",
    next_maintainance_date: "",
    purchase_date: "",
  });

  const [assetTypes, setAssetTypes] = useState([]);

  const [staffs, setStaffs] = useState([]);

  const getAsset = (id) => {
    AssetService.getAssetBy(id)
      .then((response) => {
        setAsset(response.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {

    StaffService.getAll()
    .then((response) => {
      setStaffs(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

    AssetTypeService.getAll()
      .then((response) => {
        setAssetTypes(response.data);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
    if (id) {
      getAsset(id);
    }
  }, []);

  

  const [submitted, setSubmitted] = useState(false);

  const [validated, setValidated] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAsset({ ...asset, [name]: value });
  };

  const handleFileChange = (e) => {
    console.log(e.target.files[0].name);
    setAsset({ ...asset, photo: e.target.files[0].name });
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    event.preventDefault();

    fetch("https://tms.aeonsoftware.net/cit//training/assets/index_put", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "pass123",
      },
      body: JSON.stringify({
        id: asset.id,
        name: asset.name,
        photo: asset.photo,
        registration_no: asset.registration_no,
        manager: asset.manager,
        capacity: asset.capacity,
        note: asset.note,
        maintenance_period: asset.maintenance_period,
        next_maintainance_date: asset.next_maintainance_date,
        purchase_date: asset.purchase_date,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSubmitted(true);
        setInterval(() => {
          navigate("/ManageAssets");
          setSubmitted(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err.message);
      });

     
  };

  return (
    <>
      <Container>
        <Row>
          <div className="staffsInfo">
            <div className="staffDetailsBox">
              <div className="head">
                <h3>Edit Asset Details</h3>
              </div>
              {submitted ? (
                <Row>
                  <div className="sucmessage">Asset Updated Successfully!</div>
                </Row>
              ) : (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group className="mb-3" controlId="formSelect">
                        <Form.Select
                          aria-label="Default select example"
                          name="asset_type"
                          value={asset.asset_type}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Asset Type</option>
                          {Array.isArray(assetTypes)
                            ? assetTypes.map((assetType) => {
                                return (
                                  <option
                                    key={assetType.id}
                                    value={assetType.id}
                                  >
                                    {assetType.name}
                                  </option>
                                );
                              })
                            : null}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="name"
                          value={asset.name}
                          onChange={handleInputChange}
                          required
                          title="Name"
                        />
                        <Form.Label>Name</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="file"
                          name="photo"
                          onChange={handleFileChange}
                          title="Upload Image"
                        />
                        {/* <Form.Label>Upload Image</Form.Label> */}
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="registration_no"
                          value={asset.registration_no}
                          onChange={handleInputChange}
                          required
                          title="Registration Date"
                        />
                        {/* <Form.Label>Registration Date</Form.Label> */}
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Select
                            aria-label="Default select example"
                            name="manager"
                            value={asset.manager}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Manager</option>
                            {Array.isArray(staffs)
                              ? staffs.map((staff) => {
                                  return (
                                    <option key={staff.id} value={staff.id}>
                                      {staff.first_name} {staff.last_name} 
                                    </option>
                                  );
                                })
                              : null}
                          </Form.Select>
                          {/* <Form.Label>Manager</Form.Label> */}
                        </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="capacity"
                          value={asset.capacity}
                          onChange={handleInputChange}
                          required
                          title="Students Capacity"
                        />
                        <Form.Label>Students Capacity</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="note"
                          value={asset.note}
                          onChange={handleInputChange}
                          title="Note"
                        />
                        <Form.Label>Note</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="maintenance_period"
                          value={asset.maintenance_period}
                          onChange={handleInputChange}
                          required
                          title="Maintenance Period"
                        />
                        <Form.Label>Maintenance Period</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3 joining_date"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="date"
                            name="next_maintainance_date"
                            value={asset.next_maintainance_date}
                            onChange={handleInputChange}
                            required
                            title="Next Maintainance Date"
                          />
                          <Form.Label>Next Maintainance Date</Form.Label>
                        </Form.Group>
                      </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="date"
                          name="perchase_date"
                          value={asset.purchase_date}
                          onChange={handleInputChange}
                          required
                          title="Purchase Date"
                        />
                        {/* <Form.Label>Purchase Date</Form.Label> */}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Button className="nextButton" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EditAsset;
