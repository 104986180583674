import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { Link } from "react-router-dom";

import AuthService from "../services/auth.service";

const Topbar = ({ openSidebar, logout }) => {
  const [currentUser, setCurrentUser] = useState("");

  const [menu, setMenu] = useState(false);

  const [imgPath, setImgPath] = useState({
    logoPath: "male-placeholder.jpg"
  });

  const [date, setDate] = useState(new Date());

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenu(!menu);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    // console.log(user);

    if (user) {
      setCurrentUser(user.username);
    }

    const timer = setInterval(() => {
      setDate(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const location = useLocation();

  return (
    <>
      <div className="topbar">
        <div className="toggleBtn" onClick={openSidebar}>
          <svg
            id="Layer_2"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="24"
            viewBox="0 0 44 34"
          >
            <g id="Layer_1" data-name="Layer 1" transform="translate(0)">
              <path
                id="Path_416"
                data-name="Path 416"
                d="M22.034,2.243c-6.856,0-13.713-.011-20.569.017-.726,0-1-.508-1.39-.907-.07-.072-.055-.278-.025-.4A1.89,1.89,0,0,1,1.339,0H42.684A1.847,1.847,0,0,1,43.99.93a.724.724,0,0,1-.008.409,1.852,1.852,0,0,1-1.326.9Z"
                transform="translate(-0.022)"
                fill="rgb(var(--SecondaryColorHex))"
              />
              <path
                id="Path_417"
                data-name="Path 417"
                d="M22.04,160.021c6.838,0,13.676.014,20.521-.021.719,0,1.032.435,1.417.851a.578.578,0,0,1,.045.446,1.858,1.858,0,0,1-1.295.939H1.385a1.889,1.889,0,0,1-1.307-.925.7.7,0,0,1,.01-.409,1.787,1.787,0,0,1,1.336-.882Z"
                transform="translate(-0.045 -138.828)"
                fill="rgb(var(--SecondaryColorHex))"
              />
              <path
                id="Path_418"
                data-name="Path 418"
                d="M17.745,239.888c5.447,0,10.9.015,16.343-.018.733,0,1.011.5,1.413.9.081.078.067.309.024.446a1.923,1.923,0,0,1-1.391.916H1.348a1.9,1.9,0,0,1-1.309-.943c-.033-.128-.051-.33.021-.4.394-.4.659-.919,1.391-.915C6.885,239.906,12.314,239.888,17.745,239.888Z"
                transform="translate(-0.009 -208.131)"
                fill="rgb(var(--SecondaryColorHex))"
              />
              <path
                id="Path_419"
                data-name="Path 419"
                d="M17.79,82.277c-5.462,0-10.926-.013-16.39.015-.712,0-.973-.506-1.352-.9-.071-.072-.053-.278-.018-.4a1.9,1.9,0,0,1,1.311-.934H34.174a1.912,1.912,0,0,1,1.338.9.684.684,0,0,1,0,.409,1.907,1.907,0,0,1-1.335.907Z"
                transform="translate(0 -69.466)"
                fill="rgb(var(--SecondaryColorHex))"
              />
            </g>
          </svg>
        </div>
        <div className="heading">
          {location.pathname === "/SADashboard" ? (
            <h1>Welcome, {currentUser}</h1>
          ) : location.pathname === "/IDashboard" ? (
            <h1>Welcome, {currentUser}</h1>
          ) : location.pathname === "/ManageClients" ? (
            <h1>Client Details</h1>
          ) : location.pathname === "/AddClient" ? (
            <h1>Client Details</h1>
          ) : location.pathname.includes("/EditClient") ? (
            <h1>Client Details</h1>
          ) : location.pathname === "/ManageRoles" ? (
            <h1>Roles</h1>
          ) : location.pathname === "/AddRole" ? (
            <h1>Roles</h1>
          ) : location.pathname.includes("/EditRole") ? (
            <h1>Roles</h1>
          ) : location.pathname === "/ManageStaffs" ? (
            <h1>Staff Management</h1>
          ) : location.pathname === "/AddStaff" ? (
            <h1>Staff Management</h1>
          ) : location.pathname.includes("/EditStaff/") ? (
            <h1>Staff Management</h1>
          ) : location.pathname === "/ManageAssetsType" ? (
            <h1>Assets Type</h1>
          ) : location.pathname === "/AddAssetType" ? (
            <h1>Assets Type</h1>
          ) : location.pathname.includes("/EditAssetType/") ? (
            <h1>Assets Type</h1>
          ) : location.pathname === "/ManageAssets" ? (
            <h1>Assets Management</h1>
          ) : location.pathname === "/AddAsset" ? (
            <h1>Assets Management</h1>
          ) : location.pathname.includes("/EditAsset/") ? (
            <h1>Assets Management</h1>
          ) : location.pathname === "/ManageStudents" ? (
            <h1>Students Management</h1>
          ) : location.pathname === "/AddStudent" ? (
            <h1>Students Management</h1>
          ) : location.pathname.includes("/EditStudent/") ? (
            <h1>Students Management</h1>
          ) : location.pathname === "/ManageCourses" ? (
            <h1>Courses</h1>
          ) : location.pathname === "/AddCourse" ? (
            <h1>Courses</h1>
          ) : location.pathname.includes("/EditCourses/") ? (
            <h1>Courses</h1>
          ) : location.pathname === "/BookingReq" ? (
            <h1>Booking Request</h1>
          ) : location.pathname === "/AddBooking" ? (
            <h1>Booking Request</h1>
          ) : location.pathname === "/ScheduledTrainings" ? (
            <h1>Scheduled Trainings</h1>
          ) : location.pathname.includes("/ViewScheduledTrainings") ? (
            <h1>Trainings Details</h1>
          ) : location.pathname.includes("/ScheduleBooking") ? (
            <h1>Schedule Booking</h1>
          ) : location.pathname === "/Settings" ? (
            <h1>Settings</h1>
          ) : (
            ""
          )}
        </div>
        <div className="searchFlex">
          <div className="profile" onClick={toggleMenu}>
            <div className="profilePic">
              <img src={`https://tms.aeonsoftware.net/cit//uploads/${imgPath.logoPath}`} alt="" 
              style={{ width: "45px", height: "45px", borderRadius:"100%" }} />
            </div>
            <div className="profileName">
              <span>{currentUser}</span>
              {/* <img
                src={DownIcon}
                style={{ width: "auto", height: "10px" }}
                alt=""
              /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22.941"
                height="12.353"
                viewBox="0 0 22.941 12.353"
              >
                <path
                  id="Path_385"
                  data-name="Path 385"
                  d="M9.225,11.458,8.2,10.4.724,2.807a4.6,4.6,0,0,1-.419-.462A1.6,1.6,0,0,1,.3.5,1.106,1.106,0,0,1,1.868.207a6.516,6.516,0,0,1,.71.674q4.6,4.666,9.175,9.329a1.612,1.612,0,0,1,0,2.51q-4.767,4.853-9.538,9.706a1.2,1.2,0,0,1-1.585.337A1.4,1.4,0,0,1,.037,21.3a2.439,2.439,0,0,1,.58-1.043c2.719-2.8,5.451-5.569,8.188-8.349C8.94,11.77,9.064,11.63,9.225,11.458Z"
                  transform="translate(22.941) rotate(90)"
                  fill="rgb(var(--SecondaryColorHex))"
                />
              </svg>
            </div>
          </div>
          <div className={menu ? "menu active" : "menu"}>
            <h3>
              {currentUser}
              <br />
              <span> {date.toLocaleString()}</span>
            </h3>
            <ul>
              <li>
                <i className="fa fa-user"></i>
                <Link to="/profile">My Profile</Link>
              </li>
              <li>
                <i className="fa fa-edit"></i>
                <Link to="/#">Edit Profile</Link>
              </li>
              <li>
                <i className="fa fa-envelope"></i>
                <Link to="/#">Inbox</Link>
              </li>
              <li>
                <i className="fa fa-cog"></i>
                <Link to="/Settings">Settings</Link>
              </li>
              <li>
                <i className="fa fa-question-circle"></i>
                <Link to="/#">Help</Link>
              </li>
              <li onClick={logout}>
                <i className="fa fa-sign-out"></i>
                <Link to="/#">Logout</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Topbar;
