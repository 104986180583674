import React, { useState } from "react";
import {Form, Row, Col, Button } from "react-bootstrap";
import './settings.css';

const Color = () => {
  const [validated, setValidated] = useState(false);

  const initialTitle = {
    pri_color: "#26AC96",
    sec_color : "#5DB69C"
  };

  const [color, setColor] = useState(initialTitle);

  const [submitted, setSubmitted] = useState(false);

  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setColor({ ...color, [name]: value });
  //   document.documentElement.style.setProperty('--PrimaryColorHex', event.target.value);
  //   document.documentElement.style.setProperty('--SecondaryColorHex', event.target.value);
  // };

  const handlePriColorInputChange = (event) => {
    const { name, value } = event.target;
    setColor({ ...color, [name]: value });
    const priColorInput = event.target.value;
    const priRed = parseInt(priColorInput.substring(1, 3), 16);
    const priGreen = parseInt(priColorInput.substring(3, 5), 16);
    const priBlue = parseInt(priColorInput.substring(5, 7), 16);
    const priRGBColor = `${priRed}, ${priGreen}, ${priBlue}`
    document.documentElement.style.setProperty('--PrimaryColorHex', priRGBColor);
  };

  const handleSecColorInputChange = (event) => {
    const { name, value } = event.target;
    setColor({ ...color, [name]: value });
    const secColorInput = event.target.value;
    const secRed = parseInt(secColorInput.substring(1, 3), 16);
    const secGreen = parseInt(secColorInput.substring(3, 5), 16);
    const secBlue = parseInt(secColorInput.substring(5, 7), 16);
    const secRGBColor = `${secRed}, ${secGreen}, ${secBlue}`
    document.documentElement.style.setProperty('--SecondaryColorHex', secRGBColor);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();
  };

  return (
    <>
      <h1>Choose Color</h1>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col xl={6} lg={6} md={12} sm={12}>
            <Form.Group className="mb-3 colorInputGroup" controlId="note">
              <Form.Control
                type="color"
                name="pri_color"
                value={color.pri_color}
                onChange={handlePriColorInputChange}
                required
              />
              <Form.Label>Primary Color</Form.Label>
            </Form.Group>
          </Col>
          <Col xl={6} lg={6} md={12} sm={12}>
            <Form.Group className="mb-3 colorInputGroup" controlId="note">
              <Form.Control
                type="color"
                name="sec_color"
                value={color.sec_color}
                onChange={handleSecColorInputChange}
                required
              />
              <Form.Label>Secondary Color</Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Button
              type="submit"
              className="subButton"
              // onClick={() => {
              //   saverole();
              // }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default Color;
