import React, { useState } from "react";
import { Container, Row, Form, Col, Button, Alert } from "react-bootstrap";

import "./assets.css";

import AssetService from "../../../services/asset.service";

import AssetTypeService from "../../../services/assettype.service";

import { useEffect } from "react";

import StaffService from "../../../services/staff.service";

const AddAsset = () => {
  const [messsage, setMessage] = useState("");

  const [submitted, setSubmitted] = useState(false);

  const [staffs, setStaffs] = useState([]);

  const [asset, setAsset] = useState({
    asset_type: "",
    name: "",
    manager: "",
    capacity: "",
    maintenance_period: "",
    note: "",
    registration_no: "",
    purchase_date: "",
    next_maintainance_date: "",
    photo: "",
  });

  const [assetTypes, setAssetTypes] = useState([]);

  const [validated, setValidated] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAsset({ ...asset, [name]: value });
  };

  const handleFileChange = (e) => {
    console.log(e.target.files[0].name);
    setAsset({ ...asset, photo: e.target.files[0].name });
  }

  useEffect(() => {
    AssetTypeService.getAll()
      .then((response) => {
        setAssetTypes(response.data);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

      StaffService.getAll()
    .then((response) => {
      setStaffs(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

  }, []);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    event.preventDefault();

    const data = {
      asset_type: asset.asset_type,
      name: asset.name,
      manager: asset.manager,
      capacity: asset.capacity,
      maintenance_period: asset.maintenance_period,
      note: asset.note,
      registration_no: asset.registration_no,
      purchase_date: asset.purchase_date,
      next_maintainance_date: asset.next_maintainance_date,
      photo: asset.photo,
    };

    console.log(data);

    if (Object.values(data).every((value) => value)) {
      console.log(true);
      AssetService.create(data)
        .then((response) => {
          setAsset(response.data);
          console.log(response.data);
          setSubmitted(true);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      console.log(false);
    }
  };

  return (
    <>
      <Container>
        <Row>
          <div className="staffsInfo">
            <div className="AddBoxLarge">
              <div className="head">
                <h3>Enter Details of Assets</h3>
              </div>
              {submitted ? (
                <>
                  <Row>
                    <div className="sucmessage">Asset Added Successfully!</div>
                  </Row>
                </>
              ) : (
                <>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <div className="errmessage">{messsage}</div>
                    <Row>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formSelect">
                          <Form.Select
                            aria-label="Default select example"
                            name="asset_type"
                            value={asset.asset_type}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Asset Type</option>
                            {Array.isArray(assetTypes)
                              ? assetTypes.map((assetType) => {
                                  return (
                                    <option key={assetType.id} value={assetType.id}>
                                      {assetType.name}
                                    </option>
                                  );
                                })
                              : null}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            name="name"
                            value={asset.name}
                            onChange={handleInputChange}
                            required
                            title="Name"
                          />
                          <Form.Label>Name</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="file"
                            name="photo"
                            onChange={handleFileChange}
                            title="Upload Image"
                          />
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            name="registration_no"
                            value={asset.registration_no}
                            onChange={handleInputChange}
                            required
                            title="Registration Date"
                          />
                          <Form.Label>Registration No.</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Select
                            aria-label="Default select example"
                            name="manager"
                            value={asset.manager}
                            onChange={handleInputChange}
                            required
                          >
                            <option value="">Manager</option>
                            {Array.isArray(staffs)
                              ? staffs.map((staff) => {
                                  return (
                                    <option key={staff.id} value={staff.id}>
                                      {staff.first_name} {staff.last_name} 
                                    </option>
                                  );
                                })
                              : null}
                          </Form.Select>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            name="capacity"
                            value={asset.capacity}
                            onChange={handleInputChange}
                            required
                            title="Students Capacity"
                          />
                          <Form.Label>Students Capacity</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            name="note"
                            value={asset.note}
                            onChange={handleInputChange}
                            title="Note"
                          />
                          <Form.Label>Note</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            name="maintenance_period"
                            value={asset.maintenance_period}
                            onChange={handleInputChange}
                            required
                            title="Maintenance Period"
                          />
                          <Form.Label>Maintenance Period (in months)</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3 joining_date"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="date"
                            name="next_maintainance_date"
                            value={asset.next_maintainance_date}
                            onChange={handleInputChange}
                            required
                            title="Next Maintainance Date"
                          />
                          <Form.Label>Next Maintainance Date</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3 joining_date"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="date"
                            name="purchase_date"
                            value={asset.purchase_date}
                            onChange={handleInputChange}
                            required
                            title="Purchase Date"
                          />
                          <Form.Label>Purchase Date</Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <Button type="submit" className="nextButton">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AddAsset;
