import React, { useState } from "react";
import {Form, Row, Col, Button } from "react-bootstrap";
import './settings.css';

const Title = () => {
  const [validated, setValidated] = useState(false);

  const initialTitle = {
    title: ""
  };

  const [title, setTitle] = useState(initialTitle);

  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTitle({ ...title, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();
  };

  return (
    <>
      <h1>Enter Title</h1>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Form.Group className="mb-3" controlId="note">
              <Form.Control
                type="text"
                name="note"
                value={title.title}
                onChange={handleInputChange}
                required
              />
              <Form.Label>Title</Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Button
              type="submit"
              className="subButton"
              // onClick={() => {
              //   saverole();
              // }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default Title;
