import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Form,
  Col,
  Button,
  Alert,
  FormSelect,
} from "react-bootstrap";

import Select from "react-select";

import "./trainings.css";

import CourseService from "../../../services/course.service";

import AssetTypeService from "../../../services/assettype.service";

import TrainingTypeService from "../../../services/trainingtype.service";


const EditTrainingType = () => {

  const { id } = useParams();

  const [validated, setValidated] = useState(false);

  const initialRole = {
    course: "",
    training_type: "",
    asset_id: "",
    note: "",
  };

  const [trainingType, setTrainingType] = useState(initialRole);

  const [submitted, setSubmitted] = useState(false);

  const [courses, setCourses] = useState([]);

  const [assets, setAssets] = useState([]);

  const [options, setOptions] = useState([]);


  const getTrainingtype = (id) => {
    TrainingTypeService.getTrainingTypeById(id)
      .then((response) => {
        setTrainingType(response.data[0]);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {

    if (id) {
      getTrainingtype(id);
    }

    CourseService.getAll()
      .then((response) => {
        setCourses(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    AssetTypeService.getAll()
      .then((response) => {
        console.log(response.data);
        const optionsData = response.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptions(optionsData);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const handleOptionChange = (event) => {
    const values = event.map((option) => option.value).toString();
    console.log(values);
    setTrainingType({ ...trainingType, asset_id: values });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTrainingType({ ...trainingType, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    event.preventDefault();

    fetch("https://tms.aeonsoftware.net/cit//training/trainingtype/index", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "pass123",
      },
      body: JSON.stringify({
        id: trainingType.id,
        course: trainingType.course,
        training_type: trainingType.training_type,
        asset_id: trainingType.asset_id,
        note: trainingType.note
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSubmitted(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <Container>
        <Row>
          <div className="coursesInfo">
            <div className="courseDetailsBox">
              {submitted ? (
                <Row>
                  <div className="sucmessage">Training Type Updated Successfully!</div>
                </Row>
              ) : (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="head">
                    <h3>Enter Training Type</h3>
                  </div>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          name="course"
                          value={trainingType.course}
                          onChange={handleInputChange}
                          required
                        >
                          <option>Select</option>
                          {Array.isArray(courses)
                            ? courses.map((course) => {
                                return (
                                  <option key={course.id} value={course.id}>
                                    {course.name}
                                  </option>
                                );
                              })
                            : null}
                        </Form.Select>
                        <Form.Label>Course</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group className="mb-3" controlId="note">
                        <Form.Control
                          type="text"
                          name="training_type"
                          value={trainingType.training_type}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Enter Training Type</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group className="mb-3" controlId="note">
                        <Form.Control
                          type="text"
                          name="note"
                          value={trainingType.note}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Note</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Select
                          options={options}
                          placeholder="Required Assets"
                          onChange={handleOptionChange}
                          isSearchable={true}
                          isMulti
                          className="form-control"
                          value={options.filter(option => trainingType.asset_id.includes(option.value))}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Button
                        type="submit"
                        className="nextButton"
                        // onClick={() => {
                        //   saverole();
                        // }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EditTrainingType;
