import React, { useState, useEffect } from "react";
import { Container, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddIconWhite from "../../../images/AddIconWhite.png";
import ViewIconWhite from "../../../images/viewIconWhite.png";
import ViewIcon from "../../../images/view.png";
import EditIcon from "../../../images/EditIcon.png";
import DeleteIcon from "../../../images/DeleteIcon.png";
import SearchIcon from "../../../images/SearchIcon.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { ThreeDots } from "react-loader-spinner";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import "../../../custom-css/datatable.css";

import Staffs from "../../../staffs.json";

import StaffService from "../../../services/staff.service";

import RoleService from "../../../services/role.service";

import ViewStaff from "./ViewStaff";

import "./staffs.css";

import AuthService from "../../../services/auth.service";

const ManageStaffs = () => {
  const [staffs, setStaffs] = useState([]);

  const [roles, setRoles] = useState([]);

  const [remove, setRemove] = useState(false);

  const [view, setView] = useState(false);

  const [did, setDid] = useState("");

  const [vid, setVid] = useState("");

  const [message, setMessage] = useState("");

  const [loadTable, setLoadtable] = useState(false);

  const [roll, setroll] = useState("");

  const [userEmail, setUserEmail] = useState("");

  const [loading, setLoading] = useState(true);

  const handleRemoveShow = (e) => {
    setRemove(true);
    setDid(e.target.getAttribute("data-bs-target"));
  };

  const handleRemoveClose = () => {
    setRemove(false);
  };

  const handleViewShow = (e) => {
    setView(true);
    setVid(e.target.getAttribute("data-bs-target"));
  };

  const handleViewClose = () => {
    setView(false);
  };

  const deleteStaff = () => {
    StaffService.remove(did)
      .then((response) => {
        setMessage(response.data.message);
        setRemove(false);
        console.log(response.data.message);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    console.log(user.user_id);

    setroll(user.user_id);

    setUserEmail(user.email);

    StaffService.getAll()
      .then((response) => {
        setLoading(false);
        setStaffs(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    RoleService.getAll()
      .then((response) => {
        setRoles(response.data);
        // console.log(response.data)
      })
      .catch((e) => {
        console.log(e);
      });
    if (!$.fn.DataTable.isDataTable("#table")) {
      function filterGlobal() {
        $("#table")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#table")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            processing: true,
            select: true,
            bDestroy: true,

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
          setLoadtable(true);
        }, 1000);
      });
    }
  }, []);

  function getRoleName(roleId, roles) {
    const role = roles.find((r) => r.id === roleId);
    return role ? role.name : "Unknown Role";
  }

  return (
    <>
      <Container fluid>
        {loading ? (
          <div className="loader-container">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <Row>
              <div className="staffsInfo">
                <div className="message">{message}</div>
                <div
                  className={
                    loadTable ? "table-actions active" : "table-actions"
                  }
                >
                  <label htmlFor="">
                    <input
                      type="text"
                      className="global_filter"
                      id="global_filter"
                      placeholder="Search"
                    />
                    <img src={SearchIcon} alt="" />
                  </label>

                  {roll === "1" ? (
                    <>
                      <Button className="custom-btn">
                        <Link to={"/ManageStaffs"}>
                          <img
                            src={ViewIconWhite}
                            style={{ width: "21px", height: "auto" }}
                            alt=""
                          />{" "}
                          View Staffs
                        </Link>
                      </Button>
                      <Button className="custom-btn">
                        <Link to={"/ManageRoles"}>
                          <img
                            src={ViewIconWhite}
                            style={{ width: "21px", height: "auto" }}
                            alt=""
                          />{" "}
                          View Roles
                        </Link>
                      </Button>
                      <Button className="custom-btn">
                        <Link to={"/AddRole"}>
                          {" "}
                          <img
                            src={AddIconWhite}
                            style={{ width: "15px", height: "auto" }}
                            alt=""
                          />{" "}
                          Add New Role
                        </Link>
                      </Button>
                      <Button className="custom-btn">
                        <Link to={"/AddStaff"}>
                          {" "}
                          <img
                            src={AddIconWhite}
                            style={{ width: "15px", height: "auto" }}
                            alt=""
                          />{" "}
                          Add New Staff
                        </Link>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button className="custom-btn">
                        <Link to={"/AddStaff"}>
                          {" "}
                          <img
                            src={AddIconWhite}
                            style={{ width: "15px", height: "auto" }}
                            alt=""
                          />{" "}
                          Add New Staff
                        </Link>
                      </Button>
                    </>
                  )}
                </div>
                <div className="staffsInfoTable">
                  <div className="table-responsive">
                    <table id="table" className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Role</th>
                          <th>F. Name</th>
                          <th>L. Name</th>
                          <th>Email ID</th>
                          <th>Mobile No.</th>
                          <th>Role Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(staffs)
                          ? staffs.map((staff, index) => {
                              return userEmail !== staff.email ? (
                                <>
                                  <tr key={staff.id}>
                                    <td>{index + 1}</td>
                                    <td>{getRoleName(staff.role_id, roles)}</td>
                                    <td>{staff.first_name}</td>
                                    <td>{staff.last_name}</td>
                                    <td>{staff.email}</td>
                                    <td>{staff.mobile}</td>
                                    <td>{staff.role_type}</td>
                                    <td>
                                      <div className="flex">
                                        <img
                                          onClick={handleViewShow}
                                          id="viewStaff"
                                          data-bs-target={staff.id}
                                          src={ViewIcon}
                                          alt="View Staff"
                                          title="View Staff"
                                          style={{
                                            width: "24px",
                                            height: "12px",
                                          }}
                                        />
                                        <Link to={"/EditStaff/" + staff.id}>
                                          <img
                                            src={EditIcon}
                                            alt="Edit Staff"
                                            title="Edit Staff"
                                            style={{
                                              width: "18px",
                                              height: "18px",
                                            }}
                                          />
                                        </Link>
                                        <img
                                          onClick={handleRemoveShow}
                                          id="deleteStaff"
                                          data-bs-target={staff.id}
                                          src={DeleteIcon}
                                          alt="Delete Staff"
                                          title="Delete Staff"
                                          style={{
                                            width: "18px",
                                            height: "18px",
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <></>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Row>
          </>
        )}
      </Container>
      <Modal show={remove} onHide={handleRemoveClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this staff.</p>
          <div className="flex">
            <Button onClick={deleteStaff}>Yes</Button>
            <Button variant="danger" onClick={handleRemoveClose}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={view} onHide={handleViewClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>View Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewStaff vid={vid} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ManageStaffs;
