import React from "react";

import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const ConfirmBookingData = () => {

  const data = [
    {
      name: "Jan",
      uv: 5
    },
    {
      name: "Feb",
      uv: 10
    },
    {
      name: "Mar",
      uv: 6
    },
    {
      name: "Apr",
      uv: 2
    },
    {
      name: "May",
      uv: 6
    },
    {
      name: "June",
      uv: 6
    },
    {
      name: "July",
      uv: 4
    },
    {
      name: "Aug",
      uv: 12
    },
    {
      name: "Sep",
      uv: 8
    },
    {
      name: "Oct",
      uv: 14
    },
    {
      name: "Nov",
      uv: 12
    },
    {
      name: "Dec",
      uv: 8
    }
  ];

  return (
    <>
      <div className="header-flex">
        <div className="heading">
          <h2>Confirm Booking</h2>
        </div>
        <div className="selectDate">
          <input
            type="date"
            placeholder="Select Date"
            name="todaysScheduledTraining"
            id="todaysScheduledTraining"
            title="From"
          />
          <input
            type="date"
            placeholder="Select Date"
            name="todaysScheduledTraining"
            id="todaysScheduledTraining"
            title="To"
          />
        </div>
      </div>
      <div className="dataContent">
        <h2>Average Confirm Booking</h2>
        <ResponsiveContainer width="100%" height={250}>
          <AreaChart
            data={data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area
             type="monotone"
              dataKey="uv"
              strokeWidth={3}
              stroke="rgb(var(--SecondaryColorHex))"
              fill="rgba(var(--SecondaryColorHex), 0.06)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default ConfirmBookingData;
