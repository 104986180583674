import React, { useState } from "react";
import { Col, Row, Form, Button, Alert, Container } from "react-bootstrap";

import CountryCodes from "../../../../country.json";

import ClientService from "../../../../services/client.service";

import { Link } from "react-router-dom";

import "../clients.css";

const AddClient = () => {
  const [validated, setValidated] = useState(false);

  // const [mailExistsNew, setMailExistsNew] = useState(null);

  const [client, setClient] = useState({
    name: "",
    email: "",
    person: "",
    mobile: "",
    country: "",
    address: "",
    tax_description: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const [messsage, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setClient({ ...client, [name]: value });
  };

  const [mailExist, setMailexist] = useState(null);

  const [selectedOption, setSelectedOption] = useState("");

  const [validationError, setValidationError] = useState("");

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    const { name, value } = event.target;
    setClient({ ...client, [name]: value });
    setValidationError("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    const data = {
      name: client.name,
      email: client.email,
      person: client.person,
      mobile: client.mobile,
      country: client.country,
      address: client.address,
      tax_description: client.tax_description,
    };

    if (Object.values(data).every((value) => value)) {
      ClientService.isExist(data.email)
        .then((response) => {
          const emailExist = response.data;
          setMailexist(emailExist);
          console.log(emailExist);

          if (emailExist === false) {
            console.log("Client added successfully");
            ClientService.create(data)
              .then((response) => {
                setClient(response.data);
                console.log(response.data);
                setSubmitted(true);
              })
              .catch((e) => {
                console.log(e);
              });
          } else if (emailExist === true) {
            setMessage("Client already exists");
            console.log("Client already exists");
            setSubmitted(false);
          } else {
            // Handle other cases if needed
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setMessage("Please fill all required fields!");
    }

    form.reset();
    setSelectedOption("");
    setValidated(false);

    setLoading(false);
  };

  return (
    <>
      <Container>
        <Row>
          <div className="clientsInfo">
            <div className="AddBoxLarge">
              <div className="head">
                <h3>Enter Client Details</h3>
              </div>
              {submitted ? (
                <>
                  <Row>
                    <div className="sucmessage">Client Added Successfully!</div>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Link to={"/ManageClients"}>
                        <Button className="nextButton">Back</Button>
                      </Link>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <div className="errmessage">{messsage}</div>
                    <Row>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            name="name"
                            value={client.name}
                            onChange={handleInputChange}
                            required
                          />
                          <Form.Label>Name(Organization)</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            name="person"
                            value={client.person}
                            onChange={handleInputChange}
                            required
                          />
                          <Form.Label>Responsive Person</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group className="mb-3" controlId="formSelect">
                          <Form.Select
                            aria-label="Default select example"
                            name="country"
                            value={client.country}
                            onChange={handleSelectChange}
                            required
                          >
                            <option value="">Select Country</option>
                            {Array.isArray(CountryCodes)
                              ? CountryCodes.map((country) => {
                                  return (
                                    <option
                                      key={country.code}
                                      value={country.code}
                                    >
                                      {country.name}
                                    </option>
                                  );
                                })
                              : null}
                          </Form.Select>
                          {/* <Form.Label>Country</Form.Label> */}
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="email"
                            name="email"
                            value={client.email}
                            onChange={handleInputChange}
                            required
                          />
                          <Form.Label>Email ID</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            name="address"
                            value={client.address}
                            onChange={handleInputChange}
                            required
                          />
                          <Form.Label>Address</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            name="mobile"
                            value={client.mobile}
                            onChange={handleInputChange}
                            required
                          />
                          <Form.Label>Mobile Number</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col xl={6} lg={6} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            name="tax_description"
                            value={client.tax_description}
                            onChange={handleInputChange}
                            required
                          />
                          <Form.Label>Taxation Description</Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <Button type="submit" className="nextButton">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AddClient;
