import React, { useState } from "react";
import {
  Container,
  Row,
  Form,
  Col,
  Button,
  Alert,
  FormSelect,
} from "react-bootstrap";

import Select from "react-select";

import "./trainings.css";

// import RoleService from "../../../services/role.service";

import CourseService from "../../../services/course.service";

import AssetTypeService from "../../../services/assettype.service";

import TrainingTypeService from "../../../services/trainingtype.service";

import { useEffect } from "react";

const AddTrainingType = () => {
  const [validated, setValidated] = useState(false);

  const initialRole = {
    course: "",
    training_type: "",
    asset_id: '',
    note: ''
  };

  const [trainingType, setTrainingType] = useState(initialRole);

  const [submitted, setSubmitted] = useState(false);

  const [courses, setCourses] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [selectedValues, setSelectedValues] = useState([]);

  const [assetsType, setAssetsType] = useState([]);

  const handleOptionChange = (event) => {
    setSelectedOptions(event);
    const values = (event.map((option) => option.value)).toString();
    setSelectedValues(values);
    setTrainingType({ ...trainingType, asset_id: values });
  };

  console.log(selectedValues);

  const [options, setOptions] = useState({});

    useEffect(() => {
      CourseService.getAll()
      .then((response) => {
        setCourses(response.data);
        // console.log(response.data)
      })
      .catch((e) => {
        console.log(e);
      });

      AssetTypeService.getAll()
      .then((response) => {
        setAssetsType(response.data);
        setOptions(response.data.map(asset => ({
          value: asset.id,
          label: asset.name
        })))
      })
      .catch((e) => {
        console.log(e);
      });
      
    }, [])

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTrainingType({ ...trainingType, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const data = {
      course: trainingType.course,
      training_type: trainingType.training_type,
      asset_id: trainingType.asset_id,
      note: trainingType.note
    };
    console.log(data);

    if (Object.values(data).every((value) => value)) {
      setSubmitted(true);
        TrainingTypeService.create(data)
          .then((response) => {
            console.log(response.data);
            setTrainingType(response.data);
            setSubmitted(true);
          })
          .catch((e) => {
            console.log(e);
          });
    }
  };

  return (
    <>
      <Container>
        <Row>
          <div className="coursesInfo">
            <div className="AddBoxSmall">
              {submitted ? (
                <Row>
                  <div className="sucmessage">Training Type Added Successfully!</div>
                </Row>
              ) : (
                <>
                <div className="head">
                    <h3>Enter Training Type</h3>
                  </div>
                  <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          name="course"
                          value={trainingType.course}
                          onChange={handleInputChange}
                          required
                        >
                          <option>Select</option>
                          {Array.isArray(courses)
                            ? courses.map((course) => {
                                return (
                                  <option key={course.id} value={course.id}>
                                    {course.name}
                                  </option>
                                );
                              })
                            : null}
                        </Form.Select>
                        <Form.Label>Course</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group className="mb-3" controlId="note">
                        <Form.Control
                          type="text"
                          name="training_type"
                          value={trainingType.training_type}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Enter Training Type</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group className="mb-3" controlId="note">
                        <Form.Control
                          type="text"
                          name="note"
                          value={trainingType.note}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Note</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Select
                          options={options}
                          placeholder="Required Assets"
                          onChange={handleOptionChange}
                          isSearchable={true}
                          isMulti
                          className="form-control"
                          value={assetsType.find(
                            (option) =>
                              option.value === trainingType.asset_id
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Button
                        type="submit"
                        className="nextButton"
                        // onClick={() => {
                        //   saverole();
                        // }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
                </>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AddTrainingType;
