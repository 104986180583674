import React, { useState, useEffect } from "react";
import { Container, Row, Form, Col, Button, Alert } from "react-bootstrap";

import ProfileImg from "../../../images/ProfileImg.png";
import cameraIcon from "../../../images/cameraIcon.png";

import "./staffs.css";

// import Roles from "../../../roles.json";

import RoleService from "../../../services/role.service";

import StaffService from "../../../services/staff.service";

import { useNavigate } from 'react-router-dom';

const AddStaff = () => {
  const [roles, setRoles] = useState([]);
  const [messsage, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [date, setDate] = useState(new Date());

  const [mailExistsNew, setMailExistsNew] = useState(null);

  const [uploaded, setUploaded] = useState(false);

  const navigate = useNavigate();

  RoleService.getAll()
    .then((response) => {
      setRoles(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

  const [staffs, setStaffs] = useState({
    first_name: "",
    last_name: "",
    role_id: "",
    email: "",
    mobile: "",
    // image: "",
    // imagePath: "",
    role_type: "",
    working_hours: "",
    work_experience: "",
    joining_date: "",
    address: "",
    nationality: "",
    hourly_rate: "",
  });

  const [validated, setValidated] = useState(false);

  const [profileImg, setProfileImg] = useState("");

  const [loading, setLoading] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setStaffs({ ...staffs, [name]: value });
  };

  const handleFileChange = (e) => {
    console.log(e.target.files);
    // setLogo(URL.createObjectURL(e.target.files[0]));
    // setSelectedFiles(e.target.files[0]);
    setProfileImg(URL.createObjectURL(e.target.files[0]));
    setStaffs({ ...staffs, imagePath: e.target.files[0] });
    setUploaded(true);
  };

  useEffect(() => {
    if (isFormSubmitted) {
      const timer = setTimeout(() => {
        navigate('/ManageStaffs');
      }, 2000);
      
      return () => clearTimeout(timer);
    }
  }, [isFormSubmitted, navigate]);
  

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }

    // const formData = new FormData();

    // formData.append('first_name', staffs.first_name);
    // formData.append('last_name', staffs.last_name);
    // formData.append('role_id', staffs.role_id);
    // formData.append('email', staffs.email);
    // formData.append('mobile', staffs.mobile);
    // formData.append('first_name', staffs.first_name);

    const data = {
      first_name: staffs.first_name,
      last_name: staffs.last_name,
      role_id: staffs.role_id,
      email: staffs.email,
      mobile: staffs.mobile,
      // image: staffs.image,
      // imagePath: staffs.imagePath,
      role_type: staffs.role_type,
      working_hours: staffs.working_hours,
      work_experience: staffs.work_experience,
      joining_date: staffs.joining_date,
      address: staffs.address,
      nationality: staffs.nationality,
      hourly_rate: staffs.hourly_rate,
    };

    console.log(data);

    if (Object.values(data).every((value) => value)) {
      StaffService.isExist(data.email)
        .then((response) => {
          const emailExist = response.data;
          setMailExistsNew(emailExist);
          console.log(emailExist);

          if (emailExist === false) {
            // console.log("Staff added successfully");
            StaffService.create(data)
              .then((response) => {
                setLoading(true);
                setStaffs(response.data);
                console.log(response.data);
                setSubmitted(true);
                setMessage("Staff Added and Email has been sent successfully!");
                setIsFormSubmitted(true);
                // sendMessage = "Staff Added and Email has been sent successfully!";
                // navigate('/ManageStaffs');
              })
              .catch((e) => {
                console.log(e);
              });
          } else if (emailExist === true) {
            setMessage("Staff already exists");
            // console.log("Client already exists");
            setSubmitted(false);
          } else {
            // Handle other cases if needed
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setMessage("Please fill all required fields!");
    }
  };

  return (
    <>
      <Container>
        <Row>
          <div className="staffsInfo">
            <div className="AddBoxLarge">
              <div className="head">
                <h3>Enter Staff Details</h3>
              </div>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="errmessage">{messsage}</div>
                <Row>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="first_name"
                        value={staffs.first_name}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Label>First Name</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="last_name"
                        value={staffs.last_name}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Label>Last Name</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="email"
                        value={staffs.email}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Label>Email ID</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="mobile"
                        value={staffs.mobile}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Label>Mobile Number</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="address"
                        value={staffs.address}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Label>Address</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="nationality"
                        value={staffs.nationality}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Label>Nationality</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group className="mb-3" controlId="formSelect">
                      <Form.Select
                        aria-label="Default select example"
                        name="role_id"
                        value={staffs.role_id}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Role</option>
                        {Array.isArray(roles)
                          ? roles.map((role) => {
                              return (
                                <option key={role.id} value={role.id}>
                                  {role.name}
                                </option>
                              );
                            })
                          : null}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group className="mb-3" controlId="formSelect">
                      <Form.Select
                        aria-label="Default select example"
                        name="role_type"
                        value={staffs.role_type}
                        onChange={handleInputChange}
                        required
                      >
                        <option value="">Role type</option>
                        <option value="permanent">Permanent</option>
                        <option value="contract">Contract</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="working_hours"
                        value={staffs.working_hours}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Label>Working Hours</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="work_experience"
                        value={staffs.work_experience}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Label>Work Experience</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group
                      className="mb-3 joining_date"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="date"
                        name="joining_date"
                        value={staffs.joining_date}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Label>Joining Date</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col xl={6} lg={6} md={12} sm={12}>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        type="text"
                        name="hourly_rate"
                        value={staffs.hourly_rate}
                        onChange={handleInputChange}
                        required
                      />
                      <Form.Label>Hourly Rate</Form.Label>
                    </Form.Group>
                  </Col>
                  {/* <Col xl={6} lg={6} md={12} sm={12}>
                        <div className="logoPreviw">
                          <label htmlFor="logo" className="logoLabel">
                            <img
                              src={cameraIcon}
                              className="cameraIcon"
                              alt=""
                            />
                          </label>
                          <div className="inputFile">
                            <input
                              type="file"
                              name="image"
                              id="logo"
                              value={staffs.image}
                              onChange={handleFileChange}
                              required
                            />
                          </div>
                          {uploaded === true ? (
                            <img src={profileImg} className="previewImg" alt="" />
                          ) : (
                            <>
                              {staffs.imagePath !== "" ? (
                                <>
                                  <img
                                    src={`https://tms.aeonsoftware.net/cit//uploads/${staffs.logoPath}`}
                                    className="previewImg"
                                    alt=""
                                  />
                                </>
                              ) : (
                                <>
                                  <img
                                    src={`https://tms.aeonsoftware.net/cit//uploads/male-placeholder.jpg`}
                                    className="previewImg"
                                    alt=""
                                  />
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </Col> */}
                </Row>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12}>
                    <Button type="submit" className="nextButton">
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AddStaff;
