import http from "../http-common";

const getAll = () => {
  return http.get("/student/studentdata/index");
};

const getStudentBy = (id) => {
  return http.get(`/student/studentdata/index?id=${id}`);
};

const getStudentByClient = (cid) => {
  return http.get(`/student/studentdata/index?client_id=${cid}`);
};

const getStudentByClientCourse = (cid, coid) => {
  return http.get(`/student/studentdata/index?client_id=${cid}&course=${coid}`);
};

const create = (data) => {
    return http.post("/student/studentdata/index", data)
}

const isExist = (ek) => {
  return http.get(`/student/studentdata/index?ek=${ek}`);
}

const chnageSts = (id) => {
  return http.get(`/student/studentdata/index?id=${id}`);
}

const remove = (id) => {
  return http.delete(`/student/studentdata/index?id=${id}`);
};


const StudentService = {
  getAll,
  getStudentBy,
  getStudentByClient,
  getStudentByClientCourse,
  create,
  isExist,
  chnageSts,
  remove
};

export default StudentService;