import http from "../http-common";

const create = (data) => {
  return http.post("/scheduling/schedulingdata", data);
};

const getByBookingId = (id) => {
  return http.get(`/scheduling/schedulingdata?bid=${id}`);
};

const SchedulingService = {
  create,
  getByBookingId
};

export default SchedulingService;