import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Button,
  Modal,
  Form,
  Col,
  Alert,
} from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import "./bookings.css";

import Select from "react-select";

import ViewIcon from "../../../images/view.png";
import EditIcon from "../../../images/EditIcon.png";
import DeleteIcon from "../../../images/DeleteIcon.png";

import Accordion from "react-bootstrap/Accordion";

import BookingIcon from "../../../images/BookingIcon.png";

import SchedulingIcon from "../../../images/schedulingIcon.png";

import BookingService from "../../../services/booking.service";

import ClientService from "../../../services/client.service";

import CourseService from "../../../services/course.service";

import TrainingTypeService from "../../../services/trainingtype.service";

import AssetTypeService from "../../../services/assettype.service";

import AssetService from "../../../services/asset.service";

import StudentService from "../../../services/student.service";

import { Link } from "react-router-dom";
import StaffService from "../../../services/staff.service";

import SchedulingService from "../../../services/scheduling.service";

const ScheduleBooking = () => {
  const { id } = useParams();

  // console.log(id);

  const [did, setDid] = useState("");

  const [vid, setVid] = useState("");

  const [remove, setRemove] = useState(false);

  const [view, setView] = useState(false);

  const [slot, setSlot] = useState({
    booking_id: "",
    client_id: "",
    course_id: "",
    training_typeid: "",
    date: "",
    time_from: "",
    time_to: "",
    student_id: "",
    asset_typeid: "",
    asset_id: "",
    atime_from: "",
    atime_to: "",
    instructor_id: "",
    support_instid: "",
    note: "",
  });

  const [loading, setLoading] = useState(false);

  const [booking, setBooking] = useState([]);

  const [clients, setClients] = useState([]);

  const [courses, setCourses] = useState([]);

  const [trainingTypes, setTrainingTypes] = useState([]);

  const [show, setShow] = useState(false);

  const [assets, setAssets] = useState([]);

  const [assetsName, setAssetsName] = useState([]);

  const [assetsType, setAssetsType] = useState([]);

  const [timingsId, setTimingsId] = useState("");

  const [simulators, setSimulators] = useState([]);

  const [classrooms, setClassrooms] = useState([]);

  const [aircrafts, setAircrafts] = useState([]);

  const [submitted, setSubmitted] = useState(false);

  const [message, setMessage] = useState("");

  const handleRemoveShow = (e) => {
    setRemove(true);
    setDid(e.target.getAttribute("data-bs-target"));
  };

  const handleRemoveClose = () => {
    setRemove(false);
  };

  const handleViewShow = (e) => {
    setView(true);
    setVid(e.target.getAttribute("data-bs-target"));
  };

  const handleViewClose = () => {
    setView(false);
  };

  // const showAvailableTimings = (id) => {
  //   setShow(!show);
  //   setTimingsId(id);
  // };

  const getBooking = (id) => {};

  // const trainingId = booking.training_id;

  const [trainingId, setTrainingId] = useState("");

  const [showAssetValues, setShowAssetValues] = useState(false);

  const [allAssets, setAllAssets] = useState([]);

  const [nestedAssets, setNestedAssets] = useState([]);

  const [assetTypeValues, setAssetTypeValues] = useState({});

  const [instructors, setInstructors] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [selectedValues, setSelectedValues] = useState([]);

  // const [assetsType, setAssetsType] = useState([]);

  const handleOptionChange = (event) => {
    setSelectedOptions(event);
    const values = event.map((option) => option.value).toString();
    setSelectedValues(values);
    setSlot({ ...slot, support_instid: values });
  };

  console.log(selectedValues);

  const [options, setOptions] = useState({});

  const [students, setStudents] = useState([]);

  const [unScheduled, setUnScheduled] = useState([]);

  const [allStudents, setAllStudents] = useState([]);

  const [bookedSlots, setBookedSlots] = useState({});

  const [allstaffs, setAllstaffs] = useState([]);

  const [unScheduledStudents, setUnScheduledStudents] = useState([]);

  useEffect(() => {
    StaffService.getStaffByRole("2")
      .then((response) => {
        setInstructors(response.data);
        setOptions(
          response.data.map((s_inst) => ({
            value: s_inst.id,
            label: s_inst.first_name + " " + s_inst.last_name,
          }))
        );
      })
      .catch((e) => {
        console.log(e);
      });

      StaffService.getAll().then((response) => {
        setAllstaffs(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    BookingService.getBookingBy(id)
      .then((response) => {
        setBooking(response.data[0]);
        // console.log(response.data[0].booking_id);
        setTrainingId(response.data[0].training_id);

        setSlot({
          ...slot,
          booking_id: response.data[0].booking_id,
          client_id: response.data[0].client_id,
          course_id: response.data[0].course_id,
          training_typeid: response.data[0].training_id,
        });

        SchedulingService.getByBookingId(response.data[0].booking_id)
          .then((response) => {
            // setTrainingTypes(response.data);
            setBookedSlots(response.data);
            // console.log(response.data);
            // setUnScheduledStudents(response.data);
          })
          .catch((e) => {
            console.log(e);
          });

          if (response.data[0].num_std === students.length) {
            setUnScheduledStudents([]);
          } else {
            setUnScheduledStudents(response.data[0].student_id.split(","));
          }

          setStudents(response.data[0].student_id.split(","));

          console.log(response.data[0].num_std)

        

        // setUnScheduled(response.data[0].student_id.split(","));

        TrainingTypeService.getTrainingTypeById(response.data[0].training_id)
          .then((response) => {
            console.log(response.data);
            const assetIds = response.data[0].asset_id.split(",");
            // console.log(response.data[0].asset_id);
            setAssetTypeValues(response.data[0].asset_id);
            setAssets(assetIds);
            // console.log(response.data[0].asset_id);

            const assetPromises = assetIds.map((assetId) =>
              AssetService.getAssetByAssetType(assetId)
            );

            const twoDAssets = [];

            Promise.all(assetPromises)
              .then((responses) => {
                // console.log(responses);

                responses.forEach((response, index) => {
                  // console.log(response.data);
                  twoDAssets.push(response.data);
                  // console.log(twoDAssets);
                  setNestedAssets(twoDAssets);
                });
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((e) => {
        console.log(e);
      });

    ClientService.getAll()
      .then((response) => {
        setClients(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    AssetTypeService.getAll()
      .then((response) => {
        setAssetsType(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    AssetService.getAll()
      .then((response) => {
        setAllAssets(response.data);
        // console.log(response.data);
        // const datas = response.data;

        // const filteredData = datas.filter(item => item.asset_type === '2');

        // console.log(filteredData);
      })
      .catch((e) => {
        console.log(e);
      });

    CourseService.getAll()
      .then((response) => {
        setCourses(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    TrainingTypeService.getAll()
      .then((response) => {
        setTrainingTypes(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    StudentService.getAll()
      .then((response) => {
        setAllStudents(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

  }, []);

  function getClientName(clientId, clients) {
    const client = clients.find((r) => r.id === clientId);
    return client ? client.name : "";
  }

  function getCourseName(courseId, courses) {
    const course = courses.find((r) => r.id === courseId);
    return course ? course.name : "";
  }

  function getTrainingTypes(trainingId, trainingTypes) {
    const trainingType = trainingTypes.find((r) => r.id === trainingId);
    return trainingType ? trainingType.training_type : "";
  }

  const contentArray = Array.from(
    { length: booking.num_std },
    (_, index) => index + 1
  );

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSlot({ ...slot, [name]: value });
  };

  const [validated, setValidated] = useState(false);

  const [assetIdValues, setAssetIdValues] = useState({});

  const [assetFromTimeValues, setAssetFromTimeValues] = useState({});

  const [assetToTimeValues, setAssetToTimeValues] = useState({});

  const handleFromTimeChange = (event) => {
    const { name, value } = event.target;
    setAssetFromTimeValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleToTimeChange = (event) => {
    const { name, value } = event.target;
    setAssetToTimeValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  function getAssetName(assetId, allAssets) {
    const allAsset = allAssets.find((r) => r.id === assetId);
    return allAsset ? allAsset.name : "";
  }

  const handleSelectChange = (id, event) => {
    setShow(true);
    setTimingsId(id);
    setShowAssetValues(true);
    const { name, value } = event.target;
    setAssetIdValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  console.log(assetIdValues);

  function getAssetTypeName(assetTypeID, assetsType) {
    const assetType = assetsType.find((r) => r.id === assetTypeID);
    return assetType ? assetType.name : "Unknown Course";
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const assetIds = Object.values(assetIdValues).join(", ");

    const assetFromTimes = Object.values(assetFromTimeValues).join(", ");

    const assetToTimes = Object.values(assetToTimeValues).join(", ");

    const data = {
      booking_id: slot.booking_id,
      client_id: slot.client_id,
      course_id: slot.course_id,
      training_typeid: slot.training_typeid,
      date: slot.date,
      time_from: slot.time_from,
      time_to: slot.time_to,
      student_id: slot.student_id,
      asset_typeid: assetTypeValues,
      asset_id: assetIds,
      atime_from: assetFromTimes,
      atime_to: assetToTimes,
      instructor_id: slot.instructor_id,
      support_instid: slot.support_instid,
      note: slot.note,
    };
    console.log(data);

    const updatedStudents = students.filter((student) => student.id !== slot.student_id);

    setStudents(updatedStudents);

    if (Object.values(data).every((value) => value)) {
      SchedulingService.create(data)
        .then((response) => {
          console.log(response.data);
          // setBooking(response.data);
          setSubmitted(true);
          setMessage(response.data.message);

          window.location.reload();
          
          
          // setStudents(students.filter((student) => student.id !== slot.student_id));
        })
        .catch((e) => {
          console.log(e);
          setSubmitted(false);
        });
    }
  };

  console.log(students);

  function getStudentsName(studentsID, allStudents) {
    const allStudent = allStudents.find((r) => r.id === studentsID);
    return allStudent
      ? allStudent.first_name + " " + allStudent.last_name
      : "Unknown Course";
  }

  function getStaffName(staffId, allStaffs) {
    const allStaff = allStaffs.find((r) => r.id === staffId);
    return allStaff
      ? allStaff.first_name + " " + allStaff.last_name
      : "Unknown staff";
  }

  const getSIntr = (staffId, allStaffs) => {
    const staffID = staffId.split(",");
    const assetNames = [];
    for (let i = 0; i < staffID.length; i++) {
      const matchedAsset = allStaffs.find((r) => r.id === staffID[i]);
      if (matchedAsset) {
        assetNames.push((matchedAsset.first_name + " " + matchedAsset.last_name));
      } else {
        assetNames.push("Unknown Staff");
      }
    } 
    return assetNames.join(", ");
  };


  const [activeIndex, setActiveIndex] = useState(0);

  const handleItemClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <>
      <Container fluid>
        {loading ? (
          <div className="loader-container">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <Row>
              <div className="proressBar">
                <div className="step active">
                  <div className="circle">
                    <img
                      src={BookingIcon}
                      alt=""
                      style={{ width: "20px", height: "17px" }}
                    />
                  </div>
                  <div className="text">Booking Request</div>
                </div>
                <div className="line">
                  <div className="dots"></div>
                  <div className="dots"></div>
                  <div className="dots"></div>
                </div>
                <div className="plane">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 36 36"
                  >
                    <g id="PlainIcon" transform="translate(0.001 0.002)">
                      <g
                        id="Layer_1"
                        data-name="Layer 1"
                        transform="translate(-0.001 -0.002)"
                      >
                        <path
                          id="Path_11626"
                          data-name="Path 11626"
                          d="M2.282,16.817c-.065-.147-.129-.292-.194-.438L.05,11.854c-.126-.28-.012-.457.3-.457s.6,0,.9,0a.434.434,0,0,1,.323.127Q3.581,13.394,5.6,15.26c.109.1.225.2.324.3a.354.354,0,0,0,.308.11q3.2-.131,6.395-.257l4.123-.166c.038,0,.076-.005.137-.009-.017-.07-.029-.132-.046-.192L12.853.537C12.836.473,12.817.409,12.8.345A.261.261,0,0,1,13.083,0c.555,0,1.112,0,1.666,0,.634,0,.5-.072.835.441Q20.3,7.608,25.016,14.777a.266.266,0,0,0,.267.137c1.641-.04,3.283-.043,4.921.06a11.308,11.308,0,0,1,3.531.7,4.818,4.818,0,0,1,1.644,1.007,1.708,1.708,0,0,1,0,2.628,4.83,4.83,0,0,1-1.67,1.019,10.557,10.557,0,0,1-3,.652,53.89,53.89,0,0,1-5.485.1.2.2,0,0,0-.194.109q-3.131,4.762-6.265,9.522Q17.1,33.258,15.426,35.8a.387.387,0,0,1-.368.195c-.637-.007-1.275,0-1.912,0-.31,0-.406-.124-.323-.421l4.023-14.649c.014-.05.025-.1.042-.164-.14-.009-.269-.02-.4-.025l-6.106-.24c-1.3-.051-2.595-.1-3.892-.161a.433.433,0,0,0-.344.129q-2.285,2.124-4.574,4.245a.488.488,0,0,1-.363.141c-.285-.005-.57,0-.855,0-.319,0-.437-.179-.309-.463L2.3,19.394c.029-.064.058-.128.09-.2-.211-.046-.409-.081-.6-.133a2.507,2.507,0,0,1-1.129-.606.555.555,0,0,1-.008-.882,2.649,2.649,0,0,1,1.454-.7c.043-.008.087-.015.128-.025a.335.335,0,0,0,.052-.023Z"
                          transform="translate(0.001 0.002)"
                          fill="#26ac96"
                        />
                      </g>
                    </g>
                  </svg>
                </div>
                <div className="line">
                  <div className="dots"></div>
                  <div className="dots"></div>
                  <div className="dots"></div>
                </div>
                <div className="step active">
                  <div className="circle">
                    <img
                      src={SchedulingIcon}
                      alt=""
                      style={{ width: "20px", height: "17px" }}
                    />
                  </div>
                  <div className="text">Scheduled Booking</div>
                </div>
              </div>
              <div className="schedulingInfo">
                <div className="schedulingInfoTable">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Client Name</th>
                          <th>Course</th>
                          <th>Training Type</th>
                          <th>No of Students</th>
                          <th>List of Student</th>
                          <th>Prefered Dates</th>
                          <th>Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{getClientName(booking.client_id, clients)}</td>
                          <td>{getCourseName(booking.course_id, courses)}</td>
                          <td>
                            {getTrainingTypes(
                              booking.training_id,
                              trainingTypes
                            )}
                          </td>
                          <td>{booking.num_std}</td>
                          <td>
                            <Form.Select
                              aria-label="Default select example"
                              name="student_id"
                              value={booking.student_id}
                              onChange={handleInputChange}
                              required
                            >
                              {Array.isArray(students)
                                ? students.map((student) => {
                                    return (
                                      <option key={student} value={student}>
                                        {getStudentsName(student, allStudents)}
                                      </option>
                                    );
                                  })
                                : null}

                                {/* {Array.isArray(bookedSlots)
                                ? bookedSlots.map((bookedSlot) => {
                                    return (
                                      <option key={bookedSlot.student_id} value={bookedSlot.student_id}>
                                        {getStudentsName(bookedSlot.student_id, allStudents)}
                                      </option>
                                    );
                                  })
                                : null} */}
                            </Form.Select>
                          </td>
                          <td>
                            {booking.date_from}-{booking.date_to}
                          </td>
                          <td>
                            {booking.time_from}-{booking.time_to}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {submitted ? (
                    <Row>
                      <Alert variant="success">{message}</Alert>
                    </Row>
                  ) : (
                    <></>
                  )}
                  {contentArray.map((item, index) => (
                    <Form
                      noValidate validated={validated}
                      onSubmit={handleSubmit}
                      className={
                        index === activeIndex ? "slots active" : "slots"
                      }
                    >
                      <Row>
                        <Col xl={8} lg={8}>
                          <div className="slot-info">
                            <h3>Slot {item}</h3>
                          </div>
                          <Form.Group className="slot-flex">
                            <label htmlFor="">Booking ID</label>
                            <Form.Control
                              type="text"
                              name="booking_id"
                              value={slot.booking_id}
                              onChange={handleInputChange}
                              disabled
                            />
                            <Form.Control
                              type="date"
                              name="date"
                              value={slot.date}
                              onChange={handleInputChange}
                              required
                            />
                            <Form.Control
                              type="time"
                              name="time_from"
                              value={slot.time_from}
                              onChange={handleInputChange}
                              required
                            />
                            <Form.Control
                              type="time"
                              name="time_to"
                              value={slot.time_to}
                              onChange={handleInputChange}
                              required
                            />
                            <Form.Select
                              aria-label="Default select example"
                              name="student_id"
                              value={slot.student_id}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="0">Select Student</option>
                              {Array.isArray(unScheduledStudents)
                                ? unScheduledStudents.map((unScheduledStudent) => {
                                    return (
                                      <option key={unScheduledStudent} value={unScheduledStudent}>
                                        {getStudentsName(unScheduledStudent, allStudents)}
                                      </option>
                                    );
                                  })
                                : null}
                            </Form.Select>
                          </Form.Group>
                          <div className="slot-info">
                            <h3>Assets</h3>
                            <Form.Group className="slot-flex slot-flex2">
                              {nestedAssets.map((innerArray, index) => (
                                <>
                                  <div className="assets-flex">
                                    <label htmlFor="">
                                      {getAssetTypeName(
                                        innerArray[0].asset_type,
                                        assetsType
                                      )}
                                    </label>
                                    <Form.Select
                                      aria-label="Default select example"
                                      name={`asset_id${index}`}
                                      value={slot[`asset_id${index}`]}
                                      onChange={(event) =>
                                        handleSelectChange(
                                          innerArray[0].asset_type,
                                          event
                                        )
                                      }
                                      required
                                    >
                                      <option value="0">Select</option>
                                      {innerArray.map((item, innerIndex) => (
                                        <option
                                          key={innerIndex}
                                          value={item.id}
                                        >
                                          {item.name}
                                        </option>
                                      ))}
                                    </Form.Select>
                                    <div className="selectTimes">
                                      <Form.Control
                                        type="time"
                                        name={`atime_from${index}`}
                                        value={slot[`atime_from${index}`]}
                                        onChange={handleFromTimeChange}
                                        required
                                      />
                                      <Form.Control
                                        type="time"
                                        name={`atime_to${index}`}
                                        value={slot[`atime_to${index}`]}
                                        onChange={handleToTimeChange}
                                        required
                                      />
                                      {showAssetValues ? (
                                        timingsId ===
                                        innerArray[0].asset_type ? (
                                          <>
                                            <div
                                              className={
                                                show &&
                                                timingsId ===
                                                  innerArray[0].asset_type
                                                  ? "timings active"
                                                  : "timings"
                                              }
                                              id={innerArray[0].asset_type}
                                            >
                                              <div className="head">
                                                <h2>
                                                  {assetIdValues.asset_id0}
                                                </h2>
                                              </div>
                                              <div className="time-list">
                                                <h3>Available Time</h3>
                                                <div className="times">
                                                  <button title="available">
                                                    12AM-10AM
                                                  </button>
                                                  <button
                                                    title="booked"
                                                    className="booked"
                                                  >
                                                    10AM-12PM
                                                  </button>
                                                  <button title="available">
                                                    12PM-12AM
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <></>
                                        )
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ))}
                            </Form.Group>
                          </div>
                        </Col>
                        <Col xl={4} lg={4}>
                          <div className="slot-info">
                            <h3>Instructor</h3>
                          </div>
                          <Form.Group className="slot-flex">
                            <Form.Select
                              aria-label="Default select example"
                              name="instructor_id"
                              value={slot.instructor_id}
                              onChange={handleInputChange}
                              required
                              className="instructor"
                            >
                              <option value="0">Select Instructor</option>
                              {Array.isArray(instructors)
                                ? instructors.map((instructor) => {
                                    return (
                                      <option
                                        key={instructor.id}
                                        value={instructor.id}
                                      >
                                        {instructor.first_name} {instructor.last_name}
                                      </option>
                                    );
                                  })
                                : null}
                            </Form.Select>
                          </Form.Group>
                          <div className="slot-info">
                            <h3>Supporting Instructor</h3>
                          </div>
                          <Form.Group className="slot-flex">
                            <Select
                              options={options}
                              placeholder="Supporting Instructor"
                              onChange={handleOptionChange}
                              isSearchable={true}
                              isMulti
                              className="form-control"
                              value={instructors.find(
                                (option) => option.value === slot.support_instid
                              )}
                            />
                          </Form.Group>
                          <div className="slot-info">
                            <h3>Note</h3>
                          </div>
                          <Form.Group className="slot-flex">
                            <Form.Control
                              type="text"
                              name="note"
                              onChange={handleInputChange}
                              className="instructor"
                            />
                          </Form.Group>
                        </Col>
                        <Col xl={12} lg={12}>
                          <Button type="submit" className="nextButton">
                            Submit
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  ))}
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Slot</th>
                          <th>Booking ID</th>
                          <th>Date</th>
                          <th>Time</th>
                          <th>Student Name</th>
                          <th>Instructor</th>
                          <th>Supporting Instructor</th>
                          <th>Course</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(bookedSlots)
                          ? bookedSlots.map((bookedSlot, index) => {
                              return (
                                <>
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{bookedSlot.booking_id}</td>
                                    <td>{bookedSlot.date}</td>
                                    <td>{bookedSlot.time_from}-{bookedSlot.time_to}</td>
                                    <td>{getStudentsName(bookedSlot.student_id, allStudents)}</td>
                                    <td>{getStaffName(bookedSlot.instructor_id, allstaffs)}</td>
                                    <td>{getSIntr(bookedSlot.support_instid, allstaffs)}</td>
                                    <td>{getCourseName(bookedSlot.course_id, courses)}</td>
                                    <td>
                                      <div className="flex">
                                        <img
                                          onClick={handleViewShow}
                                          id="viewAsset"
                                          data-bs-target={1}
                                          src={ViewIcon}
                                          alt="View Asset"
                                          title="View Asset"
                                          style={{
                                            width: "24px",
                                            height: "12px",
                                          }}
                                        />
                                        <Link to={"/EditAsset/" + 1}>
                                          <img
                                            src={EditIcon}
                                            alt="Edit Asset"
                                            title="Edit Asset"
                                            style={{
                                              width: "18px",
                                              height: "18px",
                                            }}
                                          />
                                        </Link>
                                        <img
                                          onClick={handleRemoveShow}
                                          id="deleteAsset"
                                          data-bs-target={1}
                                          src={DeleteIcon}
                                          alt="Delete Asset"
                                          title="Delete Asset"
                                          style={{
                                            width: "18px",
                                            height: "18px",
                                          }}
                                        />
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Row>
          </>
        )}
      </Container>
    </>
  );
};

export default ScheduleBooking;
