import axios from "axios";

export default axios.create({
  baseURL: "https://tms.aeonsoftware.net/cit/",
  headers: {
    Accept: "application/x-www-form-urlencoded",
    "Content-type": "application/x-www-form-urlencoded",
    // "Content-Type" : "application/json",
    "x-api-key": "pass123"
  }
});