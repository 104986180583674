import http from "../http-common";

const getAll = () => {
  return http.get("/training/assetstype/index");
};

const getAssetTypeBy = (id) => {
  return http.get(`/training/assetstype/index?id=${id}`);
};

const create = (data) => {
    return http.post("/training/assetstype/index", data)
}

const isExist = (ek) => {
  return http.get(`/training/assetstype/rolekey?ek=${ek}`);
}

const remove = (id) => {
  return http.delete(`/training/assetstype/index?id=${id}`);
};


const AssetTypeService = {
  getAll,
  getAssetTypeBy,
  create,
  isExist,
  remove
};

export default AssetTypeService;