import http from "../http-common";

const getAll = () => {
  return http.get("/booking/Bookingdata/index");
};

const getBookingBy = (id) => {
  return http.get(`/booking/Bookingdata/index?id=${id}`);
};

const getBookingByCid = (cid) => {
  return http.get(`/booking/Bookingdata/bookingbyclient?cid=${cid}`);
};

const create = (data) => {
    return http.post("/booking/Bookingdata/index", data)
}

const isExist = (ek) => {
  return http.get(`/booking/Bookingdata/rolekey?ek=${ek}`);
}

const chnageSts = (id) => {
  return http.get(`/booking/Bookingdata/index?id=${id}`);
}

const remove = (id) => {
  return http.delete(`/booking/Bookingdata/index?id=${id}`);
};


const BookingService = {
  getAll,
  getBookingBy,
  getBookingByCid,
  create,
  isExist,
  chnageSts,
  remove
};

export default BookingService;