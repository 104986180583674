import React, { useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import ProfileImg from "../../../images/ProfileImg.png";
import ColorIcon from "../../../images/color.png";
import TitleIcon from "../../../images/title.png";
import LogoIcon from "../../../images/logoIcon.png";
import "./settings.css";

import Title from "./Title";
import Color from "./Color";
import Logo from "./Logo";

const Settings = () => {
  const [settingsData, setSettingsData] = useState("one");

  const [imgPath, setImgPath] = useState({
    logoPath: "male-placeholder.jpg"
  });

  const showSettingsData = (count) => {
    setSettingsData(count);
  };

  return (
    <>
      <Container>
        <Row>
          <Col xl={3} lg={3} md={12} sm={12}>
            <div className="left">
              <div className="logo">
                <img src={`https://tms.aeonsoftware.net/cit//uploads/${imgPath.logoPath}`} alt="" />
              </div>
              <div
                className={settingsData === "one" ? "action active" : "action"}
              >
                <button onClick={() => showSettingsData("one")}>
                  <span className="icon">
                    <svg
                      id="title"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="21"
                      viewBox="0 0 32.784 30.126"
                    >
                      <g
                        id="Layer_1"
                        data-name="Layer 1"
                        transform="translate(0)"
                      >
                        <path
                          id="Path_11477"
                          data-name="Path 11477"
                          d="M32.782,3.256V26.865a.3.3,0,0,0-.05.079,3.769,3.769,0,0,1-3.967,3.178H9.018c-1.739,0-3.478.01-5.218,0A3.766,3.766,0,0,1,0,26.283V3.837a3.929,3.929,0,0,1,.093-.89A3.767,3.767,0,0,1,3.866,0H28.893a3.669,3.669,0,0,1,3.4,1.947A8.756,8.756,0,0,1,32.782,3.256ZM2.051,6.862V26.187a1.729,1.729,0,0,0,1.87,1.882H28.849a1.731,1.731,0,0,0,1.882-1.9V6.862Zm0-2.086H30.723c0-.414.033-.812-.006-1.2a1.731,1.731,0,0,0-1.892-1.525H3.958a3.07,3.07,0,0,0-.509.039A1.681,1.681,0,0,0,2.059,3.6C2.03,3.986,2.051,4.371,2.051,4.776Z"
                          transform="translate(0.002)"
                        />
                        <path
                          id="Path_11478"
                          data-name="Path 11478"
                          d="M53.688,130.153H49.947v.928a1.028,1.028,0,1,1-2.048-.005c0-.63,0-1.262,0-1.892a1.025,1.025,0,0,1,1.106-1.094H60.461a1.011,1.011,0,0,1,1.09,1.074c.008.663.008,1.3,0,1.955a1.023,1.023,0,0,1-1,1.073,1.034,1.034,0,0,1-1.048-1.061c-.011-.318,0-.637,0-.973H55.786v9.555h.583a1.029,1.029,0,0,1,1.085,1.052,1.01,1.01,0,0,1-1.1,1q-1.632.008-3.264,0A1.024,1.024,0,0,1,52,140.759a1.037,1.037,0,0,1,1.1-1.044h.593Z"
                          transform="translate(-43.8 -117.133)"
                        />
                        <path
                          id="Path_11479"
                          data-name="Path 11479"
                          d="M243.634,130.132H240.69a1.032,1.032,0,1,1,.01-2.052h5.888a1.034,1.034,0,1,1-.008,2.052C245.6,130.134,244.615,130.132,243.634,130.132Z"
                          transform="translate(-219.059 -117.124)"
                        />
                        <path
                          id="Path_11480"
                          data-name="Path 11480"
                          d="M243.621,194.044h-2.944a1.03,1.03,0,1,1,0-2.05q2.977-.007,5.953,0a1.032,1.032,0,0,1,1.069.747.963.963,0,0,1-.408,1.118,1.393,1.393,0,0,1-.662.178C245.626,194.055,244.624,194.044,243.621,194.044Z"
                          transform="translate(-219.058 -175.568)"
                        />
                        <path
                          id="Path_11481"
                          data-name="Path 11481"
                          d="M243.606,258.238c-.992,0-1.984.009-2.976,0a1.028,1.028,0,0,1-1.04-1.358.963.963,0,0,1,.91-.684q3.136-.012,6.272,0a1,1,0,0,1,.951.916,1.017,1.017,0,0,1-.8,1.086,2.01,2.01,0,0,1-.412.038Q245.063,258.24,243.606,258.238Z"
                          transform="translate(-219.042 -234.276)"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">Title</span>
                </button>
              </div>
              <div
                className={settingsData === "two" ? "action active" : "action"}
              >
                <button onClick={() => showSettingsData("two")}>
                  <span className="icon">
                    <svg
                      id="color"
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 29.859 30.126"
                    >
                      <g
                        id="Layer_1"
                        data-name="Layer 1"
                        transform="translate(0 0)"
                      >
                        <path
                          id="Path_11466"
                          data-name="Path 11466"
                          d="M11.555,90.554h9.563c.65,0,.831.187.819.839A11,11,0,0,1,12.3,102.05a1.884,1.884,0,0,0-1.114.281c-.112.092-.4-.02-.607-.064a3.625,3.625,0,0,0-.462-.161A11.016,11.016,0,0,1,.069,92.357,10.774,10.774,0,0,1,2.092,84.7a10.656,10.656,0,0,1,6.62-4.308,14.539,14.539,0,0,1,1.974-.234c.674-.049.866.152.866.822v9.1Zm6.752,7.078a9.725,9.725,0,0,0,2.425-5.867h-8.28ZM10.353,81.35a9.643,9.643,0,0,0-5.828,2.407l5.828,5.85ZM3.578,97.568,9.338,91.8H1.2A9.688,9.688,0,0,0,3.578,97.568Zm13.764.994-5.778-5.786v8.132A9.446,9.446,0,0,0,17.342,98.561ZM9.39,90.569,3.577,84.747c-1.255,1.192-2.566,4.44-2.325,5.822ZM4.574,98.532a9.243,9.243,0,0,0,5.751,2.359V92.772Z"
                          transform="translate(-0.001 -72.24)"
                        />
                        <path
                          id="Path_11467"
                          data-name="Path 11467"
                          d="M142.236,8.184a3.046,3.046,0,0,1-.267.353q-3.586,3.606-7.184,7.189a3.25,3.25,0,0,1-.912.592c-.511.239-1.046.428-1.579.627a1.712,1.712,0,0,1-2.224-2.219c.225-.625.468-1.244.734-1.853a2.052,2.052,0,0,1,.43-.592q3.621-3.643,7.256-7.273a1.968,1.968,0,0,1,.242-.2,4.663,4.663,0,0,1-.395-1.12,2.425,2.425,0,0,1,1.893-1.93,4.732,4.732,0,0,1,1.113.388L143,.492a1.2,1.2,0,0,1,1.927,0q.784.783,1.566,1.57a1.221,1.221,0,0,1-.012,1.958c-.572.575-1.15,1.147-1.719,1.715a1.289,1.289,0,0,1-.2,1.974q-.169.171-.339.339C143.56,8.7,143.1,8.753,142.236,8.184Zm-2.632-2.6a.466.466,0,0,0-.064.049q-3.75,3.75-7.489,7.507a.856.856,0,0,0-.188.267c-.231.569-.477,1.136-.664,1.721a.687.687,0,0,0,.114.592.716.716,0,0,0,.592.094,8,8,0,0,0,1.237-.476,3.186,3.186,0,0,0,.895-.544q3.582-3.552,7.131-7.132c.1-.1.182-.2.249-.264Zm4.38-4.275-1.776,1.8,1.715,1.723,1.789-1.791Zm-4.426,2.334,3.661,3.7.612-.6-3.64-3.676Z"
                          transform="translate(-117.122 -0.006)"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">Color</span>
                </button>
              </div>
              <div
                className={
                  settingsData === "three" ? "action active" : "action"
                }
              >
                <button onClick={() => showSettingsData("three")}>
                  <span className="icon">
                    <svg
                      id="logo"
                      xmlns="http://www.w3.org/2000/svg"
                      width="21"
                      height="21"
                      viewBox="0 0 30.081 30.126"
                    >
                      <g
                        id="Layer_1"
                        data-name="Layer 1"
                        transform="translate(0)"
                      >
                        <path
                          id="Path_11468"
                          data-name="Path 11468"
                          d="M13.9,0h2.234c.539.217.512.7.508,1.162H26.825C26.782.691,26.8.242,27.289,0h2.29l.471.471V2.765c-.24.507-.712.49-1.164.476v10.2c.465,0,.948-.038,1.164.505v2.236c-.216.537-.694.515-1.16.513v10.2c.471-.042.919-.026,1.16.464v2.3l-.471.471h-2.29c-.5-.241-.49-.713-.476-1.154H16.625c.033.483.026.937-.5,1.154H13.9c-.537-.217-.513-.7-.507-1.161H3.2c.061.479.027.917-.457,1.161H.451a.916.916,0,0,1-.479-.951c.028-.479.005-.96.006-1.44,0-.6.242-.843.83-.845h.328V16.685H.8c-.577-.013-.815-.253-.816-.83,0-.559,0-1.118,0-1.677a.669.669,0,0,1,.706-.727c.151-.006.3,0,.453,0V3.236H.811c-.586,0-.828-.249-.83-.845,0-.49.02-.979-.009-1.47A.9.9,0,0,1,.451,0h2.29c.507.24.49.713.475,1.154H13.4C13.372.671,13.376.217,13.9,0Zm14.08,26.874v-10.2c-.177,0-.341.013-.5,0a.66.66,0,0,1-.655-.689q-.014-.927,0-1.853a.655.655,0,0,1,.662-.679c.163-.009.327,0,.487,0V3.337c-.351-.114-.74-.13-.938-.333s-.2-.594-.3-.925H16.622c0,.185.012.352,0,.517a.654.654,0,0,1-.675.636q-.941.015-1.878,0a.648.648,0,0,1-.656-.624c-.016-.173,0-.348,0-.529H3.308c-.109.347-.12.745-.324.936s-.607.213-.931.314V13.453c.181,0,.346-.009.51,0a.644.644,0,0,1,.642.637q.022.97,0,1.941a.642.642,0,0,1-.612.636c-.173.02-.349.02-.539.03V26.8c.343.105.737.115.932.314s.217.607.319.941H13.4v-.421a.682.682,0,0,1,.756-.741c.588,0,1.177-.006,1.762,0a.665.665,0,0,1,.706.67c.012.161,0,.324,0,.49H26.788C26.948,26.949,26.948,26.949,27.979,26.874ZM2.309,2.34V.906H.877V2.34Zm26.837,0V.907H27.716V2.341Zm-28.27,12v1.435H2.308v-1.43Zm28.279.013H27.718v1.422h1.438ZM.882,27.786v1.435H2.315V27.786Zm28.264,1.445V27.8H27.716v1.434ZM14.3.9V2.335h1.439V.9ZM14.29,29.221h1.44V27.8H14.29Z"
                          transform="translate(0.031)"
                        />
                        <path
                          id="Path_11469"
                          data-name="Path 11469"
                          d="M65.854,65.895a1.862,1.862,0,0,0-1.876-1.3c-2.789.02-5.578.006-8.367.008a1.735,1.735,0,0,0-1.751,1.291h2.464a.426.426,0,0,1,.471.415.432.432,0,0,1-.461.461c-.587.012-1.177,0-1.761,0-.362,0-.725-.01-1.086.008a.381.381,0,0,0-.27.157Q51.462,69.963,49.725,73a1.509,1.509,0,0,0,0,1.569c1.19,2.083,2.394,4.158,3.584,6.24.1.173.228.151.37.151H65.188a2.422,2.422,0,0,0,1.089-.06c.283-.161.392-.588.572-.9.266-.457.527-.916.795-1.372.169-.287.4-.373.628-.247a.455.455,0,0,1,.132.687c-.682,1.189-1.355,2.383-2.058,3.56A2.38,2.38,0,0,1,64.2,83.846q-4.36.013-8.719,0a2.356,2.356,0,0,1-2.139-1.227q-2.21-3.794-4.384-7.61a2.344,2.344,0,0,1,0-2.441q2.175-3.814,4.384-7.61a2.375,2.375,0,0,1,2.165-1.232q4.345-.009,8.69,0a2.377,2.377,0,0,1,2.16,1.241q2.2,3.783,4.369,7.584a2.388,2.388,0,0,1,0,2.468c-.378.686-.777,1.36-1.171,2.04-.173.3-.421.38-.655.235a.458.458,0,0,1-.108-.672c.363-.638.726-1.276,1.1-1.908a1.707,1.707,0,0,0,0-1.859Q68.2,69.934,66.524,67a.387.387,0,0,0-.392-.224q-3.7.011-7.4,0H58.5c-.319-.01-.519-.177-.525-.436s.2-.445.538-.445h7.34ZM53.929,81.851a1.7,1.7,0,0,0,1.648,1.115h4.841c1.262,0,2.523.005,3.785,0a1.578,1.578,0,0,0,1.521-1.11Z"
                          transform="translate(-44.801 -58.721)"
                        />
                        <path
                          id="Path_11470"
                          data-name="Path 11470"
                          d="M152.511,121.024c-.812,0-1.624,0-2.436,0a1.2,1.2,0,1,1-.018-2.407q2.449-.014,4.9,0a1.205,1.205,0,0,1,1.255,1.081,1.182,1.182,0,0,1-.855,1.276,1.606,1.606,0,0,1-.434.049C154.115,121.026,153.313,121.025,152.511,121.024Zm0-.883h2.432c.217,0,.375-.078.381-.308s-.157-.319-.364-.338c-.058,0-.117,0-.175,0h-4.571a.935.935,0,0,0-.289.026c-.2.067-.3.241-.191.411a.555.555,0,0,0,.4.2c.792.02,1.583.009,2.375.009Z"
                          transform="translate(-137.142 -109.305)"
                        />
                        <path
                          id="Path_11471"
                          data-name="Path 11471"
                          d="M152.444,237.929h-2.406a1.207,1.207,0,1,1,0-2.41q2.432-.007,4.871,0a1.2,1.2,0,0,1,.319,2.377,1.685,1.685,0,0,1-.378.031Q153.648,237.931,152.444,237.929Zm.009-.883h2.374c.228,0,.418-.038.446-.306.022-.209-.157-.341-.447-.342h-4.748c-.188,0-.356.028-.419.235-.068.231.094.406.392.408.8.007,1.6,0,2.4,0Z"
                          transform="translate(-137.087 -217.038)"
                        />
                        <path
                          id="Path_11472"
                          data-name="Path 11472"
                          d="M125.141,165.63a2.07,2.07,0,1,1-1.468.6A2.069,2.069,0,0,1,125.141,165.63Zm0,.883a1.189,1.189,0,1,0,.843.357,1.186,1.186,0,0,0-.843-.357Z"
                          transform="translate(-113.403 -152.635)"
                        />
                        <path
                          id="Path_11473"
                          data-name="Path 11473"
                          d="M241,167.771a2.056,2.056,0,1,1,.593,1.469A2.066,2.066,0,0,1,241,167.771Zm3.251.025a1.183,1.183,0,1,0-.361.84,1.185,1.185,0,0,0,.361-.84Z"
                          transform="translate(-222.089 -152.719)"
                        />
                        <path
                          id="Path_11474"
                          data-name="Path 11474"
                          d="M186.448,167.955c-.169-.008-.314-.006-.458-.023a.434.434,0,0,1-.422-.441.421.421,0,0,1,.432-.426c.314-.013.628-.015.941,0a.482.482,0,0,1,.479.549,1.875,1.875,0,0,1-1.731,1.947,2.058,2.058,0,0,1-2.169-1.81,2.075,2.075,0,0,1,3.127-2.027.442.442,0,1,1-.428.765,1.25,1.25,0,0,0-.933-.15,1.19,1.19,0,0,0,.48,2.33A.848.848,0,0,0,186.448,167.955Z"
                          transform="translate(-169.107 -152.434)"
                        />
                        <path
                          id="Path_11475"
                          data-name="Path 11475"
                          d="M90.769,168.914c.24,0,.443-.006.646,0a.439.439,0,0,1,.484.437.427.427,0,0,1-.471.441c-.342.008-.684.009-1.025,0a.472.472,0,0,1-.508-.517q-.009-1.569,0-3.138a.424.424,0,0,1,.438-.477.433.433,0,0,1,.439.483c.007.792,0,1.585,0,2.377Z"
                          transform="translate(-82.835 -152.663)"
                        />
                        <rect
                          id="Rectangle_16917"
                          data-name="Rectangle 16917"
                          width="2.364"
                          height="0.881"
                          transform="translate(11.221 7.175)"
                        />
                        <path
                          id="Path_11476"
                          data-name="Path 11476"
                          d="M291.19,225.715l-1.406,2.456c-.013.023-.045.019-.067.005L289,227.77l1.439-2.5c.016-.03.034-.03.056-.017l.682.392a.045.045,0,0,1,.013.066Z"
                          transform="translate(-266.325 -207.577)"
                        />
                      </g>
                    </svg>
                  </span>
                  <span className="text">Logo</span>
                </button>
              </div>
            </div>
          </Col>
          <Col xl={7} lg={7} md={12} sm={12}>
            <div
              className={
                settingsData === "one"
                  ? "settings-data title active"
                  : "settings-data title"
              }
            >
              <Title />
            </div>
            <div
              className={
                settingsData === "two"
                  ? "settings-data color active"
                  : "settings-data color"
              }
            >
              <Color />
            </div>
            <div
              className={
                settingsData === "three"
                  ? "settings-data logo active"
                  : "settings-data logo"
              }
            >
              <Logo />
            </div>
          </Col>
          <Col xl={2} lg={2} md={12} sm={12}></Col>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
