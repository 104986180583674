import React, { useState, useEffect } from "react";
import { Container, Row, Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import SearchIcon from "../../../images/SearchIcon.png";
import "react-confirm-alert/src/react-confirm-alert.css";
import ViewIcon from "../../../images/view.png";
import EditIcon from "../../../images/EditIcon.png";
import DeleteIcon from "../../../images/DeleteIcon.png";

import { useParams } from "react-router-dom";

import { ThreeDots } from "react-loader-spinner";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import "../../../custom-css/datatable.css";

import "./scheduled.css";

import AuthService from "../../../services/auth.service";

import BookingService from "../../../services/booking.service";

import ClientService from "../../../services/client.service";

import CourseService from "../../../services/course.service";

import TrainingTypeService from "../../../services/trainingtype.service";

import ViewSlots from "./ViewSlots";

const ViewScheduledTrainings = () => {
  const { id } = useParams();

  const [roll, setroll] = useState("");

  const [bookings, setBookings] = useState([]);

  const [clients, setClients] = useState([]);

  const [courses, setCourses] = useState([]);

  const [trainingTypes, setTrainingTypes] = useState([]);

  const [loading, setLoading] = useState(true);

  const [bookingSts, setBookingSts] = useState("0");

  const [cancel, setCancel] = useState(false);

  const [bookingId, setBookingsId] = useState("");

  const [loadTable, setLoadTable] = useState(false);

  const [remove, setRemove] = useState(false);

  const [view, setView] = useState(false);

  const [did, setDid] = useState('');

  const [vid, setVid] = useState('');

  const [selectedData, setSelectedData] = useState(false);

  const handleRemoveData = (dataItem) => {
    setSelectedData(prevSelectedData =>
      prevSelectedData.filter(item => item.id !== dataItem.id)
    );
  };

  const handleCancelOpen = (e) => {
    setCancel(true);
    setBookingsId(e.target.getAttribute("data-bs-target"));
  };

  const handleCancelClose = () => {
    setCancel(false);
  };

  const handleRemoveShow = (e) => {
    setRemove(true);
    setDid(e.target.getAttribute('data-bs-target'));
  };

  const handleRemoveClose = () => {
    setRemove(false);
  };

  const handleViewShow = (e) => {
    setView(true);
    setVid(e.target.getAttribute('data-bs-target'));
  };

  const handleViewClose = () => {
    setView(false);
  };

  useEffect(() => {
    BookingService.getBookingBy(id)
      .then((response) => {
        setLoading(false);
        setBookings(response.data);
      })
      .catch((e) => {
        console.log(e);
        setLoading(true);
      });

    ClientService.getAll()
      .then((response) => {
        setClients(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    CourseService.getAll()
      .then((response) => {
        setCourses(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    TrainingTypeService.getAll()
      .then((response) => {
        setTrainingTypes(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    const user = AuthService.getCurrentUser();
    console.log(user);

    if (user) {
      setroll(user.user_id);
    }

    if (!$.fn.DataTable.isDataTable("#table")) {
      function filterGlobal() {
        $("#table")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#table")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            processing: true,
            select: true,
            bDestroy: true,
            // dom: "Bfrtip",

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
          setLoadTable(true);
        }, 1000);
      });
    }
  }, []);

  function getClientName(clientId, clients) {
    const client = clients.find((r) => r.id === clientId);
    return client ? client.name : "";
  }

  function getCourseName(courseId, courses) {
    const course = courses.find((r) => r.id === courseId);
    return course ? course.name : "";
  }

  function getTrainingTypes(trainingId, trainingTypes) {
    const trainingType = trainingTypes.find((r) => r.id === trainingId);
    return trainingType ? trainingType.training_type : "";
  }

  const handleBookingSts = (e) => {
    console.log(e.target.value);
    setBookingSts(e.target.value);
  };

  const cancelBooking = () => {};
  return (
    <>
      <Container fluid>
        {loading ? (
          <div className="loader-container">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <Row>
            <div className="bookingsInfo">
              <div
                className={loadTable ? "table-actions active" : "table-actions"}
              >
                <label htmlFor="">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Search"
                  />
                  <img src={SearchIcon} alt="" />
                </label>
              </div>
              <div className="bookingsInfoTable">
                <div className="table-responsive">
                  <table id="table" className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Slot</th>
                        <th>Date</th>
                        <th>Time</th>
                        <th>Student</th>
                        <th>Instructor</th>
                        <th>Supporting Instructor</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>15/05/2023</td>
                        <td>15:00-16:00</td>
                        {/* <td>{getCourseName(booking.course_id, courses)}</td> */}
                        <td>Alexa S</td>
                        <td>Teri Dactyl</td>
                        <td>Allie Grater, Olive Yew</td>
                        {/* <td>
                          {getTrainingTypes(booking.training_id, trainingTypes)}
                        </td>
                        <td>{booking.num_std}</td>
                        <td>{booking.date_from}</td>
                        <td>
                          {booking.time_from}-{booking.time_to}
                        </td> */}
                        <td>
                          <div className="flex">
                            <img
                              onClick={handleViewShow}
                              id="viewStaff"
                              data-bs-target={1}
                              src={ViewIcon}
                              alt="View Staff"
                              title="View Staff"
                              style={{ width: "24px", height: "12px" }}
                            />
                            <Link to={"/EditSlot/" + 1}>
                              <img
                                src={EditIcon}
                                alt="Edit Staff"
                                title="Edit Staff"
                                style={{ width: "18px", height: "18px" }}
                              />
                            </Link>
                            <img
                              onClick={handleRemoveShow}
                              id="deleteStaff"
                              data-bs-target={1}
                              src={DeleteIcon}
                              alt="Delete Staff"
                              title="Delete Staff"
                              style={{ width: "18px", height: "18px" }}
                            />
                          </div>
                        </td>
                      </tr>
                      {/* {Array.isArray(bookings)
                        ? bookings.map((booking, index) => {
                            return (
                              <>
                                {booking.status === "1" ? (
                                  <>
                                    <tr key={booking.id}>
                                      <td>{index + 1}</td>
                                      <td>{booking.booking_id}</td>
                                      <td>001</td>
                                      <td>
                                        {getCourseName(
                                          booking.course_id,
                                          courses
                                        )}
                                      </td>
                                      <td>
                                        {getTrainingTypes(
                                          booking.training_id,
                                          trainingTypes
                                        )}
                                      </td>
                                      <td>{booking.num_std}</td>
                                      <td>{booking.date_from}</td>
                                      <td>
                                        {booking.time_from}-{booking.time_to}
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })
                        : null} */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Row>
        )}
      </Container>
      <Modal show={remove} onHide={handleRemoveClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Booked Slot</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this slot</p>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control type="text" placeholder="Enter Reason" />
              {/* <Form.Label>Enter Reason</Form.Label> */}
            </Form.Group>
          </Form>
          <div className="flex">
            <Button onClick={cancelBooking}>Yes</Button>
            <Button variant="danger" onClick={handleRemoveClose}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={view} onHide={handleViewClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Alexa S</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewSlots vid={vid} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewScheduledTrainings;
