import http from "../http-common";

const getAll = () => {
  return http.get("/staff/staffdata/getsd");
};

const getStaffBy = (id) => {
  return http.get(`/staff/staffdata/getsd?id=${id}`);
};

const getStaffByRole = (rid) => {
  return http.get(`/staff/staffdata/getsd?role_id=${rid}`);
};

const create = (data) => {
    return http.post("/staff/staffdata/adst", data)
}

const isExist = (ek) => {
  return http.get(`/staff/staffdata/rolekey?ek=${ek}`);
}

const chnageSts = (id) => {
  return http.get(`/staff/staffdata/ssts?id=${id}`);
}

const remove = (id) => {
  return http.delete(`/staff/staffdata/delstf?id=${id}`);
};


const StaffService = {
  getAll,
  getStaffBy,
  getStaffByRole,
  create,
  isExist,
  chnageSts,
  remove
};

export default StaffService;