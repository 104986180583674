import React, { useState, useEffect } from "react";
import { Container, Row, Form, Col, Button, Alert } from "react-bootstrap";
import Select from "react-select";

import "./bookings.css";

import RoleService from "../../../services/role.service";

import AuthService from "../../../services/auth.service";

import TrainingTypeService from "../../../services/trainingtype.service";

import ClientService from "../../../services/client.service";

import CourseService from "../../../services/course.service";

import BookingService from "../../../services/booking.service";

import BookingIcon from "../../../images/BookingIcon.png";

import SchedulingIcon from "../../../images/schedulingIcon.png";
import StudentService from "../../../services/student.service";

const AddBooking = () => {
  const [validated, setValidated] = useState(false);

  const [roll, setroll] = useState("");

  const initialRole = {
    client_id: "",
    course_id: "",
    training_id: "",
    num_std: "",
    student_id: "",
    date_from: "",
    date_to: "",
    time_from: "",
    time_to: "",
  };

  const [booking, setBooking] = useState(initialRole);

  const [submitted, setSubmitted] = useState(false);

  const [clients, setClients] = useState([]);

  const [courses, setCourses] = useState([]);

  const [trainingstypes, setTrainingstypes] = useState([]);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  const [userID, setUserID] = useState("");

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [selectedValues, setSelectedValues] = useState([]);

  const handleOptionChange = (event) => {
    setSelectedOptions(event);
    const values = event.map((option) => option.value).toString();
    setSelectedValues(values);
    setBooking({ ...booking, student_id: values });
  };

  console.log(selectedValues);

  const [options, setOptions] = useState([]);

  const [clientId, setClientId] = useState("");

  const [courseId, setCourseId] = useState("");

  const getClientId = (e) => {
    setClientId(e.target.value);
  };

  const getCourses = (e) => {
    // console.log(e.target.value);
    setCourseId(e.target.value);
    const courseId = e.target.value;
    if (courseId > 0) {
      TrainingTypeService.getTrainingTypeByCourse(courseId)
        .then((response) => {
          setTrainingstypes(response.data);
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      setTrainingstypes([]);
    }
  };

  const getCoursesStudents = (e) => {
    // console.log(e.target.value);
    setCourseId(e.target.value);
    const courseId = e.target.value;
    if (courseId > 0) {
      TrainingTypeService.getTrainingTypeByCourse(courseId)
        .then((response) => {
          setTrainingstypes(response.data);
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });

      if (clientId !== 0) {
        StudentService.getStudentByClientCourse(clientId, courseId)
          .then((response) => {
            console.log(response.data);
            setOptions(
              response.data.map((student) => ({
                value: student.id,
                label: student.first_name + " " + student.last_name,
              }))
            );
          })
          .catch((e) => {
            console.log(e);
          });
      }
    } else {
      setTrainingstypes([]);
    }
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    console.log(user);

    if (user) {
      setroll(user.user_id);
      setUserID(user.id);
      if (user.user_id === "2") {
        setBooking({ ...booking, client_id: user.staff_id });
      }
    }

    ClientService.getAll()
      .then((response) => {
        // console.log(response.data);
        setClients(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    CourseService.getAll()
      .then((response) => {
        setCourses(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    if (clientId && courseId) {
      StudentService.getStudentByClientCourse(clientId, courseId)
        .then((response) => {
          console.log(response.data);
          setOptions(
            response.data.map((student) => ({
              value: student.id,
              label: student.first_name + " " + student.last_name,
            }))
          );
        })
        .catch((e) => {
          console.log(e);
        });
    }

    // TrainingTypeService.getAll()
    //   .then((response) => {
    //     setTrainingstypes(response.data);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  }, [clientId, courseId]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setBooking({ ...booking, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const data = {
      client_id: booking.client_id,
      course_id: booking.course_id,
      training_id: booking.training_id,
      num_std: booking.num_std,
      student_id: booking.student_id,
      date_from: booking.date_from,
      date_to: booking.date_to,
      time_from: booking.time_from,
      time_to: booking.time_to,
    };
    console.log(data);

    if (Object.values(data).every((value) => value)) {
      BookingService.create(data)
        .then((response) => {
          console.log(response.data);
          setBooking(response.data);
          setSubmitted(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  return (
    <>
      <Container>
        <Row>
          {/* <div className="proressBar">
            <div className="step active">
              <div className="circle">
                <img
                  src={BookingIcon}
                  alt=""
                  style={{ width: "20px", height: "17px" }}
                />
              </div>
              <div className="text">Booking Request</div>
            </div>
            <div className="line">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
            <div className="plane">
              <img src="" alt="" />
            </div>
            <div className="line">
              <div className="dots"></div>
              <div className="dots"></div>
              <div className="dots"></div>
            </div>
            <div className="step active">
              <div className="circle">
                <img
                  src={SchedulingIcon}
                  alt=""
                  style={{ width: "20px", height: "17px" }}
                />
              </div>
              <div className="text">Add Booking</div>
            </div>
          </div> */}
          <div className="bookingsInfo">
            <div className="AddBoxLarge">
              {submitted ? (
                <Row>
                  <div className="sucmessage">Booking Request Added Successfully!</div>
                </Row>
              ) : (
                <>
                  <div className="head">
                    <h3>Enter Details of Booking Request</h3>
                  </div>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Row>
                      {/* <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group className="mb-3">
                        <Form.Select
                          aria-label="Default select example"
                          name="client_id"
                          value={booking.client_id}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="0">Select</option>
                          <option value="1">ABC</option>
                          <option value="2">XYZ</option>
                        </Form.Select>
                        <Form.Label>Client Name</Form.Label>
                      </Form.Group>
                    </Col> */}
                      {roll === "3" ? (
                        <>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Select
                                aria-label="Default select example"
                                name="client_id"
                                value={booking.client_id}
                                onChange={(e) => {
                                  handleInputChange(e);
                                  getClientId(e);
                                }}
                                required
                              >
                                <option value="0">Select</option>
                                {Array.isArray(clients)
                                  ? clients.map((client) => {
                                      return (
                                        <option
                                          key={client.id}
                                          value={client.id}
                                        >
                                          {client.name}
                                        </option>
                                      );
                                    })
                                  : null}
                              </Form.Select>
                              <Form.Label>Client Name</Form.Label>
                            </Form.Group>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Select
                                aria-label="Default select example"
                                name="course_id"
                                value={booking.course_id}
                                onChange={(e) => {
                                  handleInputChange(e);
                                  getCourses(e);
                                }}
                                required
                              >
                                <option value="0">Select</option>
                                {Array.isArray(courses)
                                  ? courses.map((course) => {
                                      return (
                                        <option
                                          key={course.id}
                                          value={course.id}
                                        >
                                          {course.name}
                                        </option>
                                      );
                                    })
                                  : null}
                              </Form.Select>
                              <Form.Label>Course</Form.Label>
                            </Form.Group>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Row>
                              <Col xl={6} lg={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Select
                                    aria-label="Default select example"
                                    name="training_id"
                                    value={booking.training_id}
                                    onChange={handleInputChange}
                                    required
                                    title="Please select a course first"
                                  >
                                    <option value="0">Select</option>
                                    {Array.isArray(trainingstypes)
                                      ? trainingstypes.map((trainingtype) => {
                                          return (
                                            <option
                                              key={trainingtype.id}
                                              value={trainingtype.id}
                                            >
                                              {trainingtype.training_type}
                                            </option>
                                          );
                                        })
                                      : null}
                                  </Form.Select>
                                  <Form.Label>Training Type</Form.Label>
                                </Form.Group>
                              </Col>
                              <Col xl={6} lg={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Control
                                    type="text"
                                    name="num_std"
                                    value={booking.num_std}
                                    onChange={handleInputChange}
                                    required
                                  />
                                  <Form.Label>No. of Student</Form.Label>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Select
                                options={options}
                                placeholder="Students"
                                onChange={handleOptionChange}
                                isSearchable={true}
                                isMulti
                                className="form-control"
                                value={options.filter((option) =>
                                  booking.student_id.includes(option.value)
                                )}
                              />
                            </Form.Group>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Form.Group className="mb-3 joining_date">
                              <Form.Control
                                type="date"
                                name="date_from"
                                value={booking.date_from}
                                onChange={handleInputChange}
                                required
                              />
                              <Form.Label>Date From</Form.Label>
                            </Form.Group>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Form.Group
                              className="mb-3 joining_date"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="date"
                                name="date_to"
                                value={booking.date_to}
                                onChange={handleInputChange}
                                required
                              />
                              <Form.Label>Date To</Form.Label>
                            </Form.Group>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Form.Group
                              className="mb-3 joining_date"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="time"
                                name="time_from"
                                value={booking.time_from}
                                onChange={handleInputChange}
                                required
                              />
                              <Form.Label>Time From</Form.Label>
                            </Form.Group>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Form.Group
                              className="mb-3 joining_date"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="time"
                                name="time_to"
                                value={booking.time_to}
                                onChange={handleInputChange}
                                required
                              />
                              <Form.Label>Time To</Form.Label>
                            </Form.Group>
                          </Col>
                        </>
                      ) : roll === "2" ? (
                        <>
                          <Col
                            xl={6}
                            lg={6}
                            md={12}
                            sm={12}
                            style={{ display: "none" }}
                          >
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="text"
                                name="num_std"
                                value={booking.client_id}
                                onChange={handleInputChange}
                                required
                              />
                              <Form.Label>Client Name</Form.Label>
                            </Form.Group>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Select
                                aria-label="Default select example"
                                name="course_id"
                                value={booking.course_id}
                                onChange={(e) => {
                                  handleInputChange(e);
                                  // getCourses(e);
                                  getCoursesStudents(e);
                                }}
                                required
                              >
                                <option value="0">Select</option>
                                {Array.isArray(courses)
                                  ? courses.map((course) => {
                                      return (
                                        <option
                                          key={course.id}
                                          value={course.id}
                                        >
                                          {course.name}
                                        </option>
                                      );
                                    })
                                  : null}
                              </Form.Select>
                              <Form.Label>Course</Form.Label>
                            </Form.Group>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Row>
                              <Col xl={6} lg={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Select
                                    aria-label="Default select example"
                                    name="training_id"
                                    value={booking.training_id}
                                    onChange={handleInputChange}
                                    required
                                    title="Please select a course first"
                                  >
                                    <option value="0">Select</option>
                                    {Array.isArray(trainingstypes)
                                      ? trainingstypes.map((trainingtype) => {
                                          return (
                                            <option
                                              key={trainingtype.id}
                                              value={trainingtype.id}
                                            >
                                              {trainingtype.training_type}
                                            </option>
                                          );
                                        })
                                      : null}
                                  </Form.Select>
                                  <Form.Label>Training Type</Form.Label>
                                </Form.Group>
                              </Col>
                              <Col xl={6} lg={6}>
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlInput1"
                                >
                                  <Form.Control
                                    type="text"
                                    name="num_std"
                                    value={booking.num_std}
                                    onChange={handleInputChange}
                                    required
                                  />
                                  <Form.Label>No. of Student</Form.Label>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Select
                                options={options}
                                placeholder="Students"
                                onChange={handleOptionChange}
                                isSearchable={true}
                                isMulti
                                className="form-control"
                                value={options.filter((option) =>
                                  booking.student_id.includes(option.value)
                                )}
                              />
                            </Form.Group>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Form.Group className="mb-3 joining_date">
                              <Form.Control
                                type="date"
                                name="date_from"
                                value={booking.date_from}
                                onChange={handleInputChange}
                                required
                              />
                              <Form.Label>Date From</Form.Label>
                            </Form.Group>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Form.Group
                              className="mb-3 joining_date"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="date"
                                name="date_to"
                                value={booking.date_to}
                                onChange={handleInputChange}
                                required
                              />
                              <Form.Label>Date To</Form.Label>
                            </Form.Group>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Form.Group
                              className="mb-3 joining_date"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="time"
                                name="time_from"
                                value={booking.time_from}
                                onChange={handleInputChange}
                                required
                              />
                              <Form.Label>Time From</Form.Label>
                            </Form.Group>
                          </Col>
                          <Col xl={6} lg={6} md={12} sm={12}>
                            <Form.Group
                              className="mb-3 joining_date"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="time"
                                name="time_to"
                                value={booking.time_to}
                                onChange={handleInputChange}
                                required
                              />
                              <Form.Label>Time To</Form.Label>
                            </Form.Group>
                          </Col>
                        </>
                      ) : (
                        <></>
                      )}
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          type="submit"
                          className="nextButton"
                          // onClick={() => {
                          //   saverole();
                          // }}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AddBooking;
