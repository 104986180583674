import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Home from "../images/Home.png";
import AddClient from "../images/AddClient.png";
import StaffMang from "../images/StaffMang.png";
import TrainingMang from "../images/TrainingMang.png";
import StudentMang from "../images/StudentMang.png";
import AssetsMang from "../images/AssetsMang.png";
import ScheduleTraining from "../images/ScheduleTraining.png";
import Documents from "../images/Documents.png";
import BookingReq from "../images/BookingReq.png";
import Settings from "../images/Settings.png";
import Logout from "../images/Logout.png";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import TMSLogo from "../images/tmsLogo.png";

import AuthService from "../services/auth.service";

const Sidebar = ({ sidebar, logout }) => {
  const [roll, setroll] = useState("");

  const [menu, setMenu] = useState(false);

  const location = useLocation();

  const toggleMenu = (e) => {
    e.preventDefault();
    setMenu(!menu);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    console.log(user.user_id);

    setroll(user.user_id);
  }, []);

  return (
    <>
      <div className={sidebar ? "sidebar active" : "sidebar"}>
        {roll === "1" ? (
          <>
            <ul>
              <li>
                <span className="icon logo">
                  {/* <img
                      src={TMSLogo}
                      // style={{ width: "100px", margin: "0 auto" }}
                      alt="Dashboard"
                      title="Dashboard"
                    /> */}
                </span>
              </li>
              <li
                className={location.pathname === "/SADashboard" ? "active" : ""}
              >
                <Link to="/SADashboard">
                  <span className="icon">
                    <img
                      src={Home}
                      style={{ width: "24px", height: "24px" }}
                      alt="Dashboard"
                      title="Dashboard"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Dashboard</Tooltip>}
                  >
                    <span className="title">Dashboard</span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/ManageClients"
                    ? "active"
                    : location.pathname === "/AddClient"
                    ? "active"
                    : location.pathname.includes("/EditClient")
                    ? "active"
                    : ""
                }
              >
                <Link to="/ManageClients">
                  <span className="icon">
                    <img
                      src={AddClient}
                      style={{ width: "21px", height: "24px" }}
                      alt="AddClient"
                      title="AddClient"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">Client Management</Tooltip>
                    }
                  >
                    <span className="title">Client Mang.</span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/ManageStaffs"
                    ? "active"
                    : location.pathname === "/AddStaff"
                    ? "active"
                    : location.pathname.includes("/EditStaff")
                    ? "active"
                    : location.pathname === "/ManageRoles"
                    ? "active"
                    : location.pathname === "/AddRole"
                    ? "active"
                    : location.pathname.includes("/EditRole")
                    ? "active"
                    : ""
                }
              >
                <Link to="/ManageStaffs">
                  <span className="icon">
                    <img
                      src={StaffMang}
                      style={{ width: "22px", height: "21px" }}
                      alt="StaffMang"
                      title="StaffMang"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">Staff Management</Tooltip>
                    }
                  >
                    <span className="title">Staff Mang.</span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/BookingReq"
                    ? "active"
                    : location.pathname.includes("/ViewScheduledTrainings")
                    ? "active"
                    : ""
                }
              >
                <Link to="/BookingReq">
                  <span className="icon">
                    <img
                      src={BookingReq}
                      style={{ width: "24px", height: "21px" }}
                      alt="BookingReq"
                      title="BookingReq"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">Booking Requests</Tooltip>
                    }
                  >
                    <span className="title">Booking Req.</span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/ScheduledTrainings" ? "active" : ""
                }
              >
                <Link to="/ScheduledTrainings">
                  <span className="icon">
                    <img
                      src={ScheduleTraining}
                      style={{ width: "24px", height: "21px" }}
                      alt="BookingReq"
                      title="BookingReq"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">Scheduled Trainings</Tooltip>
                    }
                  >
                    <span className="title">Scheduled Tra.</span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li className={location.pathname === "/Settings" ? "active" : ""}>
                <Link to="/Settings">
                  <span className="icon">
                    <img
                      src={Settings}
                      style={{ width: "21px", height: "21px" }}
                      alt="Settings"
                      title="Settings"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Settings</Tooltip>}
                  >
                    <span className="title">Settings</span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li>
                <a href="/#" onClick={logout}>
                  <span className="icon">
                    <img
                      src={Logout}
                      style={{ width: "20px", height: "21px" }}
                      alt="Logout"
                      title="Logout"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Logout</Tooltip>}
                  >
                    <span className="title">Logout</span>
                  </OverlayTrigger>
                </a>
              </li>
            </ul>
          </>
        ) : roll === "3" ? (
          <>
            <ul>
              <li>
                <span className="icon logo">
                  {/* <img
                    src={TMSLogo}
                    // style={{ width: "125px", margin: "0 auto", display: "block" }}
                    alt="Dashboard"
                    title="Dashboard"
                  /> */}
                </span>
              </li>
              <li
                className={location.pathname === "/ADashboard" ? "active" : ""}
              >
                <Link to="/ADashboard">
                  <span className="icon">
                    <img
                      src={Home}
                      style={{ width: "28px", height: "24px" }}
                      alt="Dashboard"
                      title="Dashboard"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Dashboard</Tooltip>}
                  >
                    <span className="title">Dashboard</span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/ManageStaffs"
                    ? "active"
                    : location.pathname === "/AddStaff"
                    ? "active"
                    : location.pathname.includes("/EditStaff")
                    ? "active"
                    : ""
                }
              >
                <Link to="/ManageStaffs">
                  <span className="icon">
                    <img
                      src={StaffMang}
                      style={{ width: "22px", height: "21px" }}
                      alt="StaffMang"
                      title="StaffMang"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">Staff Management</Tooltip>
                    }
                  >
                    <span className="title">Staff Mang.</span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/ManageAssets"
                    ? "active"
                    : location.pathname === "/AddAsset"
                    ? "active"
                    : location.pathname.includes("/EditAsset")
                    ? "active"
                    : location.pathname === "/ManageAssetsType"
                    ? "active"
                    : location.pathname === "/AddAssetType"
                    ? "active"
                    : location.pathname.includes("/EditAssetType")
                    ? "active"
                    : ""
                }
              >
                <Link to="/ManageAssets">
                  <span className="icon">
                    <img
                      src={AssetsMang}
                      style={{ width: "22px", height: "21px" }}
                      alt="StaffMang"
                      title="StaffMang"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">Assets Management</Tooltip>
                    }
                  >
                    <span className="title">Assets Mang.</span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/ManageCourses"
                    ? "active"
                    : location.pathname === "/TrainingTypes"
                    ? "active"
                    : location.pathname === "/AddCourse"
                    ? "active"
                    : location.pathname === "/AddTrainingType"
                    ? "active"
                    : location.pathname.includes("/EditCourse")
                    ? "active"
                    : location.pathname.includes("/EditTrainingType")
                    ? "active"
                    : ""
                }
              >
                <Link href="/#" role="button" onClick={toggleMenu}>
                  <span className="icon">
                    <img
                      src={TrainingMang}
                      style={{ width: "22px", height: "21px" }}
                      alt="TrainingTypes"
                      title="TrainingTypes"
                    />
                  </span>
                  <span className="title">
                    Training Mang.&nbsp;&nbsp;
                    <i className="fas fa-caret-down"></i>
                  </span>
                </Link>
                <div
                  className={menu ? "dropdown active" : "dropdown"}
                  // className="dropdown active"
                  id="planning_dropdown"
                >
                  <ul style={{ paddingLeft: "35px" }}>
                    <li>
                      <Link to="/ManageCourses">
                        <span className="title">
                          <i
                            className="fas fa-angle-double-right"
                            style={{ paddingRight: "5px" }}
                          ></i>{" "}
                          Courses
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/TrainingTypes">
                        <span className="title">
                          <i
                            className="fas fa-angle-double-right"
                            style={{ paddingRight: "5px" }}
                          ></i>{" "}
                          Training Type
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className={
                  location.pathname === "/ManageStudents"
                    ? "active"
                    : location.pathname === "/AddStudent"
                    ? "active"
                    : location.pathname.includes("/EditStudent")
                    ? "active"
                    : ""
                }
              >
                <Link to="/ManageStudents">
                  <span className="icon">
                    <img
                      src={StudentMang}
                      style={{ width: "22px", height: "21px" }}
                      alt="StaffMang"
                      title="StaffMang"
                    />
                  </span>
                  <span className="title">Student Mang.</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/BookingReq"
                    ? "active"
                    : location.pathname.includes("/ScheduleBooking")
                    ? "active"
                    : location.pathname.includes("/ViewScheduledTrainings")
                    ? "active"
                    : ""
                }
              >
                <Link to="/BookingReq">
                  <span className="icon">
                    <img
                      src={BookingReq}
                      style={{ width: "24px", height: "21px" }}
                      alt="BookingReq"
                      title="BookingReq"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">Booking Requests</Tooltip>
                    }
                  >
                    <span className="title">Booking Req.</span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/ScheduledTrainings" ? "active" : ""
                }
              >
                <Link to="/ScheduledTrainings">
                  <span className="icon">
                    <img
                      src={ScheduleTraining}
                      style={{ width: "24px", height: "21px" }}
                      alt="BookingReq"
                      title="BookingReq"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">Scheduled Trainings</Tooltip>
                    }
                  >
                    <span className="title">Scheduled Tra.</span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li className={location.pathname === "/Settings" ? "active" : ""}>
                <Link to="/Settings">
                  <span className="icon">
                    <img
                      src={Settings}
                      style={{ width: "21px", height: "21px" }}
                      alt="Settings"
                      title="Settings"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Settings</Tooltip>}
                  >
                    <span className="title">Settings</span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li>
                <a href="/#" onClick={logout}>
                  <span className="icon">
                    <img
                      src={Logout}
                      style={{ width: "20px", height: "21px" }}
                      alt="Logout"
                      title="Logout"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip">Logout</Tooltip>}
                  >
                    <span className="title">Logout</span>
                  </OverlayTrigger>
                </a>
              </li>
            </ul>
          </>
        ) : roll === "2" ? (
          <>
            <ul>
              <li>
                <span className="icon logo">
                  {/* <img
                    src={TMSLogo}
                    // style={{ width: "100px" }}
                    alt="Dashboard"
                    title="Dashboard"
                  /> */}
                </span>
              </li>
              <li
                className={location.pathname === "/CADashboard" ? "active" : ""}
              >
                <Link to="/CADashboard">
                  <span className="icon">
                    <img
                      src={Home}
                      style={{ width: "28px", height: "24px" }}
                      alt="Dashboard"
                      title="Dashboard"
                    />
                  </span>
                  <span className="title">Dashboard</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/ManageStudents"
                    ? "active"
                    : location.pathname === "/AddStudent"
                    ? "active"
                    : location.pathname.includes("/EditStudent")
                    ? "active"
                    : ""
                }
              >
                <Link to="/ManageStudents">
                  <span className="icon">
                    <img
                      src={StudentMang}
                      style={{ width: "21px", height: "24px" }}
                      alt="ManageStudents"
                      title="ManageStudents"
                    />
                  </span>
                  <span className="title">Student Mang.</span>
                </Link>
              </li>
              <li>
                <Link to="/BookingReq">
                  <span className="icon">
                    <img
                      src={BookingReq}
                      style={{ width: "24px", height: "21px" }}
                      alt="BookingReq"
                      title="BookingReq"
                    />
                  </span>
                  <span className="title">Booking Req.</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/ScheduledTrainings" ? "active" : ""
                }
              >
                <Link to="/ScheduledTrainings">
                  <span className="icon">
                    <img
                      src={ScheduleTraining}
                      style={{ width: "24px", height: "21px" }}
                      alt="BookingReq"
                      title="BookingReq"
                    />
                  </span>
                  <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">Scheduled Trainings</Tooltip>
                    }
                  >
                    <span className="title">Scheduled Tra.</span>
                  </OverlayTrigger>
                </Link>
              </li>
              <li>
                <Link to="/Settings">
                  <span className="icon">
                    <img
                      src={Settings}
                      style={{ width: "21px", height: "21px" }}
                      alt="Settings"
                      title="Settings"
                    />
                  </span>
                  <span className="title">Settings</span>
                </Link>
              </li>
              <li>
                <a href="/#" onClick={logout}>
                  <span className="icon">
                    <img
                      src={Logout}
                      style={{ width: "20px", height: "21px" }}
                      alt="Logout"
                      title="Logout"
                    />
                  </span>
                  <span className="title">Logout</span>
                </a>
              </li>
            </ul>
          </>
        ) : roll === "4" ? (
          <>
            <ul>
            {/* instructure sidebar */}
              <li>
                <span className="icon logo">
                  {/* <img
                    src={TMSLogo}
                    // style={{ width: "100px" }}
                    alt="Dashboard"
                    title="Dashboard"
                  /> */}
                </span>
              </li>
              <li
                className={location.pathname === "/IDashboard" ? "active" : ""}
              >
                <Link to="/IDashboard">
                  <span className="icon">
                    <img
                      src={Home}
                      style={{ width: "28px", height: "24px" }}
                      alt="Dashboard"
                      title="Dashboard"
                    />
                  </span>
                  <span className="title">Dashboard</span>
                </Link>
              </li>
              <li
                className={
                  location.pathname === "/ManageCourses"
                    ? "active"
                    : location.pathname === "/TrainingTypes"
                    ? "active"
                    : location.pathname === "/AddCourse"
                    ? "active"
                    : location.pathname === "/AddTrainingType"
                    ? "active"
                    : location.pathname.includes("/EditCourse")
                    ? "active"
                    : location.pathname.includes("/EditTrainingType")
                    ? "active"
                    : ""
                }
              >
                <Link href="/#" role="button" onClick={toggleMenu}>
                  <span className="icon">
                    <img
                      src={TrainingMang}
                      style={{ width: "22px", height: "21px" }}
                      alt="TrainingTypes"
                      title="TrainingTypes"
                    />
                  </span>
                  <span className="title">
                    Training Mang.&nbsp;&nbsp;
                    <i className="fas fa-caret-down"></i>
                  </span>
                </Link>
                <div
                  className={menu ? "dropdown active" : "dropdown"}
                  // className="dropdown active"
                  id="planning_dropdown"
                >
                  <ul style={{ paddingLeft: "35px" }}>
                    <li>
                      <Link to="/ManageCourses">
                        <span className="title">
                          {/* <i
                            className="fas fa-angle-double-right"
                            style={{ paddingRight: "5px" }}
                          ></i>{" "} */}
                          Today's Training
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/TrainingTypes">
                        <span className="title">
                          {/* <i
                            className="fas fa-angle-double-right"
                            style={{ paddingRight: "5px" }}
                          ></i>{" "} */}
                          Upcoming Training
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/TrainingTypes">
                        <span className="title">
                          {/* <i
                            className="fas fa-angle-double-right"
                            style={{ paddingRight: "5px" }}
                          ></i>{" "} */}
                          Completed Training
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/TrainingTypes">
                        <span className="title">
                          {/* <i
                            className="fas fa-angle-double-right"
                            style={{ paddingRight: "5px" }}
                          ></i>{" "} */}
                          Cancelled Training
                        </span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li
                className={
                  location.pathname === "/ManageStudents"
                    ? "active"
                    : location.pathname === "/AddStudent"
                    ? "active"
                    : location.pathname.includes("/EditStudent")
                    ? "active"
                    : ""
                }
              >
                <Link to="/ManageStudents">
                  <span className="icon">
                    <img
                      src={Documents}
                      style={{ width: "21px", height: "24px" }}
                      alt="ManageStudents"
                      title="ManageStudents"
                    />
                  </span>
                  <span className="title">Documents</span>
                </Link>
              </li>
              <li>
                <Link to="/Settings">
                  <span className="icon">
                    <img
                      src={Settings}
                      style={{ width: "21px", height: "21px" }}
                      alt="Settings"
                      title="Settings"
                    />
                  </span>
                  <span className="title">Settings</span>
                </Link>
              </li>
              <li>
                <a href="/#" onClick={logout}>
                  <span className="icon">
                    <img
                      src={Logout}
                      style={{ width: "20px", height: "21px" }}
                      alt="Logout"
                      title="Logout"
                    />
                  </span>
                  <span className="title">Logout</span>
                </a>
              </li>
            </ul>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Sidebar;
