import React, { useEffect, useState } from "react";
import { Container, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import EditIcon from "../../../images/EditIcon.png";
import DeleteIcon from "../../../images/DeleteIcon.png";
import SearchIcon from "../../../images/SearchIcon.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import { ThreeDots } from "react-loader-spinner";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import "../../../custom-css/datatable.css";

import { reactLocalStorage } from "reactjs-localstorage";

import ClientService from "../../../services/client.service";

import "./clients.css";

const ManageClients = () => {
  const [clients, setClients] = useState([]);

  const [remove, setRemove] = useState(false);

  const [did, setDid] = useState("");

  const [message, setMessage] = useState("");

  const [checkedState, setCheckedState] = useState([]);

  const [loadTable, setLoadtable] = useState(false);

  const [status, setStatus] = useState("");

  const [loading, setLoading] = useState(true);

  const handleRemoveShow = (e) => {
    setRemove(true);
    setDid(e.target.getAttribute("data-bs-target"));
  };

  const handleRemoveClose = () => {
    setRemove(false);
  };

  useEffect(() => {
    ClientService.getAll()
      .then((response) => {
        setLoading(false);
        setClients(response.data);
        const client = response.data;
        setCheckedState(
          Array.from(
            { length: client.length },
            (_, index) => client[index].status === "1"
          )
        );
      })
      .catch((e) => {
        console.log(e);
      });

    if (!$.fn.DataTable.isDataTable("#table")) {
      function filterGlobal() {
        $("#table")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#table")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            processing: true,
            select: true,
            bDestroy: true,

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });

          setLoadtable(true);
        }, 1000);
      });
    }
  }, []);

  const changeStatus = (position, id) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    console.log(position);
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            ClientService.chnageSts(id)
              .then((response) => {
                console.log(response.data);
                setCheckedState(updatedCheckedState);

                window.location.reload();

                // setClients(clients.filter((client) => client.id !== id));

                console.log(clients[position]);

                if (clients[position].status === "1") {
                  // console.log("Inactive");
                  setStatus("Inactive");
                } else if (clients[position].status === "0") {
                  // console.log("Active");
                  setStatus("Active");
                }

                // setMessage("Client Status updated successfully");
              })
              .catch((e) => {
                console.log(e);
              });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const deleteClient = () => {
    ClientService.remove(did)
      .then((response) => {
        setMessage(response.data.message);
        setRemove(false);
        setClients(clients.filter((client) => client.id !== did));
        // console.log(response.data.message);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <Container fluid>
        {loading ? (
          <div className="loader-container">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <>
            <Row>
              <div className="clientsInfo">
                <div className="message">{message}</div>
                <div
                  className={
                    loadTable ? "table-actions active" : "table-actions"
                  }
                >
                  <label htmlFor="">
                    <input
                      type="text"
                      className="global_filter"
                      id="global_filter"
                      placeholder="Search"
                    />
                    <img src={SearchIcon} alt="" />
                  </label>
                  <Button className="custom-btn">
                    <Link to={"/AddClient"}>Add New Client</Link>
                  </Button>
                </div>
                <div className="clientsInfoTable">
                  <div className="table-responsive">
                    <table id="table" className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Sr. No.</th>
                          <th>Name(Org.)</th>
                          <th>Country</th>
                          <th>Address</th>
                          <th>Manager</th>
                          <th>Email</th>
                          <th>Mobile No.</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(clients)
                          ? clients.map((client, index) => {
                              return (
                                <tr key={client.id}>
                                  <td>{index + 1}</td>
                                  <td>{client.name}</td>
                                  <td>{client.country}</td>
                                  <td>{client.address}</td>
                                  <td>{client.person}</td>
                                  <td>{client.email}</td>
                                  <td>{client.mobile}</td>
                                  <td>
                                    <label
                                      className={
                                        client.status === "0"
                                          ? "switch inactive"
                                          : "switch active"
                                      }
                                      htmlFor={`clients-${index}`}
                                    >
                                      <input
                                        type="checkbox"
                                        id={`clients-${index}`}
                                        name={client.name}
                                        value={client.name}
                                        checked={checkedState[index]}
                                        onClick={() =>
                                          changeStatus(index, client.id)
                                        }
                                      />
                                      <span className="slider round"></span>
                                      <span
                                        className={
                                          client.status === "0"
                                            ? "slider-text inactive"
                                            : "slider-text"
                                        }
                                      >
                                        {client.status === "0"
                                          ? "Inactive"
                                          : "Active"}
                                      </span>
                                    </label>
                                  </td>
                                  <td>
                                    <div className="flex">
                                      <Link to={"/EditClient/" + client.id}>
                                        <img
                                          src={EditIcon}
                                          alt="Edit Route"
                                          title="Edit Route"
                                        />
                                      </Link>
                                      <img
                                        onClick={handleRemoveShow}
                                        id="deleteClient"
                                        data-bs-target={client.id}
                                        src={DeleteIcon}
                                        alt="Delete Client"
                                        title="Delete Client"
                                      />
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          : null}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Row>
          </>
        )}
      </Container>
      <Modal show={remove} onHide={handleRemoveClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Client</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this client.</p>
          <div className="flex">
            <Button onClick={deleteClient}>Yes</Button>
            <Button variant="danger" onClick={handleRemoveClose}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ManageClients;
