import React from "react";

import { LineChart, Line, ResponsiveContainer } from "recharts";

import GreenUp from "../../../../images/green-up.png";
import RedDown from "../../../../images/red-down.png";

const BookingReqData = () => {
  const courseOne = [
    {
      name: "Page A",
      uv: 400,
    },
    {
      name: "Page B",
      uv: 450,
    },
    {
      name: "Page C",
      uv: 435,
    },
    {
      name: "Page D",
      uv: 475,
    },
  ];

  const courseTwo = [
    {
      name: "Page A",
      uv: 475,
    },
    {
      name: "Page B",
      uv: 400,
    },
    {
      name: "Page C",
      uv: 450,
    },
    {
      name: "Page D",
      uv: 475,
    },
  ];

  const courseThree = [
    {
      name: "Page A",
      uv: 400,
    },
    {
      name: "Page B",
      uv: 450,
    },
    {
      name: "Page C",
      uv: 435,
    },
    {
      name: "Page D",
      uv: 475,
    },
  ];

  const courseFour = [
    {
      name: "Page A",
      uv: 400,
    },
    {
      name: "Page B",
      uv: 450,
    },
    {
      name: "Page C",
      uv: 435,
    },
    {
      name: "Page D",
      uv: 475,
    },
  ];

  const courseFive = [
    {
      name: "Page A",
      uv: 400,
    },
    {
      name: "Page B",
      uv: 450,
    },
    {
      name: "Page C",
      uv: 435,
    },
    {
      name: "Page D",
      uv: 475,
    },
  ];
  return (
    <>
      <div className="header-flex">
        <div className="heading">
          <h2>Booking Request</h2>
        </div>
        <div className="selectDate">
          <input
            type="date"
            placeholder="Select Date"
            name="todaysScheduledTraining"
            id="todaysScheduledTraining"
            title="From"
          />
          <input
            type="date"
            placeholder="Select Date"
            name="todaysScheduledTraining"
            id="todaysScheduledTraining"
            title="To"
          />
        </div>
      </div>
      <div className="dataContent">
        <div className="course-grid">
          <div className="course">
            <div className="courseName">
              <span>Course 1</span>
            </div>
            <div className="courseCount">
              <span>475</span>
              <span>
                <img src={GreenUp} alt="" />
              </span>
            </div>
            <ResponsiveContainer width="100%" height={75}>
              <LineChart
                data={courseOne}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <Tooltip /> */}
                <Line type="monotone" dataKey="uv" stroke="#00E38C" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="course">
            <div className="courseName">
              <span>Course 2</span>
            </div>
            <div className="courseCount">
              <span>431</span>
              <span>
                <img src={GreenUp} alt="" />
              </span>
            </div>
            <ResponsiveContainer width="100%" height={75}>
              <LineChart
                data={courseTwo}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <Tooltip /> */}
                <Line type="monotone" dataKey="uv" stroke="#00E38C" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="course">
            <div className="courseName">
              <span>Course 3</span>
            </div>
            <div className="courseCount">
              <span>174</span>
              <span>
                <img src={RedDown} alt="" />
              </span>
            </div>
            <ResponsiveContainer width="100%" height={75}>
              <LineChart
                data={courseThree}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <Tooltip /> */}
                <Line type="monotone" dataKey="uv" stroke="#FF4B75" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="course">
            <div className="courseName">
              <span>Course 4</span>
            </div>
            <div className="courseCount">
              <span>475</span>
              <span>
                <img src={GreenUp} alt="" />
              </span>
            </div>
            <ResponsiveContainer width="100%" height={75}>
              <LineChart
                data={courseFour}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <Tooltip /> */}
                <Line type="monotone" dataKey="uv" stroke="#00E38C" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="course">
            <div className="courseName">
              <span>Course 5</span>
            </div>
            <div className="courseCount">
              <span>125</span>
              <span>
                <img src={RedDown} alt="" />
              </span>
            </div>
            <ResponsiveContainer width="100%" height={75}>
              <LineChart
                data={courseFive}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <Tooltip /> */}
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="uv" stroke="#FF4B75" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="course">
            <div className="courseName">
              <span>Course 6</span>
            </div>
            <div className="courseCount">
              <span>475</span>
              <span>
                <img src={GreenUp} alt="" />
              </span>
            </div>
            <ResponsiveContainer width="100%" height={75}>
              <LineChart
                data={courseOne}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <Tooltip /> */}
                <Line type="monotone" dataKey="uv" stroke="#00E38C" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="course">
            <div className="courseName">
              <span>Course 7</span>
            </div>
            <div className="courseCount">
              <span>431</span>
              <span>
                <img src={GreenUp} alt="" />
              </span>
            </div>
            <ResponsiveContainer width="100%" height={75}>
              <LineChart
                data={courseTwo}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <Tooltip /> */}
                <Line type="monotone" dataKey="uv" stroke="#00E38C" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="course">
            <div className="courseName">
              <span>Course 8</span>
            </div>
            <div className="courseCount">
              <span>174</span>
              <span>
                <img src={RedDown} alt="" />
              </span>
            </div>
            <ResponsiveContainer width="100%" height={75}>
              <LineChart
                data={courseThree}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <Tooltip /> */}
                <Line type="monotone" dataKey="uv" stroke="#FF4B75" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="course">
            <div className="courseName">
              <span>Course 9</span>
            </div>
            <div className="courseCount">
              <span>475</span>
              <span>
                <img src={GreenUp} alt="" />
              </span>
            </div>
            <ResponsiveContainer width="100%" height={75}>
              <LineChart
                data={courseFour}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <Tooltip /> */}
                <Line type="monotone" dataKey="uv" stroke="#00E38C" />
              </LineChart>
            </ResponsiveContainer>
          </div>

          <div className="course">
            <div className="courseName">
              <span>Course 10</span>
            </div>
            <div className="courseCount">
              <span>125</span>
              <span>
                <img src={RedDown} alt="" />
              </span>
            </div>
            <ResponsiveContainer width="100%" height={75}>
              <LineChart
                data={courseFive}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                {/* <Tooltip /> */}
                <Line
                  type="monotone"
                  dataKey="pv"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
                <Line type="monotone" dataKey="uv" stroke="#FF4B75" />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingReqData;
