import React, { useState, useEffect } from "react";
import { Container, Row, Form, Col, Button, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

import "./assets.css";

import AssetTypeService from "../../../services/assettype.service";

const EditAsset = () => {
  const { id } = useParams();

  const [assetType, setAssetType] = useState({
    id: id,
    name: "",
    note: ""
  });

  const getAsset = (id) => {
    AssetTypeService.getAssetTypeBy(id)
      .then((response) => {
        setAssetType(response.data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (id) {
      getAsset(id);
    }
  }, []);

  const [submitted, setSubmitted] = useState(false);

  const [validated, setValidated] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAssetType({ ...assetType, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    event.preventDefault();

    fetch("https://tms.aeonsoftware.net/cit//training/assetstype/index_put", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "pass123",
      },
      body: JSON.stringify({
        id: assetType.id,
        name: assetType.name,
        note: assetType.note
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSubmitted(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <Container>
        <Row>
          <div className="staffsInfo">
            <div className="staffDetailsBox assetTypeDetailsBox">
              <div className="head">
                <h3>Edit Asset Type Details</h3>
              </div>
              {submitted ? (
                <Row>
                  <div className="sucmessage">Asset Type Updated Successfully!</div>
                </Row>
              ) : (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="name"
                          value={assetType.name}
                          onChange={handleInputChange}
                          required
                          title="Name"
                        />
                        <Form.Label>Name</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="note"
                          value={assetType.note}
                          onChange={handleInputChange}
                          required
                          title="Note"
                        />
                        <Form.Label>Note</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Button className="nextButton" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EditAsset;
