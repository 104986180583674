import http from "../http-common";

const login = (username, password) => {
  return http.post("/Api/Apidata/userlog", {
    username,
    password,
  })
    .then((response) => {
      if (response.data.username) {
        localStorage.setItem("username", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem("username");
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("username"));
};

const AuthService = {
  login,
  logout,
  getCurrentUser
};

export default AuthService;