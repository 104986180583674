import http from "../http-common";

const getAll = () => {
  return http.get("/Role/Roledata/roleinfo/roles");
};

const getRoleBy = (id) => {
  return http.get(`/Role/Roledata/roleinfo/roles?id=${id}`);
};

const create = (data) => {
    return http.post("/Role/Roledata/iscroll", data)
}

const remove = (id) => {
  return http.delete(`/Role/Roledata/delrol?id=${id}`);
};


const RoleService = {
  getAll,
  getRoleBy,
  create,
  remove
};

export default RoleService;