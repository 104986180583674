import React, { useState, useEffect } from "react";
import { Container, Row, Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Scheduled from "../../../images/Scheduled.png";
import Approved from "../../../images/Approved.png";
import dateBlack from "../../../images/dateBlack.png";
import Pending from "../../../images/Pending.png";
import SearchIcon from "../../../images/SearchIcon.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import BookingIcon from "../../../images/BookingIcon.png";

import SchedulingIcon from "../../../images/schedulingIcon.png";

import { ThreeDots } from "react-loader-spinner";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import "../../../custom-css/datatable.css";

import "./bookings.css";

import AuthService from "../../../services/auth.service";

import BookingService from "../../../services/booking.service";

import ClientService from "../../../services/client.service";

import CourseService from "../../../services/course.service";

import TrainingTypeService from "../../../services/trainingtype.service";

const BookingReq = () => {
  const [roll, setroll] = useState("");

  const [bookings, setBookings] = useState([]);

  const [clabookings, setClaBookings] = useState([]);

  const [clients, setClients] = useState([]);

  const [courses, setCourses] = useState([]);

  const [trainingTypes, setTrainingTypes] = useState([]);

  const [loading, setLoading] = useState(true);

  const [bookingSts, setBookingSts] = useState("all");

  const [cancel, setCancel] = useState(false);

  const [bookingId, setBookingsId] = useState("");

  const [userID, setUserID] = useState("");

  const [loadTable, setLoadtable] = useState(false);

  const handleCancelOpen = (e) => {
    setCancel(true);
    setBookingsId(e.target.getAttribute("data-bs-target"));
  };

  const handleCancelClose = () => {
    setCancel(false);
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    console.log(user);

    if (user) {
      setroll(user.user_id);
      setUserID(user.id);
    }

    BookingService.getAll()
      .then((response) => {
        setLoading(false);
        setBookings(response.data);
      })
      .catch((e) => {
        console.log(e);
        setLoading(true);
      });

    BookingService.getBookingByCid(user.user_id)
      .then((response) => {
        setLoading(false);
        setClaBookings(response.data);
      })
      .catch((e) => {
        console.log(e);
        setLoading(true);
      });

    ClientService.getAll()
      .then((response) => {
        setClients(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    CourseService.getAll()
      .then((response) => {
        setCourses(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    TrainingTypeService.getAll()
      .then((response) => {
        setTrainingTypes(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    if (!$.fn.DataTable.isDataTable("#table")) {
      function filterGlobal() {
        $("#table")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#table")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            processing: true,
            select: true,
            bDestroy: true,
            // dom: "Bfrtip",

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
          setLoadtable(true);
        }, 1000);
      });
    }
  }, []);

  function getClientName(clientId, clients) {
    const client = clients.find((r) => r.id === clientId);
    return client ? client.name : "";
  }

  function getCourseName(courseId, courses) {
    const course = courses.find((r) => r.id === courseId);
    return course ? course.name : "";
  }

  function getTrainingTypes(trainingId, trainingTypes) {
    const trainingType = trainingTypes.find((r) => r.id === trainingId);
    return trainingType ? trainingType.training_type : "";
  }

  const handleBookingSts = (e) => {
    console.log(e.target.value);
    setBookingSts(e.target.value);
  };

  const cancelBooking = () => {};

  const showClientWise = (e) => {
    console.log(e.target.value);

    if (e.target.value === "0") {
      BookingService.getAll()
        .then((response) => {
          setLoading(false);
          console.log(response.data);
          setBookings(response.data);
        })
        .catch((e) => {
          console.log(e);
          setLoading(true);
        });
    } else {
      BookingService.getBookingByCid(e.target.value)
        .then((response) => {
          setLoading(false);
          console.log(response.data);
          setBookings(response.data);
        })
        .catch((e) => {
          console.log(e);
          setLoading(true);
        });
    }
  };

  return (
    <>
      <Container fluid>
        {loading ? (
          <div className="loader-container">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <Row>
            <div className="proressBar">
              <div className="step active">
                <div className="circle">
                  <img
                    src={BookingIcon}
                    alt=""
                    style={{ width: "20px", height: "17px" }}
                  />
                </div>
                <div className="text">Booking Request</div>
              </div>
              <div className="line">
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
              </div>
              <div className="plane">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 36 36"
                >
                  <g id="PlainIcon" transform="translate(0.001 0.002)">
                    <g
                      id="Layer_1"
                      data-name="Layer 1"
                      transform="translate(-0.001 -0.002)"
                    >
                      <path
                        id="Path_11626"
                        data-name="Path 11626"
                        d="M2.282,16.817c-.065-.147-.129-.292-.194-.438L.05,11.854c-.126-.28-.012-.457.3-.457s.6,0,.9,0a.434.434,0,0,1,.323.127Q3.581,13.394,5.6,15.26c.109.1.225.2.324.3a.354.354,0,0,0,.308.11q3.2-.131,6.395-.257l4.123-.166c.038,0,.076-.005.137-.009-.017-.07-.029-.132-.046-.192L12.853.537C12.836.473,12.817.409,12.8.345A.261.261,0,0,1,13.083,0c.555,0,1.112,0,1.666,0,.634,0,.5-.072.835.441Q20.3,7.608,25.016,14.777a.266.266,0,0,0,.267.137c1.641-.04,3.283-.043,4.921.06a11.308,11.308,0,0,1,3.531.7,4.818,4.818,0,0,1,1.644,1.007,1.708,1.708,0,0,1,0,2.628,4.83,4.83,0,0,1-1.67,1.019,10.557,10.557,0,0,1-3,.652,53.89,53.89,0,0,1-5.485.1.2.2,0,0,0-.194.109q-3.131,4.762-6.265,9.522Q17.1,33.258,15.426,35.8a.387.387,0,0,1-.368.195c-.637-.007-1.275,0-1.912,0-.31,0-.406-.124-.323-.421l4.023-14.649c.014-.05.025-.1.042-.164-.14-.009-.269-.02-.4-.025l-6.106-.24c-1.3-.051-2.595-.1-3.892-.161a.433.433,0,0,0-.344.129q-2.285,2.124-4.574,4.245a.488.488,0,0,1-.363.141c-.285-.005-.57,0-.855,0-.319,0-.437-.179-.309-.463L2.3,19.394c.029-.064.058-.128.09-.2-.211-.046-.409-.081-.6-.133a2.507,2.507,0,0,1-1.129-.606.555.555,0,0,1-.008-.882,2.649,2.649,0,0,1,1.454-.7c.043-.008.087-.015.128-.025a.335.335,0,0,0,.052-.023Z"
                        transform="translate(0.001 0.002)"
                        fill="#26ac96"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <div className="line">
                <div className="dots"></div>
                <div className="dots"></div>
                <div className="dots"></div>
              </div>
              <div className="step">
                <div className="circle">
                  <img
                    src={SchedulingIcon}
                    alt=""
                    style={{ width: "20px", height: "17px" }}
                  />
                </div>
                <div className="text">Scheduled Booking</div>
              </div>
            </div>
            <div className="bookingsInfo">
              <div
                className={loadTable ? "table-actions active" : "table-actions"}
              >
                <label htmlFor="">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Search"
                  />
                  <img src={SearchIcon} alt="" />
                </label>
                <label htmlFor="">Filter</label>
                <div className="filter">
                  <select
                    name="booking"
                    id="booking"
                    onChange={handleBookingSts}
                  >
                    <option value="all">All Booking</option>
                    <option value="0">Pending</option>
                    <option value="1">Scheduled</option>
                    <option value="2">Canceled</option>
                  </select>
                  {roll === "1" || roll === "3" ? (
                    <>
                      <select
                        name="client-wise"
                        id="client-wise"
                        onChange={showClientWise}
                      >
                        <option value="0">All Clients</option>
                        {Array.isArray(clients)
                          ? clients.map((client, index) => {
                              return (
                                <option value={client.id}>{client.name}</option>
                              );
                            })
                          : null}
                      </select>
                    </>
                  ) : (
                    <></>
                  )}

                  <input
                    type="date"
                    className="date"
                    id="date-wise"
                    placeholder="Date"
                    name="date-wise"
                  />
                </div>
                {roll === "3" || roll === "2" ? (
                  <>
                    <Button className="custom-btn">
                      <Link to={"/AddBooking"}>Add Booking</Link>
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className="bookingsInfoTable">
                <div className="table-responsive">
                  <table id="table" className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Name</th>
                        <th>Course</th>
                        <th>Training Type</th>
                        <th>No of Student</th>
                        <th>Date From</th>
                        <th>Date To</th>
                        <th>Time</th>
                        <th>Status</th>
                        {roll === "3" || roll === "2" ? (
                          <>
                            <th>Action</th>
                          </>
                        ) : (
                          <></>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {roll === "1" ? (
                        <>
                          {bookingSts === "0" ? (
                            <>
                              {Array.isArray(bookings)
                                ? bookings.map((booking, index) => {
                                    return booking.status === "0" ? (
                                      <>
                                        <tr key={booking.id}>
                                          <td>{index + 1}</td>
                                          <td>
                                            {getClientName(
                                              booking.client_id,
                                              clients
                                            )}
                                          </td>
                                          <td>
                                            {getCourseName(
                                              booking.course_id,
                                              courses
                                            )}
                                          </td>
                                          <td>
                                            {getTrainingTypes(
                                              booking.training_id,
                                              trainingTypes
                                            )}
                                          </td>
                                          <td>{booking.num_std}</td>
                                          <td>{booking.date_from}</td>
                                          <td>{booking.date_to}</td>
                                          <td>
                                            {booking.time_from}-
                                            {booking.time_to}
                                          </td>
                                          <td>
                                            <div className="flex">
                                              <img
                                                src={Pending}
                                                alt="Pending"
                                                title="Pending"
                                                style={{
                                                  width: "34px",
                                                  height: "32px",
                                                }}
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    ) : (
                                      <></>
                                    );
                                  })
                                : null}
                            </>
                          ) : bookingSts === "1" ? (
                            <>
                              {Array.isArray(bookings)
                                ? bookings.map((booking, index) => {
                                    return booking.status === "1" ? (
                                      <>
                                        <tr key={booking.id}>
                                          <td>{index + 1}</td>
                                          <td>
                                            {getClientName(
                                              booking.client_id,
                                              clients
                                            )}
                                          </td>
                                          <td>
                                            {getCourseName(
                                              booking.course_id,
                                              courses
                                            )}
                                          </td>
                                          <td>
                                            {getTrainingTypes(
                                              booking.training_id,
                                              trainingTypes
                                            )}
                                          </td>
                                          <td>{booking.num_std}</td>
                                          <td>{booking.date_from}</td>
                                          <td>{booking.date_to}</td>
                                          <td>
                                            {booking.time_from}-
                                            {booking.time_to}
                                          </td>
                                          <td>
                                            <div className="flex">
                                              <img
                                                src={Scheduled}
                                                alt="Scheduled"
                                                title="Scheduled"
                                                style={{
                                                  width: "34px",
                                                  height: "32px",
                                                }}
                                              />
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    ) : (
                                      <></>
                                    );
                                  })
                                : null}
                            </>
                          ) : bookingSts === "2" ? (
                            <>
                              {Array.isArray(bookings)
                                ? bookings.map((booking, index) => {
                                    return booking.status === "2" ? (
                                      <>
                                        <tr key={booking.id}>
                                          <td>{index + 1}</td>
                                          <td>
                                            {getClientName(
                                              booking.client_id,
                                              clients
                                            )}
                                          </td>
                                          <td>
                                            {getCourseName(
                                              booking.course_id,
                                              courses
                                            )}
                                          </td>
                                          <td>
                                            {getTrainingTypes(
                                              booking.training_id,
                                              trainingTypes
                                            )}
                                          </td>
                                          <td>{booking.num_std}</td>
                                          <td>{booking.date_from}</td>
                                          <td>{booking.date_to}</td>
                                          <td>
                                            {booking.time_from}-
                                            {booking.time_to}
                                          </td>
                                          <td>
                                            <div className="flex">
                                              {/* <img
                                                src={Scheduled}
                                                alt="Scheduled"
                                                title="Scheduled"
                                                style={{
                                                  width: "34px",
                                                  height: "32px",
                                                }}
                                              /> */}
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    ) : (
                                      <></>
                                    );
                                  })
                                : null}
                            </>
                          ) : bookingSts === "all" ? (
                            <>
                            {Array.isArray(bookings)
                                ? bookings.map((booking, index) => {
                                    return (
                                      <tr key={booking.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                          {getClientName(
                                            booking.client_id,
                                            clients
                                          )}
                                        </td>
                                        <td>
                                          {getCourseName(
                                            booking.course_id,
                                            courses
                                          )}
                                        </td>
                                        <td>
                                          {getTrainingTypes(
                                            booking.training_id,
                                            trainingTypes
                                          )}
                                        </td>
                                        <td>{booking.num_std}</td>
                                        <td>{booking.date_from}</td>
                                        <td>{booking.date_to}</td>
                                        <td>
                                          {booking.time_from}-{booking.time_to}
                                        </td>
                                        <td>
                                          <div className="flex">
                                            {booking.status === "1" ? (
                                              <img
                                                src={Scheduled}
                                                alt="Scheduled"
                                                title="Scheduled"
                                                style={{
                                                  width: "34px",
                                                  height: "32px",
                                                }}
                                              />
                                            ) : (
                                              <img
                                                src={Pending}
                                                alt="Pending"
                                                title="Pending"
                                                style={{
                                                  width: "34px",
                                                  height: "32px",
                                                }}
                                              />
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })
                                : null}
                            </>
                          ) : (
                            <>
                              {Array.isArray(bookings)
                                ? bookings.map((booking, index) => {
                                    return (
                                      <tr key={booking.id}>
                                        <td>{index + 1}</td>
                                        <td>
                                          {getClientName(
                                            booking.client_id,
                                            clients
                                          )}
                                        </td>
                                        <td>
                                          {getCourseName(
                                            booking.course_id,
                                            courses
                                          )}
                                        </td>
                                        <td>
                                          {getTrainingTypes(
                                            booking.training_id,
                                            trainingTypes
                                          )}
                                        </td>
                                        <td>{booking.num_std}</td>
                                        <td>{booking.date_from}</td>
                                        <td>{booking.date_to}</td>
                                        <td>
                                          {booking.time_from}-{booking.time_to}
                                        </td>
                                        <td>
                                          <div className="flex">
                                            {booking.status === "1" ? (
                                              <img
                                                src={Scheduled}
                                                alt="Scheduled"
                                                title="Scheduled"
                                                style={{
                                                  width: "34px",
                                                  height: "32px",
                                                }}
                                              />
                                            ) : (
                                              <img
                                                src={Pending}
                                                alt="Pending"
                                                title="Pending"
                                                style={{
                                                  width: "34px",
                                                  height: "32px",
                                                }}
                                              />
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  })
                                : null}
                            </>
                          )}
                        </>
                      ) : roll === "3" ? (
                        <>
                          {bookingSts === "0" ? (
                            <>
                              
                            </>
                            ) : (
                            <>
                            {Array.isArray(bookings)
                            ? bookings.map((booking, index) => {
                                return (
                                  <tr key={booking.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {getClientName(
                                        booking.client_id,
                                        clients
                                      )}
                                    </td>
                                    <td>
                                      {getCourseName(
                                        booking.course_id,
                                        courses
                                      )}
                                    </td>
                                    <td>
                                      {getTrainingTypes(
                                        booking.training_id,
                                        trainingTypes
                                      )}
                                    </td>
                                    <td>{booking.num_std}</td>
                                    <td>{booking.date_from}</td>
                                    <td>{booking.date_to}</td>
                                    <td>
                                      {booking.time_from}-{booking.time_to}
                                    </td>
                                    <td>
                                      <div className="flex">
                                        {booking.status === "1" ? (
                                          <img
                                            src={Scheduled}
                                            alt="Scheduled"
                                            title="Scheduled"
                                            style={{
                                              width: "34px",
                                              height: "32px",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={Pending}
                                            alt="Pending"
                                            title="Pending"
                                            style={{
                                              width: "34px",
                                              height: "32px",
                                            }}
                                          />
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      {booking.status === "1" ? (
                                        <Link
                                          to={
                                            "/ViewScheduledTrainings/" +
                                            booking.id
                                          }
                                        >
                                          <Button className="custom-btn view">
                                            View
                                          </Button>
                                        </Link>
                                      ) : (
                                        <div className="flex">
                                          <Link
                                            to={
                                              "/ScheduleBooking/" + booking.id
                                            }
                                          >
                                            <Button className="custom-btn schedule">
                                              Schedule
                                            </Button>
                                          </Link>

                                          <Button
                                            className="custom-btn cancel"
                                            data-bs-target={booking.id}
                                            onClick={handleCancelOpen}
                                          >
                                            Cancel
                                          </Button>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            : null}
                            </>
                          )}
                        </>
                      ) : roll === "2" ? (
                        <>
                          {Array.isArray(clabookings)
                            ? clabookings.map((clabooking, index) => {
                                return (
                                  <tr key={clabooking.id}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {getClientName(
                                        clabooking.client_id,
                                        clients
                                      )}
                                    </td>
                                    <td>
                                      {getCourseName(
                                        clabooking.course_id,
                                        courses
                                      )}
                                    </td>
                                    <td>
                                      {getTrainingTypes(
                                        clabooking.training_id,
                                        trainingTypes
                                      )}
                                    </td>
                                    <td>{clabooking.num_std}</td>
                                    <td>{clabooking.date_from}</td>
                                    <td>{clabooking.date_to}</td>
                                    <td>
                                      {clabooking.time_from}-
                                      {clabooking.time_to}
                                    </td>
                                    <td>
                                      <div className="flex">
                                        {clabooking.status === "1" ? (
                                          <img
                                            src={Scheduled}
                                            alt="Scheduled"
                                            title="Scheduled"
                                            style={{
                                              width: "34px",
                                              height: "32px",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={Pending}
                                            alt="Pending"
                                            title="Pending"
                                            style={{
                                              width: "34px",
                                              height: "32px",
                                            }}
                                          />
                                        )}
                                      </div>
                                    </td>
                                    <td>
                                      {clabooking.status === "1" ? (
                                        <Link
                                          to={
                                            "/ViewScheduledTrainings/" +
                                            clabooking.id
                                          }
                                        >
                                          <Button className="custom-btn view">
                                            View
                                          </Button>
                                        </Link>
                                      ) : (
                                        <div className="flex">
                                          <Button
                                            className="custom-btn cancel"
                                            data-bs-target={clabooking.id}
                                            onClick={handleCancelOpen}
                                          >
                                            Cancel
                                          </Button>
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })
                            : null}
                        </>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Row>
        )}
      </Container>
      <Modal show={cancel} onHide={handleCancelClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Booking Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to cancel this booking request.</p>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control type="text" placeholder="Enter Reason" />
              {/* <Form.Label>Enter Reason</Form.Label> */}
            </Form.Group>
          </Form>
          <div className="flex">
            <Button onClick={cancelBooking}>Yes</Button>
            <Button variant="danger" onClick={handleCancelClose}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default BookingReq;
