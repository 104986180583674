import React, { useState, useEffect } from "react";
import { Container, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import EditIcon from "../../../images/EditIcon.png";
import DeleteIcon from "../../../images/DeleteIcon.png";
import SearchIcon from "../../../images/SearchIcon.png";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import "../../../custom-css/datatable.css";

import CourseService from "../../../services/course.service";

import './courses.css';

const ManageCouses = () => {

  const [courses, setCourses] = useState([]);

  const [remove, setRemove] = useState(false);

  const [message, setMessage] = useState("");

  const [did, setDid] = useState('');

  const [loadTable, setLoadTable] = useState(false);

  const handleRemoveShow = (e) => {
    setRemove(true);
    setDid(e.target.getAttribute('data-bs-target'));
  };

  const handleRemoveClose = () => {
    setRemove(false);
  };

  CourseService.getAll()
  .then((response) => {
    setCourses(response.data);
  })
  .catch((e) => {
    console.log(e);
  });

  useEffect(() => {
    if (!$.fn.DataTable.isDataTable("#table")) {
      function filterGlobal() {
        $("#table")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#table")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            processing: true,
            select: true,
            bDestroy: true,

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
          setLoadTable(true);
        }, 1000);
      });
    }
  }, []);

  const deleteRole = () => {
    CourseService.remove(did).then((response) => {
      setMessage(response.data.message);
      setRemove(false);
      console.log(response.data.message);
      setCourses(courses.filter((course) => course.id !== did));
    }).catch((e) => {
      console.log(e);
    });
  }
  
  return (
    <>
      <Container fluid>
        <Row>
          <div className="coursesInfo">
          <div className="message">
            {message}
            </div>
            <div className={loadTable ? 'table-actions active' : 'table-actions' }>
              <label htmlFor="">
                <input
                  type="text"
                  className="global_filter"
                  id="global_filter"
                  placeholder="Search"
                />
                <img src={SearchIcon} alt="" />
              </label>
              <Button className="custom-btn">
                <Link to={"/AddCourse"}>Add New Course</Link>
              </Button>
            </div>
            <div className="coursesInfoTable">
              <div className="table-responsive">
                <table id="table" className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Name</th>
                      <th>Note</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(courses)
                      ? courses.map((course, index) => {
                          return (
                            <tr key={course.id}>
                              <td>{index + 1}</td>
                              <td>{course.name}</td>
                              <td>{course.note}</td>
                              <td>
                                <div className="flex">
                                  <Link to={"/EditCourse/" + course.id}>
                                    <img
                                      src={EditIcon}
                                      alt="Edit Route"
                                      title="Edit Route"
                                    />
                                  </Link>
                                  <img
                                    onClick={handleRemoveShow}
                                    id="deleteRole"
                                    data-bs-target={course.id}
                                    src={DeleteIcon}
                                    alt="Delete Route"
                                    title="Delete Route"
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      <Modal show={remove} onHide={handleRemoveClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this course.</p>
          <div className="flex">
            <Button onClick={deleteRole}>Yes</Button>
            <Button variant="danger" onClick={handleRemoveClose}>No</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ManageCouses;
