import React, { useState, useEffect } from "react";
import { Container, Row, Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ViewIcon from "../../../images/ViewIcon.png";
import SearchIcon from "../../../images/SearchIcon.png";
import "react-confirm-alert/src/react-confirm-alert.css";

import { ThreeDots } from "react-loader-spinner";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import "../../../custom-css/datatable.css";

import "./scheduled.css";

import AuthService from "../../../services/auth.service";

import BookingService from "../../../services/booking.service";

import ClientService from "../../../services/client.service";

import CourseService from "../../../services/course.service";

import TrainingTypeService from "../../../services/trainingtype.service";

const ScheduledTrainings = () => {
  const [roll, setroll] = useState("");

  const [bookings, setBookings] = useState([]);

  const [clients, setClients] = useState([]);

  const [courses, setCourses] = useState([]);

  const [trainingTypes, setTrainingTypes] = useState([]);

  const [loading, setLoading] = useState(true);

  const [bookingSts, setBookingSts] = useState("0");

  const [cancel, setCancel] = useState(false);

  const [bookingId, setBookingsId] = useState("");

  const [loadTable, setLoadTable] = useState(false);

  const [clabookings, setClaBookings] = useState([]);

  const handleCancelOpen = (e) => {
    setCancel(true);
    setBookingsId(e.target.getAttribute("data-bs-target"));
  };

  const handleCancelClose = () => {
    setCancel(false);
  };

  useEffect(() => {
    BookingService.getAll()
      .then((response) => {
        setLoading(false);
        setBookings(response.data);
      })
      .catch((e) => {
        console.log(e);
        setLoading(true);
      });

    ClientService.getAll()
      .then((response) => {
        setClients(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    CourseService.getAll()
      .then((response) => {
        setCourses(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    TrainingTypeService.getAll()
      .then((response) => {
        setTrainingTypes(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    const user = AuthService.getCurrentUser();
    console.log(user);

    if (user) {
      setroll(user.user_id);
    }

    BookingService.getBookingByCid(user.user_id)
      .then((response) => {
        setLoading(false);
        setClaBookings(response.data);
      })
      .catch((e) => {
        console.log(e);
        setLoading(true);
      });

    if (!$.fn.DataTable.isDataTable("#table")) {
      function filterGlobal() {
        $("#table")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#table")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            processing: true,
            select: true,
            bDestroy: true,
            // dom: "Bfrtip",

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
          setLoadTable(true);
        }, 1000);
      });
    }
  }, []);

  function getClientName(clientId, clients) {
    const client = clients.find((r) => r.id === clientId);
    return client ? client.name : "";
  }

  function getCourseName(courseId, courses) {
    const course = courses.find((r) => r.id === courseId);
    return course ? course.name : "";
  }

  function getTrainingTypes(trainingId, trainingTypes) {
    const trainingType = trainingTypes.find((r) => r.id === trainingId);
    return trainingType ? trainingType.training_type : "";
  }

  const handleBookingSts = (e) => {
    console.log(e.target.value);
    setBookingSts(e.target.value);
  };

  const cancelBooking = () => {};
  return (
    <>
      <Container fluid>
        {loading ? (
          <div className="loader-container">
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#4fa94d"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        ) : (
          <Row>
            <div className="bookingsInfo">
              <div
                className={loadTable ? "table-actions active" : "table-actions"}
              >
                <label htmlFor="">
                  <input
                    type="text"
                    className="global_filter"
                    id="global_filter"
                    placeholder="Search"
                  />
                  <img src={SearchIcon} alt="" />
                </label>
                <label htmlFor="">Filter</label>
                <div className="filter">
                  <select name="client-wise" id="client-wise">
                    <option value="0">Client-wise</option>
                    {Array.isArray(clients)
                      ? clients.map((client, index) => {
                          return <option value="0">{client.name}</option>;
                        })
                      : null}
                  </select>
                  {/* <input
                    type="date"
                    className="date"
                    id="date-wise"
                    placeholder="Date"
                    name="date-wise"
                  /> */}
                </div>
              </div>
              <div className="bookingsInfoTable">
                <div className="table-responsive">
                  <table id="table" className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Sr. No.</th>
                        <th>Booking ID</th>
                        <th>Client Name</th>
                        <th>Date (From - To)</th>
                        <th>Time</th>
                        <th>No of Student</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {roll === "1" && roll === "3" ? (
                        <>
                          {Array.isArray(bookings)
                            ? bookings.map((booking, index) => {
                                return (
                                  <>
                                    {booking.status === "1" ? (
                                      <>
                                        <tr key={booking.id}>
                                          <td>{index + 1}</td>
                                          <td>{booking.booking_id}</td>
                                          <td>
                                            {getClientName(
                                              booking.client_id,
                                              clients
                                            )}
                                          </td>
                                          <td>
                                            {booking.date_from}-
                                            {booking.date_to}
                                          </td>
                                          <td>{booking.booking_id}</td>
                                          <td>{booking.num_std}</td>
                                          <td>
                                            <Link
                                              to={
                                                "/ViewScheduledTrainings/" +
                                                booking.booking_id
                                              }
                                            >
                                              <img src={ViewIcon} alt="" />
                                            </Link>
                                          </td>
                                        </tr>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })
                            : null}
                        </>
                      ) : roll === "2" ? (
                        <>
                        {Array.isArray(clabookings)
                        ? clabookings.map((clabooking, index) => {
                            return (
                              <>
                                {clabooking.status === "1" ? (
                                  <>
                                    <tr key={clabooking.id}>
                                      <td>{index + 1}</td>
                                      <td>{clabooking.booking_id}</td>
                                      <td>
                                        {getClientName(
                                          clabooking.client_id,
                                          clients
                                        )}
                                      </td>
                                      <td>
                                        {clabooking.date_from}-{clabooking.date_to}
                                      </td>
                                      <td>{clabooking.booking_id}</td>
                                      <td>{clabooking.num_std}</td>
                                      <td>
                                        <Link
                                          to={
                                            "/ViewScheduledTrainings/" +
                                            clabooking.booking_id
                                          }
                                        >
                                          <img src={ViewIcon} alt="" />
                                        </Link>
                                      </td>
                                    </tr>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            );
                          })
                        : null}
                        </>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Row>
        )}
      </Container>
      <Modal show={cancel} onHide={handleCancelClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Booking Request</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to cancel this booking request.</p>
          <Form>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control type="text" placeholder="Enter Reason" />
              {/* <Form.Label>Enter Reason</Form.Label> */}
            </Form.Group>
          </Form>
          <div className="flex">
            <Button onClick={cancelBooking}>Yes</Button>
            <Button variant="danger" onClick={handleCancelClose}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ScheduledTrainings;
