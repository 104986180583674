import React, { useState, useEffect } from "react";
import { Container, Row, Form, Col, Button, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

import "./scheduled.css";

import RoleService from "../../../services/role.service";

import StaffService from "../../../services/staff.service";

const EditSlot = () => {
  const { id } = useParams();

  const [roles, setRoles] = useState([]);

  const [slot, setSlot] = useState({
    id: id,
    slot_id: "1",
    booking_id: "00127",
    date: "2023-05-15",
    from_time: "15:00",
    to_time: "16:00",
    student: "Alexa S",
    instructor: "Teri Dactyl",
    s_instructor: "Allie Grater, Olive Yew"
  });

  console.log(slot);

  const [submitted, setSubmitted] = useState(false);

  const [validated, setValidated] = useState(false);

  const getRole = (id) => {
    // StaffService.getStaffBy(id)
    //   .then((response) => {
    //     setSlot(response.data[0]);
    //     console.log(response.data);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
  };

  useEffect(() => {
    if (id) {
      getRole(id);
    }
  }, [id]);

  RoleService.getAll()
    .then((response) => {
      setRoles(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSlot({ ...slot, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    event.preventDefault();

    fetch("https://tms.aeonsoftware.net/cit//staff/staffdata/upstf", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "pass123",
      },
      body: JSON.stringify({
        id: slot.id,
        slot_id: slot.slot_id,
        booking_id: "",
        date: "",
        from_time: "",
        to_time: "",
        student: "",
        instructor: "",
        s_instructor: ""
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSubmitted(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <Container>
        <Row>
          <div className="staffsInfo">
            <div className="staffDetailsBox">
              <div className="head">
                <h3>Edit Staff Details</h3>
              </div>
              {submitted ? (
                <Row>
                  <div className="sucmessage">Slot Updated Successfully!</div>
                </Row>
              ) : (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="id"
                          value={slot.slot_id}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Slot</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3 joining_date"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="last_name"
                          value={slot.booking_id}
                          onChange={handleInputChange}
                          disabled
                        />
                        <Form.Label>Booking Id</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3 joining_date"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="date"
                          name="email"
                          value={slot.date}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Date</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="time"
                          name="mobile"
                          value={slot.from_time}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>From Time</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="time"
                          name="nationality"
                          value={slot.to_time}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>To Time</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                      <Form.Select
                          aria-label="Default select example"
                          name="role"
                          value={slot.student}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="0">Select Student</option>
                          {Array.isArray(roles)
                            ? roles.map((role) => {
                                return (
                                  <option key={role.id} value={role.name}>
                                    {role.name}
                                  </option>
                                );
                              })
                            : null}
                        </Form.Select>
                        <Form.Label>Student</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          name="role"
                          value={slot.instructor}
                          onChange={handleInputChange}
                          required
                        >
                          <option>Select Instructor</option>
                          {Array.isArray(roles)
                            ? roles.map((role) => {
                                return (
                                  <option key={role.id} value={role.name}>
                                    {role.name}
                                  </option>
                                );
                              })
                            : null}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          name="s_instructor"
                          value={slot.s_instructor}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="0">Select S Instructor</option>
                          {Array.isArray(roles)
                            ? roles.map((role) => {
                                return (
                                  <option key={role.id} value={role.name}>
                                    {role.name}
                                  </option>
                                );
                              })
                            : null}
                        </Form.Select>
                      </Form.Group>
                    </Col>    
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Button className="nextButton" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EditSlot;
