import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import "../App.css";

import AuthService from "../services/auth.service";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        This field is required!
      </div>
    );
  }
};

const Login = () => {
  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.login(username, password).then(
        (response) => {
          console.log(response);
          if (response.user_id === "1") {
            navigate("/SADashboard");
          } else if (response.user_id === "2") {
            navigate("/CADashboard");
          } else if (response.user_id === "3") {
            navigate("/ADashboard");
          } else if (response.user_id === "4") {
            navigate("/IDashboard");
          } else {
            navigate("/Login");
          }
          window.location.reload();
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      );
    } else {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login">
        <div className="card card-container">
          <h1>TMS</h1>
          <h3>Sign In</h3>
          <Form onSubmit={handleLogin} ref={form}>
            <div className="mb-3">
              <Input
                type="text"
                className="form-control"
                name="username"
                placeholder="Enter Username"
                value={username}
                onChange={onChangeUsername}
                validations={[required]}
              />
            </div>
            <div className="mb-3">
              <Input
                type="password"
                className="form-control"
                name="password"
                placeholder="Enter Password"
                value={password}
                onChange={onChangePassword}
                validations={[required]}
              />
            </div>
            <div className="mb-3">
              <button
                className="form-btn"
                disabled={loading}
                onClick={handleLogin}
              >
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Login</span>
              </button>
            </div>

            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
            <CheckButton style={{ display: "none" }} ref={checkBtn} />
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
