import { React, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./instructure.css";

import Calendar from "react-calendar";
import "../../custom-css/calendar.css";

import AuthService from "../../services/auth.service";

const IDashboard = () => {

  const [value, onChange] = useState(new Date());

  const [statsData, setStatsData] = useState("one");

  const showStatsData = (count) => {
    setStatsData(count);
  };

  const [roll, setroll] = useState("");

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    console.log(user.user_id);

    setroll(user.user_id);
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <Col xl={9} lg={9} md={12} sm={12}>
          
          </Col>
          <Col xl={3} lg={3} md={12} sm={12}>
            <Calendar onChange={onChange} value={value} />
            <div className="upcomming-events">
              <div className="flex">
                <h2 className="headings">Upcoming Training</h2>
              </div>
              <div className="events__list">
                <div className="events_list_inside">
                  <div className="events">
                    <div className="event__circle"></div>
                    <div className="event__data">
                      <span className="title">IT Session</span>
                      <span className="descrip">
                        <span className="time">
                          <i className="uil uil-clock-three"></i>
                        </span>
                        <span className="text">
                          Tuesday, 17 May 10.00 AM to 1:00 PM
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="events active">
                    <div className="event__circle"></div>
                    <div className="event__data">
                      <span className="title">Face to Face QA</span>
                      <span className="descrip">
                        <span className="time">
                          <i className="uil uil-clock-three"></i>
                        </span>
                        <span className="text">
                          Tuesday, 17 May 11.00 AM to 1:30 PM
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="events">
                    <div className="event__circle"></div>
                    <div className="event__data">
                      <span className="title">IT Session</span>
                      <span className="descrip">
                        <span className="time">
                          <i className="uil uil-clock-three"></i>
                        </span>
                        <span className="text">
                          Thursday, 19 May 11.00 AM to 1:00 PM
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="events">
                    <div className="event__circle"></div>
                    <div className="event__data">
                      <span className="title">Face to Face QA</span>
                      <span className="descrip">
                        <span className="time">
                          <i className="uil uil-clock-three"></i>
                        </span>
                        <span className="text">
                          Tuesday, 17 May 10.00 AM to 1:00 PM
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="events">
                    <div className="event__circle"></div>
                    <div className="event__data">
                      <span className="title">IT Session</span>
                      <span className="descrip">
                        <span className="time">
                          <i className="uil uil-clock-three"></i>
                        </span>
                        <span className="text">
                          Tuesday, 17 May 10.00 AM to 1:00 PM
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="events">
                    <div className="event__circle"></div>
                    <div className="event__data">
                      <span className="title">Face to Face QA</span>
                      <span className="descrip">
                        <span className="time">
                          <i className="uil uil-clock-three"></i>
                        </span>
                        <span className="text">
                          Tuesday, 17 May 10.00 AM to 1:00 PM
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="events">
                    <div className="event__circle"></div>
                    <div className="event__data">
                      <span className="title">IT Session</span>
                      <span className="descrip">
                        <span className="time">
                          <i className="uil uil-clock-three"></i>
                        </span>
                        <span className="text">
                          Tuesday, 17 May 10.00 AM to 1:00 PM
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="events">
                    <div className="event__circle"></div>
                    <div className="event__data">
                      <span className="title">Face to Face QA</span>
                      <span className="descrip">
                        <span className="time">
                          <i className="uil uil-clock-three"></i>
                        </span>
                        <span className="text">
                          Tuesday, 17 May 10.00 AM to 1:00 PM
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="events">
                    <div className="event__circle"></div>
                    <div className="event__data">
                      <span className="title">IT Session</span>
                      <span className="descrip">
                        <span className="time">
                          <i className="uil uil-clock-three"></i>
                        </span>
                        <span className="text">
                          Tuesday, 17 May 10.00 AM to 1:00 PM
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="events">
                    <div className="event__circle"></div>
                    <div className="event__data">
                      <span className="title">Face to Face QA</span>
                      <span className="descrip">
                        <span className="time">
                          <i className="uil uil-clock-three"></i>
                        </span>
                        <span className="text">
                          Tuesday, 17 May 10.00 AM to 1:00 PM
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default IDashboard;
