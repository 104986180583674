import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import ProfileImg from "../../../images/ProfileImg.png";
import cameraIcon from "../../../images/cameraIcon.png";
import "./settings.css";
import { useNavigate } from "react-router-dom";

import SettingsService from "../../../services/settings.service";

import axios from "axios";

const Logo = () => {
  const navigate = useNavigate();

  const [validated, setValidated] = useState(false);

  const initialTitle = {
    logo: "",
    logoPath: "",
  };

  const [logo, setLogo] = useState(initialTitle);

  const [selectedFiles, setSelectedFiles] = useState(initialTitle);

  const [uploaded, setUploaded] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    console.log(e.target.files);
    setLogo(URL.createObjectURL(e.target.files[0]));
    setSelectedFiles(e.target.files[0]);
    setUploaded(true);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const formData = new FormData();

    formData.append('image', selectedFiles);

    console.log(formData);

    let endpoint = "https://tms.aeonsoftware.net/cit//client/clientdata/imgup";
    axios
      .post(endpoint, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-api-key": "pass123",
        },
      })
      .then((res) => {
        setMessage("File uploaded Successfully!");
        console.log("File uploaded!");
        setTimeout(() => {
          navigate("/Settings");
        }, 2000);
      });
  };

  return (
    <>
      <h1>Choose Logo</h1>
      <div className="uplode_success">{message}</div>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <div className="logoPreviw">
              <label htmlFor="logo" className="logoLabel">
                <img src={cameraIcon} className="cameraIcon" alt="" />
              </label>
              <div className="inputFile">
                <input
                  type="file"
                  name="image"
                  id="logo"
                  value={logo.logo}
                  onChange={handleInputChange}
                  required
                />
              </div>
              {uploaded === true ? (
                <img src={logo} className="previewImg" alt="" />
              ) : (
                <>
                  {logo.logoPath !== '' ? (
                    <>
                    <img
                  src={`https://tms.aeonsoftware.net/cit//uploads/${logo.logoPath}`}
                  className="previewImg"
                  alt=""
                />
                    </>
                  ) : (
                    <>
                    <img
                  src={`https://tms.aeonsoftware.net/cit//uploads/male-placeholder.jpg`}
                  className="previewImg"
                  alt=""
                />
                    </>
                  )}
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12}>
            <Button
              type="submit"
              className="subButton"
              // onClick={() => {
              //   saverole();
              // }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
};
export default Logo;
