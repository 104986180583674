import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import AssetService from "../../../services/asset.service";

const ViewStaff = ({ vid }) => {
  const [remove, setRemove] = useState(false);

  const {navigate} = useNavigate();

  const [did, setDid] = useState("");

  const [message, setMessage] = useState("");

  const [asset, setAsset] = useState({
    id: vid,
    first_name: "",
    last_name: "",
    role: "",
    role_id: "",
    email: "",
    mobile: "",
    role_type: "",
    working_hours: "",
    work_experience: "",
    joining_date: "",
    address: "",
    nationality: "",
    hourly_rate: "",
  });

  const handleRemoveShow = (e) => {
    setRemove(true);
    setDid(e.target.getAttribute("data-bs-target"));
  };

  const handleRemoveClose = () => {
    setRemove(false);
  };

  const getRole = (vid) => {
    AssetService.getAssetBy(vid)
      .then((response) => {
        setAsset(response.data[0]);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (vid) {
      getRole(vid);
    }
  }, [vid]);

  const deleteasset = () => {
    AssetService
      .remove(did)
      .then((response) => {
        setMessage(response.data.message);
        setRemove(false);
        console.log(response.data.message);
        setInterval(() => {
          navigate('/ManageAssets');
        }, 1000)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <div className="viewasset">
        <div className="asset-details">
          {asset.photo === "" ? (
            <>
              <img
                src={`https://tms.aeonsoftware.net/cit//uploads/placeholder.png`}
                width="100%"
                alt=""
                style={{ padding: "10px 0px" }}
              />
            </>
          ) : (
            <>
              <img
                src={`https://tms.aeonsoftware.net/cit//uploads/${asset.photo}`}
                width="100%"
                alt={asset.name}
                style={{ padding: "10px 0px" }}
              />
            </>
          )}
        </div>
        <div className="asset-details">
          <div className="title">
            <span>Name</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{asset.name}</span>
          </div>
        </div>
        <div className="asset-details">
          <div className="title">
            <span>Manager</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{asset.manager}</span>
          </div>
        </div>
        <div className="asset-details">
          <div className="title">
            <span>Students Capacity</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{asset.capacity}</span>
          </div>
        </div>
        <div className="asset-details">
          <div className="title">
            <span>Maintenance Period</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{asset.maintenance_period}</span>
          </div>
        </div>
        <div className="asset-details">
          <div className="title">
            <span>Note</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>
            {asset.note}
            </span>
          </div>
        </div>
        <div className="asset-details">
          <div className="title">
            <span>Registration No.</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{asset.registration_no}</span>
          </div>
        </div>
        <div className="asset-details">
          <div className="title">
            <span>Purchased Date</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{asset.perchase_date}</span>
          </div>
        </div>
        <div className="flex">
          <Button>
            <Link to={"/EditAsset/" + vid}>Edit</Link>
          </Button>
          <Button
            variant="danger"
            onClick={handleRemoveShow}
            id="deleteStaff"
            data-bs-target={vid}
          >
            Delete
          </Button>
        </div>
      </div>
      <Modal show={remove} onHide={handleRemoveClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this asset.</p>
          <div className="flex">
            <Button onClick={deleteasset}>Yes</Button>
            <Button variant="danger" onClick={handleRemoveClose}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ViewStaff;
