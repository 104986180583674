import http from "../http-common";

const getAll = () => {
  return http.get("/training/assets/index");
};

const getAssetBy = (id) => {
  return http.get(`/training/assets/index?id=${id}`);
};

const getAssetByAssetType = (aid) => {
  return http.get(`/training/assets/index?asset_type=${aid}`);
};

const create = (data) => {
    return http.post("/training/assets/index", data)
}

const isExist = (ek) => {
  return http.get(`/training/assets/rolekey?ek=${ek}`);
}

const chnageSts = (id) => {
  return http.get(`/training/assets/index?id=${id}`);
}

const remove = (id) => {
  return http.delete(`/training/assets/index?id=${id}`);
};


const AssetService = {
  getAll,
  getAssetBy,
  getAssetByAssetType,
  create,
  isExist,
  chnageSts,
  remove
};

export default AssetService;