import http from "../http-common";

const getAll = () => {
  return http.get("/training/course/index");
};

const getCourseBy = (id) => {
  return http.get(`/training/course/index?id=${id}`);
};

const create = (data) => {
    return http.post("/training/course/index", data)
}

const isExist = (ek) => {
  return http.get(`/training/course/rolekey?ek=${ek}`);
}

const chnageSts = (id) => {
  return http.get(`/training/course/index?id=${id}`);
}

const remove = (id) => {
  return http.delete(`/training/course/index?id=${id}`);
};


const CourseService = {
  getAll,
  getCourseBy,
  create,
  isExist,
  chnageSts,
  remove
};

export default CourseService;