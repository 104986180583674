import http from "../http-common";

const getAll = () => {
  return http.get("/training/trainingtype/index");
};

const getTrainingTypeById = (id) => {
  return http.get(`/training/trainingtype/index?id=${id}`);
};

const getTrainingTypeByCourse = (id) => {
  return http.get(`/training/trainingtype/index?course=${id}`);
};


const create = (data) => {
    return http.post("/training/trainingtype/index", data)
}

const isExist = (ek) => {
  return http.get(`/training/trainingtype/rolekey?ek=${ek}`);
}

const chnageSts = (id) => {
  return http.get(`/training/trainingtype/index?id=${id}`);
}

const remove = (id) => {
  return http.delete(`/training/trainingtype/index?id=${id}`);
};


const TrainingTypeService = {
  getAll,
  getTrainingTypeById,
  getTrainingTypeByCourse,
  create,
  isExist,
  chnageSts,
  remove
};

export default TrainingTypeService;