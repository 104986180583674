import React, { useState } from "react";
import { Container, Row, Form, Col, Button, Alert } from "react-bootstrap";

import "./assets.css";

import AssetTypeService from "../../../services/assettype.service";

const AddAssetType = () => {
  const [messsage, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const [assetType, setAssetType] = useState({
    name: "",
    note: "",
  });

  const [validated, setValidated] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setAssetType({ ...assetType, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    event.preventDefault();

    const data = {
      name: assetType.name,
      note: assetType.note,
    };

    console.log(data);

    if (Object.values(data).every((value) => value)) {
      console.log(true);
      AssetTypeService.create(data)
        .then((response) => {
          setAssetType(response.data);
          console.log(response.data);
          setSubmitted(true);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      console.log(false);
    }
  };

  return (
    <>
      <Container>
        <Row>
          <div className="staffsInfo">
            <div className="AddBoxSmall">
              <div className="head">
                <h3>Enter Details of Asset Type</h3>
              </div>
              {submitted ? (
                <>
                  <Row>
                    <div className="sucmessage">Asset Type Added Successfully!</div>
                  </Row>
                </>
              ) : (
                <>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <div className="errmessage">{messsage}</div>
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            name="name"
                            value={assetType.name}
                            onChange={handleInputChange}
                            required
                            title="Name"
                          />
                          <Form.Label>Name</Form.Label>
                        </Form.Group>
                      </Col>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <Form.Group
                          className="mb-3"
                          controlId="exampleForm.ControlInput1"
                        >
                          <Form.Control
                            type="text"
                            name="note"
                            value={assetType.note}
                            onChange={handleInputChange}
                            required
                            title="Note"
                          />
                          <Form.Label>Note</Form.Label>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl={12} lg={12} md={12} sm={12}>
                        <Button type="submit" className="nextButton">
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AddAssetType;
