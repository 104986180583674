import React, { useState } from "react";
import { Container, Row, Form, Col, Button, Alert } from "react-bootstrap";

import AddIcon from '../../../images/AddIcon.png';
import ViewIcon from '../../../images/ViewIcon.png';
import EditIcon from '../../../images/EditIconNew.png';
import DeleteIconNew from '../../../images/DeleteIconNew.png';

import "./courses.css";

import CourseService from "../../../services/course.service";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const EditCourse = () => {

  const { id } = useParams();

  const [validated, setValidated] = useState(false);

  const initialRole = {
    id: id,
    name: "",
    note: "",
  };

  const [course, setCourse] = useState(initialRole);

  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCourse({ ...course, [name]: value });
  };

  const getCourse = (id) => {
    CourseService.getCourseBy(id).then((response) => {
      console.log(response.data);
      setCourse(response.data[0]);
    }).catch((e) => {
      console.log(e);
    });
  }

  useEffect(() => {
    if (id) {
      getCourse(id);
    }
  }, [])

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    fetch("https://tms.aeonsoftware.net/cit//training/course/index", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "pass123",
      },
      body: JSON.stringify({
        id: course.id,
        name: course.name,
        note: course.note
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSubmitted(true);
      })
      .catch((err) => {
        console.log(err.message);
      });

    // if (Object.values(data).every((value) => value)) {
      // CourseService.create(data)
      //   .then((response) => {
      //     console.log(response.data);
      //     setCourse(response.data);
      //     setSubmitted(true);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
    // }
  };

  return (
    <>
      <Container>
        <Row>
          <div className="coursesInfo">
            <div className="courseDetailsBox">
              {submitted ? (
                <Row>
                  <div className="sucmessage">Course Updated Successfully!</div>
                </Row>
              ) : (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="head">
                    <h3>Enter Course Details</h3>
                  </div>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group className="mb-3" controlId="name">
                        <Form.Control
                          type="text"
                          name="name"
                          value={course.name}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Name</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group className="mb-3" controlId="note">
                        <Form.Control
                          type="text"
                          name="note"
                          value={course.note}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Note</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>                 
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Button
                        type="submit"
                        className="nextButton"
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EditCourse;
