import React, { useState, useEffect } from "react";
import { Container, Row, Form, Col, Button, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Select from "react-select";

import "./students.css";

import RoleService from "../../../services/role.service";

import StaffService from "../../../services/staff.service";

import StudentService from "../../../services/student.service";

import CourseService from "../../../services/course.service";

import AuthService from "../../../services/auth.service";

import ClientService from "../../../services/client.service";

const EditStudent = () => {
  const { id } = useParams();

  const [roles, setRoles] = useState([]);

  const [courses, setCourses] = useState([]);

  const [clients, setClients] = useState([]);

  const [messsage, setMessage] = useState("");

  const [student, setStudent] = useState({
    id: id,
    first_name: "",
    last_name: "",
    photo: "",
    client_id: "",
    qualification: "",
    email: "",
    mobile: "",
    dob: "",
    license: "",
    course: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const [validated, setValidated] = useState(false);

  const [roll, setRoll] = useState("");

  const [options, setOptions] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [selectedValues, setSelectedValues] = useState([]);

  const handleOptionChange = (event) => {
    setSelectedOptions(event);
    const values = event.map((option) => option.value).toString();
    setSelectedValues(values);
    setStudent({ ...student, course: values });
  };

  const getRole = (id) => {
    StudentService.getStudentBy(id)
      .then((response) => {
        setStudent(response.data[0]);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (id) {
      getRole(id);
    }

    CourseService.getAll()
      .then((response) => {
        setCourses(response.data);
        const optionsData = response.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptions(optionsData);
      })
      .catch((e) => {
        console.log(e);
      });

    ClientService.getAll()
      .then((response) => {
        setClients(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    const user = AuthService.getCurrentUser();
    console.log(user.id);
    setRoll(user.user_id);
    if (user.user_id === "2") {
      setStudent({ ...student, client_id: user.id });
    }
  }, [id]);

  RoleService.getAll()
    .then((response) => {
      setRoles(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setStudent({ ...student, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    event.preventDefault();

    fetch("https://tms.aeonsoftware.net/cit//student/studentdata/index", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "pass123",
      },
      body: JSON.stringify({
        id: student.id,
        first_name: student.first_name,
        last_name: student.last_name,
        photo: student.photo,
        client_id: student.client_id,
        qualification: student.qualification,
        email: student.email,
        mobile: student.mobile,
        dob: student.dob,
        license: student.license,
        course: student.course,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSubmitted(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <Container>
        <Row>
          <div className="staffsInfo">
            <div className="staffDetailsBox">
              <div className="head">
                <h3>Edit Student Details</h3>
              </div>
              {submitted ? (
                <Row>
                  <div className="sucmessage">Student Updated Successfully!</div>
                </Row>
              ) : (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="errmessage">{messsage}</div>
                  <Row>
                    {roll === "3" ? (
                      <>
                        <Col xl={6} lg={6} md={12} sm={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Select
                              aria-label="Default select example"
                              name="client_id"
                              value={student.client_id}
                              onChange={handleInputChange}
                              required
                            >
                              <option value="NA">Select</option>
                              <option value="0">Admin</option>
                              {Array.isArray(clients)
                                ? clients.map((client) => {
                                    return (
                                      <option key={client.id} value={client.id}>
                                        {client.name}
                                      </option>
                                    );
                                  })
                                : null}
                            </Form.Select>
                            <Form.Label>Client Name</Form.Label>
                          </Form.Group>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col
                          xl={6}
                          lg={6}
                          md={12}
                          sm={12}
                          style={{ display: "none" }}
                        >
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Control
                              type="text"
                              name="num_std"
                              value={student.client_id}
                              onChange={handleInputChange}
                              required
                            />
                            <Form.Label>Client Name</Form.Label>
                          </Form.Group>
                        </Col>
                      </>
                    )}
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="first_name"
                          value={student.first_name}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>First Name</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="last_name"
                          value={student.last_name}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Last Name</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="email"
                          value={student.email}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Email ID</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="mobile"
                          value={student.mobile}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Mobile Number</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="address"
                          value={student.address}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Address</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="nationality"
                          value={student.nationality}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Nationality</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Select
                          options={options}
                          placeholder="Course"
                          onChange={handleOptionChange}
                          isSearchable={true}
                          isMulti
                          className="form-control"
                          // value={courses.find(
                          //   (option) => option.value === student.course
                          // )}
                          value={options.filter(option => student.course.includes(option.value))}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="qualification"
                          value={student.qualification}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Qualification</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="license"
                          value={student.license}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Licence Number</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3 joining_date"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="date"
                          name="dob"
                          value={student.dob}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Date Of Birth</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Button type="submit" className="nextButton">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EditStudent;
