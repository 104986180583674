import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../clients.css";
import { Col, Row, Form, Button, Alert, Container } from "react-bootstrap";

import CountryCodes from "../../../../country.json";

import ClientService from "../../../../services/client.service";

const EditClient = () => {
  const { id } = useParams();

  const [validated, setValidated] = useState(false);

  const [client, setClient] = useState({
    id: id,
    name: "",
    email: "",
    person: "",
    mobile: "",
    country: "",
    address: "",
    tax_description: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const getClient = (id) => {
    ClientService.getClientBy(id)
      .then((response) => {
        setClient(response.data[0]);
        // console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (id) {
      getClient(id);
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setClient({ ...client, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    event.preventDefault();

    fetch("https://tms.aeonsoftware.net/cit//client/clientdata/index_put", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "pass123",
      },
      body: JSON.stringify({
        id: client.id,
        name: client.name,
        email: client.email,
        person: client.person,
        mobile: client.mobile,
        country: client.country,
        address: client.address,
        tax_description: client.tax_description,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // setClient(data);
        setSubmitted(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <Container>
        <Row>
          <div className="clientsInfo">
            <div className="AddBoxLarge">
              <div className="head">
                <h3>Enter Client Details</h3>
              </div>
              {submitted ? (
                <>
                  <Row>
                  <div className="sucmessage">Client Updated Successfully!</div>
                  </Row>
                </>
              ) : (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="name"
                          value={client.name}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Name(Organization)</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="person"
                          value={client.person}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Responsive Person</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          name="country"
                          value={client.country}
                          onChange={handleInputChange}
                          required
                        >
                          <option>Country</option>
                          {Array.isArray(CountryCodes)
                            ? CountryCodes.map((country) => {
                                return (
                                  <option
                                    key={country.code}
                                    value={country.code}
                                  >
                                    {country.name}
                                  </option>
                                );
                              })
                            : null}
                        </Form.Select>
                        <Form.Label>Country</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="email"
                          name="email"
                          value={client.email}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Email ID</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="address"
                          value={client.address}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Address</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="mobile"
                          value={client.mobile}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Mobile Number</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="tax_description"
                          value={client.tax_description}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Taxation Description</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Button type="submit" className="nextButton">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EditClient;
