import React, { useState, useEffect } from "react";
import { Container, Row, Form, Col, Button, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

import "./roles.css";

import RoleService from "../../../services/role.service";

const AddRole = () => {
  const [validated, setValidated] = useState(false);

  const { id } = useParams();

  const initialRole = {
    id: id,
    name: "",
    note: "",
  };

  const [role, setRole] = useState(initialRole);

  const [submitted, setSubmitted] = useState(false);

  const getRole = (id) => {
    RoleService.getRoleBy(id)
      .then((response) => {
        setRole(response.data[0]);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (id) {
      getRole(id);
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRole({ ...role, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const data = {
      name: role.name,
      note: role.note,
    };
    console.log(data);

    fetch("https://tms.aeonsoftware.net/cit//role/roledata/uroles", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "pass123",
      },
      body: JSON.stringify({
        id: role.id,
        name: role.name,
        note: role.note,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        // setClient(data);
        setSubmitted(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <Container>
        <Row>
          <div className="rolesInfo">
            <div className="roleDetailsBox">
              <div className="head">
                <h3>Enter Role Details</h3>
              </div>
              {submitted ? (
                <Row>
                  <div className="sucmessage">Role Updated Successfully!</div>
                </Row>
              ) : (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group className="mb-3" controlId="name">
                        <Form.Control
                          type="text"
                          name="name"
                          value={role.name}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Name</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Form.Group className="mb-3" controlId="note">
                        <Form.Control
                          type="text"
                          name="note"
                          value={role.note}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Note</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Button
                        type="submit"
                        className="nextButton"
                        // onClick={() => {
                        //   saverole();
                        // }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AddRole;
