import React, { useState, useEffect } from "react";
import { Container, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ViewIcon from "../../../images/view.png";
import EditIcon from "../../../images/EditIcon.png";
import DeleteIcon from "../../../images/DeleteIcon.png";
import SearchIcon from "../../../images/SearchIcon.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import "../../../custom-css/datatable.css";

import Staffs from "../../../staffs.json";

import StaffService from "../../../services/staff.service";

import StudentService from "../../../services/student.service";

import ClientService from "../../../services/client.service";

import CourseService from "../../../services/course.service";

import AuthService from "../../../services/auth.service";

import "./students.css";

import ViewStudent from "./ViewStudent";

const ManageStudents = () => {
  
  const [students, setStudents] = useState([]);

  const [claStudents, setClaStudents] = useState([]);

  const [courses, setCourses] = useState([]);

  const [remove, setRemove] = useState(false);

  const [view, setView] = useState(false);

  const [did, setDid] = useState("");

  const [vid, setVid] = useState("");

  const [message, setMessage] = useState("");

  const [clients, setClients] = useState([]);

  const [roll, setRoll] = useState("");

  const [loadTable, setLoadTable] = useState(false);

  const handleRemoveShow = (e) => {
    setRemove(true);
    setDid(e.target.getAttribute("data-bs-target"));
  };

  const handleRemoveClose = () => {
    setRemove(false);
  };

  const handleViewShow = (e) => {
    setView(true);
    setVid(e.target.getAttribute("data-bs-target"));
  };

  const handleViewClose = () => {
    setView(false);
  };

  const deleteStaff = () => {
    StudentService.remove(did)
      .then((response) => {
        setMessage(response.data.message);
        setRemove(false);
        setStudents(
          students.filter((student) => student.id !== did)
        );
        console.log(response.data.message);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    console.log(user);

    if (user) {
      setRoll(user.user_id);
    }

    CourseService.getAll()
      .then((response) => {
        setCourses(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    StudentService.getAll()
    .then((response) => {
      setStudents(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

    StudentService.getStudentByClient(user.user_id)
    .then((response) => {
      setClaStudents(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

    ClientService.getAll()
    .then((response) => {
      setClients(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

   


    if (!$.fn.DataTable.isDataTable("#table")) {
      function filterGlobal() {
        $("#table")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#table")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            processing: true,
            select: true,
            bDestroy: true,

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
          setLoadTable(true);
        }, 1000);
      });
    }
  }, []);

  function getClientName(clientID, clients) {
    const client = clients.find(r => r.id === clientID);
    return client ? client.name : 'Admin';
  }

  function getCourseName(courseID, courses) {
    const courseIDs = courseID.split(",");

    const courseNames = [];

    for (let i = 0; i < courseIDs.length; i++) {
      const matchedAsset = courses.find((r) => r.id === courseIDs[i]);
      if (matchedAsset) {
        courseNames.push(matchedAsset.name);
      } else {
        courseNames.push("Course Not Added");
      }
    } 
    return courseNames.join(", ");
  }

  function getUserName(userID, users) {
    const user = users.find(r => r.id === userID);
    return user ? user.name : 'Not Available';
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="staffsInfo">
            <div className="message">{message}</div>
            <div className={loadTable ? 'table-actions active' : 'table-actions' }>
              <label htmlFor="">
                <input
                  type="text"
                  className="global_filter"
                  id="global_filter"
                  placeholder="Search"
                />
                <img src={SearchIcon} alt="" />
              </label>
              <Button className="custom-btn">
                <Link to={"/AddStudent"}>Add Student</Link>
              </Button>
            </div>
            <div className="staffsInfoTable">
              <div className="table-responsive">
                <table id="table" className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Name</th>
                      <th>Client</th>
                      <th>Course</th>
                      <th>Email ID</th>
                      <th>Mobile No.</th>
                      {/* <th>Created By</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {roll === '3' ? 
                  (<>
                    {Array.isArray(students)
                      ? students.map((student, index) => {
                          return (
                            <tr key={student.id}>
                              <td>{index + 1}</td>
                              <td>{student.first_name} {student.last_name}</td>
                              <td>{getClientName(student.client_id, clients)}</td>
                              <td>{getCourseName(student.course, courses)}</td>
                              <td>{student.email}</td>
                              <td>{student.mobile}</td>
                              {/* <td>{student.created_by}</td> */}
                              <td>
                                <div className="flex">
                                  <img
                                      onClick={handleViewShow}
                                      id="viewStudent"
                                      data-bs-target={student.id}
                                      src={ViewIcon}
                                      alt="View Student"
                                      title="View Student"
                                      style={{width: '24px', height: '12px'}}
                                    />
                                  <Link to={"/EditStudent/" + student.id}>
                                    <img
                                      src={EditIcon}
                                      alt="Edit Student"
                                      title="Edit Student"
                                      style={{width: '18px', height: '18px'}}
                                    />
                                  </Link>
                                  <img
                                    onClick={handleRemoveShow}
                                    id="deleteStudent"
                                    data-bs-target={student.id}
                                    src={DeleteIcon}
                                    alt="Delete Student"
                                    title="Delete Student"
                                    style={{width: '18px', height: '18px'}}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </>) : roll === '2' ? (
                    <>
                    {Array.isArray(claStudents)
                      ? claStudents.map((claStudent, index) => {
                          return (
                            <tr key={claStudent.id}>
                              <td>{index + 1}</td>
                              <td>{claStudent.first_name} {claStudent.last_name}</td>
                              <td>{getClientName(claStudent.client_id, clients)}</td>
                              <td>{getCourseName(claStudent.course, courses)}</td>
                              <td>{claStudent.email}</td>
                              <td>{claStudent.mobile}</td>
                              {/* <td>{claStudent.created_by}</td> */}
                              <td>
                                <div className="flex">
                                  <img
                                      onClick={handleViewShow}
                                      id="viewStudent"
                                      data-bs-target={claStudent.id}
                                      src={ViewIcon}
                                      alt="View Student"
                                      title="View Student"
                                      style={{width: '24px', height: '12px'}}
                                    />
                                  <Link to={"/EditStudent/" + claStudent.id}>
                                    <img
                                      src={EditIcon}
                                      alt="Edit Student"
                                      title="Edit Student"
                                      style={{width: '18px', height: '18px'}}
                                    />
                                  </Link>
                                  <img
                                    onClick={handleRemoveShow}
                                    id="deleteStudent"
                                    data-bs-target={claStudent.id}
                                    src={DeleteIcon}
                                    alt="Delete Student"
                                    title="Delete Student"
                                    style={{width: '18px', height: '18px'}}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </>
                  ) :
                  (<></>)}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      <Modal show={remove} onHide={handleRemoveClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this student.</p>
          <div className="flex">
            <Button onClick={deleteStaff}>Yes</Button>
            <Button variant="danger" onClick={handleRemoveClose}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={view} onHide={handleViewClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>View Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewStudent vid={vid} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ManageStudents;
