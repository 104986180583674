import React, { useState, useEffect } from "react";
import { Container, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ViewIcon from "../../../images/ViewIcon.png";
import EditIcon from "../../../images/EditIcon.png";
import DeleteIcon from "../../../images/DeleteIcon.png";
import SearchIcon from "../../../images/SearchIcon.png";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import "../../../custom-css/datatable.css";

import RoleService from "../../../services/role.service";

import TrainingTypeService from "../../../services/trainingtype.service";

import CourseService from "../../../services/course.service";

import AssetService from "../../../services/asset.service";

import AssetTypeService from "../../../services/assettype.service";

import ViewTrainingType from "./ViewTrainingType";

import "./trainings.css";

const TrainingTypes = () => {
  const [trainingTypes, setTrainingTypes] = useState([]);

  const [assets, setAssets] = useState([]);

  const [assetsType, setAssetsType] = useState([]);

  const [courses, setCourses] = useState([]);

  const [remove, setRemove] = useState(false);

  const [view, setView] = useState(false);

  const [message, setMessage] = useState("");

  const [did, setDid] = useState("");

  const [vid, setVid] = useState("");

  const [loadTable, setLoadTable] = useState(false);

  const handleRemoveShow = (e) => {
    setRemove(true);
    setDid(e.target.getAttribute("data-bs-target"));
  };

  const handleRemoveClose = () => {
    setRemove(false);
  };

  const handleViewShow = (e) => {
    setView(true);
    setVid(e.target.getAttribute("data-bs-target"));
  };

  const handleViewClose = () => {
    setView(false);
  };

  useEffect(() => {

    TrainingTypeService.getAll()
    .then((response) => {
      setTrainingTypes(response.data);
      console.log(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

    AssetService.getAll()
    .then((response) => {
      setAssets(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

    AssetTypeService.getAll()
    .then((response) => {
      setAssetsType(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

    if (!$.fn.DataTable.isDataTable("#table")) {
      function filterGlobal() {
        $("#table")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#table")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            processing: true,
            select: true,
            bDestroy: true,

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
          setLoadTable(true);
        }, 1000);
      });
    }
  }, []);

  const deleteRole = () => {
    TrainingTypeService.remove(did).then((response) => {
      setMessage(response.data.message);
      setRemove(false);
      // console.log(response.data.message);
      setTrainingTypes(trainingTypes.filter((trainingType) => trainingType.id !== did));
    }).catch((e) => {
      console.log(e);
    });
  };

  const [checkedState, setCheckedState] = useState([true, false, false, true]);

  const changeStatus = (position, id) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    // console.log(position);
    confirmAlert({
      title: "Confirm to submit",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setCheckedState(updatedCheckedState);

            // ClientService.chnageSts(id)
            //   .then((response) => {
            //     setMessage(response.data);
            //     console.log(response.data);
            //   })
            //   .catch((e) => {
            //     console.log(e);
            //   });
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  CourseService.getAll()
  .then((response) => {
    setCourses(response.data);
    // console.log(response.data)
  })
  .catch((e) => {
    console.log(e);
  });

  function getCourseName(courseId, courses) {
    const course = courses.find(r => r.id === courseId);
    return course ? course.name : 'Unknown Course';
  }

  const getAssetName = (assetIds, assets) => {
    const assetID = assetIds.split(",");
    const assetNames = [];
    for (let i = 0; i < assetID.length; i++) {
      const matchedAsset = assets.find((r) => r.id === assetID[i]);
      if (matchedAsset) {
        assetNames.push(matchedAsset.name);
      } else {
        assetNames.push("Unknown Asset");
      }
    } 
    return assetNames.join(", ");
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div className="coursesInfo">
            <div className="message">{message}</div>
            <div className={loadTable ? 'table-actions active' : 'table-actions' }>
              <label htmlFor="">
                <input
                  type="text"
                  className="global_filter"
                  id="global_filter"
                  placeholder="Search"
                />
                <img src={SearchIcon} alt="" />
              </label>
              <Button className="custom-btn">
                <Link to={"/AddTrainingType"}>Add Training Type</Link>
              </Button>
            </div>
            <div className="coursesInfoTable">
              <div className="table-responsive">
                <table id="table" className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Course</th>
                      <th>Training Type</th>
                      <th>Required Assets</th>
                      <th>Note</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {Array.isArray(trainingTypes)
                      ? trainingTypes.map((trainingType, index) => {
                          return (
                            <tr key={trainingType.id}>
                              <td>{index + 1}</td>
                              <td>{getCourseName(trainingType.course, courses)}</td>
                              <td>{trainingType.training_type}</td>
                              <td>{getAssetName(trainingType.asset_id, assetsType)}</td>
                              <td>{trainingType.note}</td>
                              <td>
                                <div className="flex">
                                <img
                                    onClick={handleViewShow}
                                    id="viewAsset"
                                    data-bs-target={trainingType.id}
                                    src={ViewIcon}
                                    alt="View Asset"
                                    title="View Asset"
                                    style={{ width: "24px", height: "12px" }}
                                  />
                                  <Link to={"/EditTrainingType/" + trainingType.id}>
                                    <img
                                      src={EditIcon}
                                      alt="Edit Route"
                                      title="Edit Route"
                                      style={{width: '18px', height: '18px'}}
                                    />
                                  </Link>
                                  <img
                                    onClick={handleRemoveShow}
                                    id="deleteRole"
                                    data-bs-target={trainingType.id}
                                    src={DeleteIcon}
                                    alt="Delete Route"
                                    title="Delete Route"
                                    style={{width: '18px', height: '18px'}}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      <Modal show={remove} onHide={handleRemoveClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Course</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this course.</p>
          <div className="flex">
            <Button onClick={deleteRole}>Yes</Button>
            <Button variant="danger" onClick={handleRemoveClose}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={view} onHide={handleViewClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>View Training Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewTrainingType vid={vid} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TrainingTypes;
