import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import StudentService from "../../../services/student.service";

import CourseService from "../../../services/course.service";

import ClientService from "../../../services/client.service";

const ViewStudent = ({ vid }) => {
  const [remove, setRemove] = useState(false);

  const [did, setDid] = useState("");

  const [message, setMessage] = useState("");

  const [courses, setCourses] = useState([]);

  const [clients, setClients] = useState([]);

  const [student, setStudent] = useState({
    id: vid,
    first_name: "",
    last_name: "",
    role: "",
    role_id: "",
    email: "",
    mobile: "",
    role_type: "",
    working_hours: "",
    work_experience: "",
    joining_date: "",
    address: "",
    nationality: "",
    hourly_rate: "",
  });

  const handleRemoveShow = (e) => {
    setRemove(true);
    setDid(e.target.getAttribute("data-bs-target"));
  };

  const handleRemoveClose = () => {
    setRemove(false);
  };

  const getStudent = (vid) => {
    StudentService.getStudentBy(vid)
      .then((response) => {
        setStudent(response.data[0]);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (vid) {
      getStudent(vid);
    }
    CourseService.getAll()
      .then((response) => {
        setCourses(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    ClientService.getAll()
      .then((response) => {
        setClients(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [vid]);

  const deleteStaff = () => {
    StudentService.remove(did)
      .then((response) => {
        setMessage(response.data.message);
        setRemove(false);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function getClientName(clientID, clients) {
    const client = clients.find((r) => r.id === clientID);
    return client ? client.name : "Admin";
  }

  function getCourseName(courseID = "", courses) {
    const courseIDs = courseID.split(",");

    const courseNames = [];

    for (let i = 0; i < courseIDs.length; i++) {
      const matchedAsset = courses.find((r) => r.id === courseIDs[i]);
      if (matchedAsset) {
        courseNames.push(matchedAsset.name);
      } else {
        courseNames.push("Course Not Added");
      }
    }
    return courseNames.join(", ");
  }

  return (
    <>
      <div className={message ? "modalMessage active" : "modalMessage"}>
        {message}
      </div>
      <div className="viewStaff">
        <div className="staff-details">
          <div className="title">
            <span>Name</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>
              {student.first_name} {student.last_name}
            </span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Client</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{getClientName(student.client_id, clients)}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Course</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{getCourseName(student.course, courses)}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Qualification</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{student.qualification}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Email</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{student.email}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Mobile No.</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{student.mobile}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Date of Birth</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{student.dob}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Nationality</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{student.nationality}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Address</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{student.address}</span>
          </div>
        </div>
        <div className="flex">
          <Button>
            <Link to={"/EditStaff/" + vid}>Edit</Link>
          </Button>
          <Button
            variant="danger"
            onClick={handleRemoveShow}
            id="deleteStaff"
            data-bs-target={vid}
          >
            Delete
          </Button>
        </div>
      </div>
      <Modal show={remove} onHide={handleRemoveClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Student</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this student.</p>
          <div className="flex">
            <Button onClick={deleteStaff}>Yes</Button>
            <Button variant="danger" onClick={handleRemoveClose}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ViewStudent;
