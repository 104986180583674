import React, { useState, useEffect } from "react";
import { Container, Row, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import AddIconWhite from "../../../images/AddIconWhite.png"
import ViewIcon from "../../../images/view.png";
import EditIcon from "../../../images/EditIcon.png";
import DeleteIcon from "../../../images/DeleteIcon.png";
import SearchIcon from "../../../images/SearchIcon.png";
import "react-confirm-alert/src/react-confirm-alert.css";

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import $ from "jquery";

import "../../../custom-css/datatable.css";

import AssetService from "../../../services/asset.service";

import AssetTypeService from "../../../services/assettype.service";

import StaffService from "../../../services/staff.service";

import ViewAssets from "./ViewAssets";

import "./assets.css";

const ManageAssets = () => {

  const [assets, setAssets] = useState([]);

  const [staffs, setStaffs] = useState([]);

  const [remove, setRemove] = useState(false);

  const [view, setView] = useState(false);

  const [did, setDid] = useState("");

  const [vid, setVid] = useState("");

  const [message, setMessage] = useState("");

  const [loadTable, setLoadTable] = useState(false);

  const [assetsType, setAssetsType] = useState([]);

  const handleRemoveShow = (e) => {
    setRemove(true);
    setDid(e.target.getAttribute("data-bs-target"));
  };

  const handleRemoveClose = () => {
    setRemove(false);
  };

  const handleViewShow = (e) => {
    setView(true);
    setVid(e.target.getAttribute("data-bs-target"));
  };

  const handleViewClose = () => {
    setView(false);
  };

  const deleteasset = () => {
    AssetService
      .remove(did)
      .then((response) => {
        setMessage(response.data.message);
        setRemove(false);
        console.log(response.data.message);
        setAssets(assets.filter((asset) => asset.id !== did));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {

    AssetService.getAll()
    .then((response) => {
      setAssets(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

    AssetTypeService.getAll()
    .then((response) => {
      setAssetsType(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

    StaffService.getAll()
    .then((response) => {
      setStaffs(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
    
    if (!$.fn.DataTable.isDataTable("#table")) {
      function filterGlobal() {
        $("#table")
          .DataTable()
          .search(
            $("#global_filter").val(),
            $("#global_regex").prop("checked"),
            $("#global_smart").prop("checked")
          )
          .draw();
      }
      function filterColumn(i) {
        $("#table")
          .DataTable()
          .column(i)
          .search(
            $("#col" + i + "_filter").val(),
            $("#col" + i + "_regex").prop("checked"),
            $("#col" + i + "_smart").prop("checked")
          )
          .draw();
      }

      $(document).ready(function () {
        setTimeout(function () {
          $("#table").DataTable({
            pagingType: "full_numbers",
            responsive: true,
            pageLength: 10,
            processing: true,
            select: true,
            bDestroy: true,

            lengthMenu: [
              [10, 20, 30, 50, -1],
              [10, 20, 30, 50, "All"],
            ],
            columnDefs: [
              {
                targets: 0,
                render: function (data, type, row, meta) {
                  return type === "export" ? meta.row + 1 : data;
                },
              },
            ],
          });

          $("input.global_filter").on("keyup click", function () {
            filterGlobal();
          });

          $("input.column_filter").on("keyup click", function () {
            filterColumn($(this).parents("tr").attr("data-column"));
          });
          
          setLoadTable(true);
        }, 1000);
      });
    }
  }, []);


  function getStaffName(staffId, staffs) {
    const staff = staffs.find((r) => r.id === staffId);
    return staff ? staff.first_name + ' ' + staff.last_name : "";
  }

  function getAssetTypeName(assetTypeID, assetsType) {
    const assetType = assetsType.find((r) => r.id === assetTypeID);
    return assetType ? assetType.name : "Unknown Asset Type";
  }

  return (
    <>
      <Container fluid>
        <Row>
          <div className="assetsInfo">
            <div className="message">{message}</div>
            <div className={loadTable ? 'table-actions active' : 'table-actions' }>
              <label htmlFor="">
                <input
                  type="text"
                  className="global_filter"
                  id="global_filter"
                  placeholder="Search"
                />
                <img src={SearchIcon} alt="" />
              </label>
              <Button className="custom-btn">
                <Link to={"/ManageAssets"}>View Assets</Link>
              </Button>
              <Button className="custom-btn">
                <Link to={"/ManageAssetsType"}>View Asset Types</Link>
              </Button>
              <Button className="custom-btn">
                <Link to={"/AddAsset"}> <img src={AddIconWhite} style={{width: '15px', height: '15px'}} alt="" /> Add Asset</Link>
              </Button>
              <Button className="custom-btn">
                <Link to={"/AddAssetType"}> <img src={AddIconWhite} style={{width: '15px', height: '15px'}} alt="" /> Add Asset Type</Link>
              </Button>
            </div>
            <div className="assetsInfoTable">
              <div className="table-responsive">
                <table id="table" className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Sr. No.</th>
                      <th>Image</th>
                      <th>Asset Type</th>
                      <th>Name</th>
                      <th>Manager</th>
                      <th>Students Capacity</th>
                      <th>Maintenance Period</th>
                      <th>Purchased Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(assets)
                      ? assets.map((asset, index) => {
                          return (
                            <tr key={asset.id}>
                              <td>{index + 1}</td>
                              <td>
                                {asset.photo === "" ? (
                                  <>
                                  <img
                                      src={`https://tms.aeonsoftware.net/cit//uploads/placeholder.png`}
                                      width={90}
                                      alt=""
                                      style={{padding: '10px 0px'}}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={`https://tms.aeonsoftware.net/cit//uploads/${asset.photo}`}
                                      width={90}
                                      alt={asset.name}
                                      style={{padding: '10px 0px'}}
                                    />
                                  </>
                                )}
                              </td>
                              <td>{getAssetTypeName(asset.asset_type, assetsType)}</td>
                              <td>{asset.name}</td>
                              <td>{getStaffName(asset.manager, staffs)}</td>
                              <td>{asset.capacity}</td>
                              <td>{asset.maintenance_period}</td>
                              <td>{asset.purchase_date}</td>
                              <td>
                                <div className="flex">
                                  <img
                                    onClick={handleViewShow}
                                    id="viewAsset"
                                    data-bs-target={asset.id}
                                    src={ViewIcon}
                                    alt="View Asset"
                                    title="View Asset"
                                    style={{ width: "24px", height: "12px" }}
                                  />
                                  <Link to={"/EditAsset/" + asset.id}>
                                    <img
                                      src={EditIcon}
                                      alt="Edit Asset"
                                      title="Edit Asset"
                                      style={{ width: "18px", height: "18px" }}
                                    />
                                  </Link>
                                  <img
                                    onClick={handleRemoveShow}
                                    id="deleteAsset"
                                    data-bs-target={asset.id}
                                    src={DeleteIcon}
                                    alt="Delete Asset"
                                    title="Delete Asset"
                                    style={{ width: "18px", height: "18px" }}
                                  />
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Row>
      </Container>
      <Modal show={remove} onHide={handleRemoveClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this asset.</p>
          <div className="flex">
            <Button onClick={deleteasset}>Yes</Button>
            <Button variant="danger" onClick={handleRemoveClose}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={view} onHide={handleViewClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>View Asset</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ViewAssets vid={vid} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ManageAssets;
