import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

import AssetService from "../../../services/asset.service";

import TrainingTypeService from "../../../services/trainingtype.service";

import CourseService from "../../../services/course.service";

const ViewTrainingType = ({ vid }) => {
  const [remove, setRemove] = useState(false);

  const [did, setDid] = useState("");

  const navigate = useNavigate();

  const [trainingType, setTrainingType] = useState([]);

  const [courses, setCourses] = useState([]);

  const [assets, setAssets] = useState([]);

  const [message, setMessage] = useState("");

  const handleRemoveShow = (e) => {
    setRemove(true);
    setDid(e.target.getAttribute("data-bs-target"));
  };

  const handleRemoveClose = () => {
    setRemove(false);
  };

  const getAsset = (vid) => {
    TrainingTypeService.getTrainingTypeById(vid)
      .then((response) => {
        setTrainingType(response.data[0]);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    AssetService.getAll()
      .then((response) => {
        setAssets(response.data);
      })
      .catch((e) => {
        console.log(e);
      });

    CourseService.getAll()
      .then((response) => {
        setCourses(response.data);
        // console.log(response.data)
      })
      .catch((e) => {
        console.log(e);
      });

    if (vid) {
      getAsset(vid);
    }
  }, [vid]);

  const deletTrainingType = () => {
    TrainingTypeService.remove(did).then((response) => {
      setMessage(response.data.message);
      setRemove(false);
      setInterval(() => {
        navigate('/TrainingTypes');
      }, 1000)
    }).catch((e) => {
      console.log(e);
    });
  };

  const getCourseName = (courseId, courses) => {
    const course = courses.find((r) => r.id === courseId);
    return course ? course.name : "Unknown Course";
  };

  const getAssetName = (assetIds = "", assets) => {
    const assetID = assetIds.split(",");
    const assetNames = [];
    for (let i = 0; i < assetID.length; i++) {
      const matchedAsset = assets.find((r) => r.id === assetID[i]);
      if (matchedAsset) {
        assetNames.push(matchedAsset.name);
      } else {
        assetNames.push("Unknown Asset");
      }
    }
    return assetNames.join(", ");
  };

  return (
    <>
      <div className="suc_message">{message}</div>
      <div className="viewasset">
        <div className="asset-details">
          <div className="title">
            <span>Course</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{getCourseName(trainingType.course, courses)}</span>
          </div>
        </div>
        <div className="asset-details">
          <div className="title">
            <span>Training Type</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{trainingType.training_type}</span>
          </div>
        </div>
        <div className="asset-details">
          <div className="title">
            <span>Required Assets</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{getAssetName(trainingType.asset_id, assets)}</span>
          </div>
        </div>
        <div className="asset-details">
          <div className="title">
            <span>Note</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{trainingType.note}</span>
          </div>
        </div>
        <div className="flex">
          <Link to={"/EditTrainingType/" + vid}>
            <Button>Edit</Button>
          </Link>
          <Button
            variant="danger"
            onClick={handleRemoveShow}
            id="deleteasset"
            data-bs-target={vid}
          >
            Delete
          </Button>
        </div>
      </div>
      <Modal show={remove} onHide={handleRemoveClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Training Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this training type.</p>
          <div className="flex">
            <Button onClick={deletTrainingType}>Yes</Button>
            <Button variant="danger" onClick={handleRemoveClose}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ViewTrainingType;
