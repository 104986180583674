import { React, useState, useEffect } from "react";
import Sidebar from "./components/Sidebar";
import { useLocation } from "react-router-dom";
import Topbar from "./components/Topbar";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

// SuperAdmin
import SADashboard from "./components/Common/Dashboard/Dashboard";

import ManageClients from "./components/SupAdmin/ManageClients/ManageClients";
import AddClient from "./components/SupAdmin/ManageClients/AddClient/AddClient";
import EditClient from "./components/SupAdmin/ManageClients/EditClient/EditClient";

import ManageRoles from "./components/SupAdmin/ManageRoles/ManageRoles";
import AddRole from "./components/SupAdmin/ManageRoles/AddRole";
import EditRole from "./components/SupAdmin/ManageRoles/EditRole";

import ManageStaffs from "./components/Common/ManageStaffs/ManageStaffs";
import AddStaff from "./components/Common/ManageStaffs/AddStaff";
import EditStaff from "./components/Common/ManageStaffs/EditStaff";

import BookingReq from "./components/Common/BookingReq/BookingReq";
import AddBooking from "./components/Common/BookingReq/AddBooking";
import ScheduleBooking from "./components/Common/BookingReq/ScheduleBooking";
import Settings from "./components/Common/Settings/Settings";

import Dashboard from "./components/Common/Dashboard/Dashboard";

import ManageCourses from "./components/Admin/ManageCourses/ManageCourses";
import AddCourse from "./components/Admin/ManageCourses/AddCourse";
import EditCourse from "./components/Admin/ManageCourses/EditCourse";

import ManageAssets from "./components/Admin/ManageAssets/ManageAssets";
import AddAsset from "./components/Admin/ManageAssets/AddAsset";
import EditAsset from "./components/Admin/ManageAssets/EditAsset";

import ManageAssetsType from "./components/Admin/ManageAssets/ManageAssetsType";
import AddAssetType from "./components/Admin/ManageAssets/AddAssetType";
import EditAssetType from "./components/Admin/ManageAssets/EditAssetType";

// ClientAdmin

import ManageStudents from "./components/Common/ManageStudents/ManageStudents";
import AddStudent from "./components/Common/ManageStudents/AddStudent";
import EditStudent from "./components/Common/ManageStudents/EditStudent";

// login
import Login from "./components/Login";

import AuthService from "./services/auth.service";

import ScheduledTrainings from "./components/Common/ScheduledTrainings/ScheduledTrainings";
import ViewScheduledTrainings from "./components/Common/ScheduledTrainings/ViewScheduledTrainings";
import EditSlot from "./components/Common/ScheduledTrainings/EditSlot";

import TrainingTypes from "./components/Admin/TrainingTypes/TrainingTypes";
import AddTrainingType from "./components/Admin/TrainingTypes/AddTraininType";
import EditTrainingType from "./components/Admin/TrainingTypes/EditTrainingType";

import IDashboard from "./components/Instructure/IDashboard";

function App() {
  const [sidebar, setSidebar] = useState(false);

  const [loggedIn, setLoggedIn] = useState(false);

  const [roll, setroll] = useState("");

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    console.log(user);

    if (user) {
      setLoggedIn(true);
      setroll(user.user_id);
    }
  }, []);

  const logOut = () => {
    AuthService.logout();
    setLoggedIn(false);
    localStorage.removeItem("username");
    localStorage.clear();
  };

  const location = useLocation();

  const openSidebar = () => {
    setSidebar(!sidebar);
  };
  return (
    <>
      <div className="wrapper">
        {loggedIn ? (
          <>
            <Sidebar sidebar={sidebar} logout={logOut} />
            <div className={sidebar ? "main active" : "main"}>
              <Topbar openSidebar={openSidebar} logout={logOut} />
              <div
                className={
                  location.pathname === "/SADashboard"
                    ? "dscontent"
                    : location.pathname === "/Settings"
                    ? "dscontent"
                    : "content"
                }
              >
                <Routes>
                  {/* { roll === "Superadmin" ? (<Route exact path="/" element={<SADashboard />} />) : roll === "Superadmin" ? (<Route exact path="/" element={<CADashboard />} />) : (<Route exact path="/" element={<Login />} />) } */}

                  {roll === "1" ? (
                    <>
                      <Route exact path="/" element={<Dashboard />} />
                      <Route
                        exact
                        path="/SADashboard"
                        element={<Dashboard />}
                      />
                      <Route
                        exact
                        path="/ManageClients"
                        element={<ManageClients />}
                      />
                      <Route exact path="/AddClient" element={<AddClient />} />
                      <Route
                        exact
                        path="/EditClient/:id"
                        element={<EditClient />}
                      />

                      <Route
                        exact
                        path="/ManageRoles"
                        element={<ManageRoles />}
                      />
                      <Route exact path="/AddRole" element={<AddRole />} />
                      <Route
                        exact
                        path="/EditRole/:id"
                        element={<EditRole />}
                      />

                      <Route
                        exact
                        path="/ManageStaffs"
                        element={<ManageStaffs />}
                      />
                      <Route exact path="/AddStaff" element={<AddStaff />} />
                      <Route
                        exact
                        path="/EditStaff/:id"
                        element={<EditStaff />}
                      />

                      <Route
                        exact
                        path="/BookingReq"
                        element={<BookingReq />}
                      />
                      <Route exact path="/Settings" element={<Settings />} />
                      <Route
                        exact
                        path="/ScheduledTrainings"
                        element={<ScheduledTrainings />}
                      />
                      <Route
                        exact
                        path="/ViewScheduledTrainings/:id"
                        element={<ViewScheduledTrainings />}
                      />
                    </>
                  ) : roll === "3" ? (
                    <>
                      <Route exact path="/" element={<Dashboard />} />
                      <Route exact path="/ADashboard" element={<Dashboard />} />
                      <Route
                        exact
                        path="/SADashboard"
                        element={<Dashboard />}
                      />

                      <Route
                        exact
                        path="/ManageStudents"
                        element={<ManageStudents />}
                      />
                      <Route
                        exact
                        path="/AddStudent"
                        element={<AddStudent />}
                      />
                      <Route
                        exact
                        path="/EditStudent/:id"
                        element={<EditStudent />}
                      />

                      <Route
                        exact
                        path="/ManageStaffs"
                        element={<ManageStaffs />}
                      />
                      <Route exact path="/AddStaff" element={<AddStaff />} />
                      <Route
                        exact
                        path="/EditStaff/:id"
                        element={<EditStaff />}
                      />

                      <Route
                        exact
                        path="/ManageCourses"
                        element={<ManageCourses />}
                      />
                      <Route exact path="/AddCourse" element={<AddCourse />} />
                      <Route
                        exact
                        path="/EditCourse/:id"
                        element={<EditCourse />}
                      />

                      <Route
                        exact
                        path="/ManageAssets"
                        element={<ManageAssets />}
                      />
                      <Route exact path="/AddAsset" element={<AddAsset />} />
                      <Route
                        exact
                        path="/EditAsset/:id"
                        element={<EditAsset />}
                      />

                      <Route
                        exact
                        path="/ManageAssetsType"
                        element={<ManageAssetsType />}
                      />
                      <Route
                        exact
                        path="/AddAssetType"
                        element={<AddAssetType />}
                      />
                      <Route
                        exact
                        path="/EditAssetType/:id"
                        element={<EditAssetType />}
                      />

                      <Route
                        exact
                        path="/BookingReq"
                        element={<BookingReq />}
                      />
                      <Route
                        exact
                        path="/AddBooking"
                        element={<AddBooking />}
                      />
                      <Route
                        exact
                        path="/ScheduleBooking/:id"
                        element={<ScheduleBooking />}
                      />

                      <Route exact path="/Settings" element={<Settings />} />
                      <Route
                        exact
                        path="/ScheduledTrainings"
                        element={<ScheduledTrainings />}
                      />
                      <Route
                        exact
                        path="/ViewScheduledTrainings/:id"
                        element={<ViewScheduledTrainings />}
                      />
                      <Route
                        exact
                        path="/EditSlot/:id"
                        element={<EditSlot />}
                      />

                      <Route
                        exact
                        path="/TrainingTypes"
                        element={<TrainingTypes />}
                      />
                      <Route
                        exact
                        path="/AddTrainingType"
                        element={<AddTrainingType />}
                      />
                      <Route
                        exact
                        path="/EditTrainingType/:id"
                        element={<EditTrainingType />}
                      />
                    </>
                  ) : roll === "2" ? (
                    <>
                    <Route exact path="/" element={<Dashboard />} />
                      <Route exact path="/CADashboard" element={<Dashboard />} />
                      <Route
                        exact
                        path="/AddBooking"
                        element={<AddBooking />}
                      />
                      <Route exact path="/Settings" element={<Settings />} />

                      <Route
                        exact
                        path="/ManageStudents"
                        element={<ManageStudents />}
                      />
                      <Route
                        exact
                        path="/AddStudent"
                        element={<AddStudent />}
                      />
                      <Route
                        exact
                        path="/EditStudent/:id"
                        element={<EditStudent />}
                      />

                      <Route
                        exact
                        path="/BookingReq"
                        element={<BookingReq />}
                      />
                      <Route
                        exact
                        path="/AddBooking"
                        element={<AddBooking />}
                      />

                      <Route
                        exact
                        path="/ScheduledTrainings"
                        element={<ScheduledTrainings />}
                      />
                      <Route
                        exact
                        path="/ViewScheduledTrainings/:id"
                        element={<ViewScheduledTrainings />}
                      />
                    </>
                  ) : roll === "4" ? (
                    <>
                      <Route
                        exact
                        path="/IDashboard"
                        element={<IDashboard />}
                      />
                      <Route exact path="/Settings" element={<Settings />} />
                    </>
                  ) : roll === "null" ? (
                    <></>
                  ) : (
                    <>
                      <Route exact path="/SADashboard" element={<Login />} />
                      <Route exact path="/ADashboard" element={<Login />} />
                    </>
                  )}

                  <Route exact path="/CADashboard" element={<Dashboard />} />
                </Routes>
              </div>
            </div>
          </>
        ) : (
          <Routes>
            <Route exact path="/SADashboard" element={<Login />} />
            <Route exact path="/CADashboard" element={<Login />} />
            <Route exact path="/Login" element={<Login />} />
            <Route exact path="/" element={<Login />} />
          </Routes>
        )}
      </div>
    </>
  );
}

export default App;
