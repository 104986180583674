import React, { useState } from "react";
import { Container, Row, Form, Col, Button, Alert } from "react-bootstrap";

import AddIcon from '../../../images/AddIcon.png';
import ViewIcon from '../../../images/ViewIcon.png';
import EditIcon from '../../../images/EditIconNew.png';
import DeleteIconNew from '../../../images/DeleteIconNew.png';

import "./roles.css";

import RoleService from "../../../services/role.service";

const AddRole = () => {
  const [validated, setValidated] = useState(false);

  const initialRole = {
    name: "",
    note: "",
    status: "1",
  };

  const [role, setRole] = useState(initialRole);

  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRole({ ...role, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
    event.preventDefault();

    const data = {
      name: role.name,
      note: role.note,
      status: role.status,
    };
    console.log(data);

    if (Object.values(data).every((value) => value)) {
      RoleService.create(data)
        .then((response) => {
          console.log(response.data);
          setRole(response.data);
          setSubmitted(true);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  const [mtcheckedState, setMtCheckedState] = useState(
    new Array(4).fill(false)
  );

  const [mrcheckedState, setMrCheckedState] = useState(
    new Array(4).fill(false)
  );

  const [stcheckedState, setStCheckedState] = useState(
    new Array(4).fill(false)
  );

  const [ctcheckedState, setCtCheckedState] = useState(
    new Array(4).fill(false)
  );

  const mtHandleOnChange = (position) => {
    const updatedCheckedState = mtcheckedState.map((item, index) =>
      index === position ? !item : item
    );

    setMtCheckedState(updatedCheckedState);
  };

  const mrHandleOnChange = (position) => {
    const updatedCheckedState = mrcheckedState.map((item, index) =>
      index === position ? !item : item
    );

    setMrCheckedState(updatedCheckedState);
  };

  const stHandleOnChange = (position) => {
    const updatedCheckedState = stcheckedState.map((item, index) =>
      index === position ? !item : item
    );

    setStCheckedState(updatedCheckedState);
  };

  const ctHandleOnChange = (position) => {
    const updatedCheckedState = ctcheckedState.map((item, index) =>
      index === position ? !item : item
    );

    setCtCheckedState(updatedCheckedState);
  };

  return (
    <>
      <Container>
        <Row>
          <div className="rolesInfo">
            <div className="roleDetailsBox">
              {submitted ? (
                <Row>
                  <div className="sucmessage">Role Added Successfully!</div>
                </Row>
              ) : (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="head">
                    <h3>Enter Role Details</h3>
                  </div>
                  <Row>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group className="mb-3" controlId="name">
                        <Form.Control
                          type="text"
                          name="name"
                          value={role.name}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Name</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group className="mb-3" controlId="note">
                        <Form.Control
                          type="text"
                          name="note"
                          value={role.note}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Note</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className="head">
                    <h3>Assign Permission</h3>
                    <div className="errmessage">
                      Work In Progress
                    </div>
                  </div>
                  <div className="assignList">
                    <div className="assignHeader top">
                      <div className="moduleHeader top">
                        <span>Module Name</span>
                      </div>
                      <div className="assignActionHeader top">
                        <div className="actionTitle add">
                          <span>
                            <img src={AddIcon} alt="AddIcon" />
                          </span>
                          <span>Add</span>
                        </div>
                        <div className="actionTitle view">
                          <span>
                            <img src={ViewIcon} alt="ViewIcon" />
                          </span>
                          <span>View</span>
                        </div>
                        <div className="actionTitle edit">
                          <span>
                            <img src={EditIcon} alt="EditIcon" />
                          </span>
                          <span>Edit</span>
                        </div>
                        <div className="actionTitle delete">
                          <span>
                            <img src={DeleteIconNew} alt="DeleteIcon" />
                          </span>
                          <span>Delete</span>
                        </div>
                      </div>
                    </div>
                    <div className="assignHeader">
                      <div className="moduleHeader rest">
                        <span>Staff Management</span>
                      </div>
                      <div className="assignActionHeader">
                        <div className="actionTitle">
                          <label htmlFor="">
                            <input
                              type="checkbox"
                              name=""
                              id={`MT-${0}`}
                              checked={mtcheckedState[0]}
                              onChange={() => mtHandleOnChange(0)}
                            />
                          </label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">
                            <input
                              type="checkbox"
                              name=""
                              id={`MT-${1}`}
                              checked={mtcheckedState[1]}
                              onChange={() => mtHandleOnChange(1)}
                            />
                          </label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">
                            <input
                              type="checkbox"
                              name=""
                              id={`MT-${2}`}
                              checked={mtcheckedState[2]}
                              onChange={() => mtHandleOnChange(2)}
                            />
                          </label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">
                            <input
                              type="checkbox"
                              name=""
                              id={`MT-${3}`}
                              checked={mtcheckedState[3]}
                              onChange={() => mtHandleOnChange(3)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="assignHeader">
                      <div className="moduleHeader rest">
                        <span>Trainees Management</span>
                      </div>
                      <div className="assignActionHeader">
                        <div className="actionTitle">
                          <label htmlFor="">
                            <input
                              type="checkbox"
                              name=""
                              id={`MR-${0}`}
                              checked={mrcheckedState[0]}
                              onChange={() => mrHandleOnChange(0)}
                            />
                          </label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">
                            <input
                              type="checkbox"
                              name=""
                              id={`MR-${1}`}
                              checked={mrcheckedState[1]}
                              onChange={() => mrHandleOnChange(1)}
                            />
                          </label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">
                            <input
                              type="checkbox"
                              name=""
                              id={`MR-${2}`}
                              checked={mrcheckedState[2]}
                              onChange={() => mrHandleOnChange(2)}
                            />
                          </label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">
                            <input
                              type="checkbox"
                              name=""
                              id={`MR-${3}`}
                              checked={mrcheckedState[3]}
                              onChange={() => mrHandleOnChange(3)}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="assignHeader">
                      <div className="moduleHeader rest">
                        <span>Assets Management</span>
                      </div>
                      <div className="assignActionHeader">
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">
                            <input
                              type="checkbox"
                              id={`ST-${1}`}
                              checked={stcheckedState[1]}
                              onChange={() => stHandleOnChange(1)}
                            />
                          </label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                      </div>
                    </div>
                    <div className="assignHeader">
                      <div className="moduleHeader rest">
                        <span>Course Management</span>
                      </div>
                      <div className="assignActionHeader">
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">
                            <input
                              type="checkbox"
                              id={`CT-${0}`}
                              checked={ctcheckedState[1]}
                              onChange={() => ctHandleOnChange(1)}
                            />
                          </label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                      </div>
                    </div>
                    <div className="assignHeader">
                      <div className="moduleHeader rest">
                        <span>Training Types</span>
                      </div>
                      <div className="assignActionHeader">
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">
                            <input
                              type="checkbox"
                              id={`CT-${0}`}
                              checked={ctcheckedState[1]}
                              onChange={() => ctHandleOnChange(1)}
                            />
                          </label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                      </div>
                    </div>
                    <div className="assignHeader">
                      <div className="moduleHeader rest">
                        <span>Booking Request</span>
                      </div>
                      <div className="assignActionHeader">
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">
                            <input
                              type="checkbox"
                              id={`CT-${0}`}
                              checked={ctcheckedState[1]}
                              onChange={() => ctHandleOnChange(1)}
                            />
                          </label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                      </div>
                    </div>
                    <div className="assignHeader">
                      <div className="moduleHeader rest">
                        <span>Schedule Trainings</span>
                      </div>
                      <div className="assignActionHeader">
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">
                            <input
                              type="checkbox"
                              id={`CT-${0}`}
                              checked={ctcheckedState[1]}
                              onChange={() => ctHandleOnChange(1)}
                            />
                          </label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                        <div className="actionTitle">
                          <label htmlFor="">NA</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Button
                        type="submit"
                        className="nextButton"
                        // onClick={() => {
                        //   saverole();
                        // }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default AddRole;
