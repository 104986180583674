import React, { useState, useEffect } from "react";
import { Container, Row, Form, Col, Button, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

import "./staffs.css";

import RoleService from "../../../services/role.service";

import StaffService from "../../../services/staff.service";

const EditStaff = () => {
  const { id } = useParams();

  const [roles, setRoles] = useState([]);

  const [staff, setStaff] = useState({
    id: id,
    first_name: "",
    last_name: "",
    role: "",
    role_id: "",
    email: "",
    mobile: "",
    role_type: "",
    working_hours: "",
    work_experience: "",
    joining_date: "",
    address: "",
    nationality: "",
    hourly_rate: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const [validated, setValidated] = useState(false);

  const getRole = (id) => {
    StaffService.getStaffBy(id)
      .then((response) => {
        setStaff(response.data[0]);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (id) {
      getRole(id);
    }
  }, [id]);

  RoleService.getAll()
    .then((response) => {
      setRoles(response.data);
    })
    .catch((e) => {
      console.log(e);
    });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setStaff({ ...staff, [name]: value });
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    event.preventDefault();

    fetch("https://tms.aeonsoftware.net/cit//staff/staffdata/upstf", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": "pass123",
      },
      body: JSON.stringify({
        id: staff.id,
        first_name: staff.first_name,
        last_name: staff.last_name,
        role: staff.role,
        role_id: staff.role_id,
        email: staff.email,
        mobile: staff.mobile,
        role_type: staff.role_type,
        working_hours: staff.working_hours,
        work_experience: staff.work_experience,
        joining_date: staff.joining_date,
        nationality: staff.nationality,
        hourly_rate: staff.hourly_rate,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setSubmitted(true);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <Container>
        <Row>
          <div className="staffsInfo">
            <div className="staffDetailsBox">
              <div className="head">
                <h3>Edit Staff Details</h3>
              </div>
              {submitted ? (
                <Row>
                  <div className="sucmessage">Staff Updated Successfully!</div>
                </Row>
              ) : (
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="first_name"
                          value={staff.first_name}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>First Name</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="last_name"
                          value={staff.last_name}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Last Name</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="email"
                          value={staff.email}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Email ID</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="mobile"
                          value={staff.mobile}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Mobile Number</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="nationality"
                          value={staff.address}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Address</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="nationality"
                          value={staff.nationality}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Nationality</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          name="role"
                          value={staff.role}
                          onChange={handleInputChange}
                          required
                        >
                          <option>Role</option>
                          {Array.isArray(roles)
                            ? roles.map((role) => {
                                return (
                                  <option key={role.id} value={role.name}>
                                    {role.name}
                                  </option>
                                );
                              })
                            : null}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Select
                          aria-label="Default select example"
                          name="role_type"
                          value={staff.role_type}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="0">Role type</option>
                          <option value="permanent">Permanent</option>
                          <option value="contract">Contract</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="working_hours"
                          value={staff.working_hours}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Working Hours</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="working_hours"
                          value={staff.work_experience}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Work Experience</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3 joining_date"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="date"
                          name="joining_date"
                          value={staff.joining_date}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Joining Date</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col xl={6} lg={6} md={12} sm={12}>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="hourly_rate"
                          value={staff.hourly_rate}
                          onChange={handleInputChange}
                          required
                        />
                        <Form.Label>Hourly Rate</Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={12} lg={12} md={12} sm={12}>
                      <Button className="nextButton" type="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default EditStaff;
