import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

import StaffService from "../../../services/staff.service";

import RoleService from "../../../services/role.service";

const ViewStaff = ({ vid }) => {
  const [remove, setRemove] = useState(false);

  const [did, setDid] = useState("");

  const [message, setMessage] = useState("");

  const [roles, setRoles] = useState([]);

  const [staff, setStaff] = useState({
    id: vid,
    first_name: "",
    last_name: "",
    role: "",
    role_id: "",
    email: "",
    mobile: "",
    role_type: "",
    working_hours: "",
    work_experience: "",
    joining_date: "",
    address: "",
    nationality: "",
    hourly_rate: "",
  });

  const handleRemoveShow = (e) => {
    setRemove(true);
    setDid(e.target.getAttribute("data-bs-target"));
  };

  const handleRemoveClose = () => {
    setRemove(false);
  };

  const getRole = (vid) => {
    StaffService.getStaffBy(vid)
      .then((response) => {
        setStaff(response.data[0]);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    if (vid) {
      getRole(vid);
    }
    RoleService.getAll()
    .then((response) => {
      setRoles(response.data);
      // console.log(response.data)
    })
    .catch((e) => {
      console.log(e);
    });
  }, [vid, roles]);

  const deleteStaff = () => {
    StaffService.remove(did)
      .then((response) => {
        setMessage(response.data.message);
        // window.location.reload();
        setRemove(false);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  function getRoleName(roleId, roles) {
    const role = roles.find(r => r.id === roleId);
    return role ? role.name : 'Unknown Role';
  }

  return (
    <>
      <div className={message ? 'modalMessage active' : 'modalMessage'}>{message}</div>
      <div className="viewStaff">
        <div className="staff-details">
          <div className="title">
            <span>Role</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{getRoleName(staff.role_id, roles)}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>First Name</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{staff.first_name}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Last Name</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{staff.last_name}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Email</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{staff.email}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Mobile No.</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{staff.mobile}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Working Hours</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{staff.working_hours} Hours</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Joining Date</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{staff.joining_date}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Work Exp.</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{staff.work_experience} Year</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Role Type</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{staff.role_type}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Nationality</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{staff.nationality}</span>
          </div>
        </div>
        <div className="staff-details">
          <div className="title">
            <span>Hourly Late</span>
          </div>
          <div className="dash">
            <span>-</span>
          </div>
          <div className="text">
            <span>{staff.hourly_rate}</span>
          </div>
        </div>
        <div className="flex">
          <Button>
            <Link to={"/EditStaff/" + vid}>Edit</Link>
          </Button>
          <Button
            variant="danger"
            onClick={handleRemoveShow}
            id="deleteStaff"
            data-bs-target={vid}
          >
            Delete
          </Button>
        </div>
      </div>
      <Modal show={remove} onHide={handleRemoveClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this staff.</p>
          <div className="flex">
            <Button onClick={deleteStaff}>Yes</Button>
            <Button variant="danger" onClick={handleRemoveClose}>
              No
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ViewStaff;
